<template>
  <ContentModal
    :is-visible="showModal"
    :allow-click-away="true"
    size="medium"
    @onModalClose="() => $emit('onCloseBrowse')">
    <template
      #header-content>
      <div class="upload-header">
        Select File
      </div>
    </template>
    <template
      #content>
      <div class="browse-content h-100 w-100">
        <div
          class="upload-box"
          :class="fileDropOverlay ? 'file-drop-overlay' : ''"
          @drop.prevent="dropFile($event)"
          @dragenter.prevent="dragFile()"
          @dragleave.prevent="dragFileLeave()"
          @dragover.prevent>
          <div>
            <v-icon
              size="50">
              cloud_upload
            </v-icon>
          </div>
          <div>
            <span>Drag & drop or</span>
            <label
              for="browse-input"
              class="browse-input-label">
              select file
            </label>
          </div>
          <div class="browse-sub-text">
            <p>5GB max file size.</p>
            <p class="err-msg">{{ errorMsg }}</p>
          </div>
        </div>
        <input
          id="browse-input"
          ref="browse-input"
          type="file"
          @change="filesSelected($event.target.files)">
      </div>
    </template>
  </ContentModal>
</template>

<script>
import ContentModal from '@/components/utils/modal/ContentModal.vue';

export default {
  name: 'FileUploadDialog',
  components: {
    ContentModal,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dragCounter: 0,
      fileDropOverlay: false,
      acceptedFileTypes: ['application/pdf', 'text/csv'],
      errorMsg: '',
    };
  },
  methods: {
    dragFile() {
      this.fileDropOverlay = true;
      this.dragCounter++;
    },
    dragFileLeave() {
      this.dragCounter--;
      if (this.dragCounter === 0) {
        this.fileDropOverlay = false;
      }
    },
    dropFile(event) {
      this.dragCounter = 0;
      this.fileDropOverlay = false;
      this.filesSelected(event.dataTransfer.files);
    },
    filesSelected(files) {
      if (this.acceptedFileTypes.includes(files[0].type)) {
        this.errorMsg = '';
        this.$emit('filesSelected', files);
      } else
        this.errorMsg = 'File Type not accepted, please select a file type of one of the following. PDF, CSV';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/colors.scss";

#browse-input {
  display: none;
}
.err-msg {
  width: 300px;
  font-size: 12px;
  color: red;
}
.browse-input-label {
  color: blue;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
.browse-content {
  display: flex;
  justify-content: center;
}
.browse-sub-text {
  font-size: 12px;
  color: $bainbridge-gray;
}
.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: dashed 1px gray;
  border-radius: 8px;
  margin: 16px;
  height: calc(100% - 2rem);
  width: calc(100% - 5rem);
}
.file-drop-overlay {
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: rgba(133, 133, 133, .5);
}
</style>
