<template>
  <v-app
    :class="{
      'menus-loading': !tenantSwitchComplete,
    }"
    class="w-100 h-100"
    fixed>
    <v-app-bar
      v-if="loggedIn && tenantSwitchComplete"
      class="primary elevation-0"
      absolute
      dense
      app>
      <h1 class="v-toolbar__title white--text navbar-title">
        {{ page }}
      </h1>
      <v-spacer/>
      <div
        v-if="showSelectedTenant"
        class="white--text">
        {{ tenant.display_name }}
      </div>
    </v-app-bar>
    <SideMenu v-if="loggedIn && tenantSwitchComplete"/>
    <v-main class="inner-wrapper h-100 w-100">
      <v-progress-circular
        v-if="!tenantSwitchComplete"
        indeterminate
        class="primary--text loader app-loader"/>
      <div
        v-else-if="loggedIn"
        class="inner-wrapper router-wrapper h-100 w-100">
        <div
          v-show="!$route.meta.analyticsMenu"
          class="h-100 w-100">
          <keep-alive :exclude="['LandingPage']">
            <router-view/>
          </keep-alive>
        </div>
        <AnalyticsRouter v-show="$route.meta.analyticsMenu"/>
      </div>
      <div
        v-else
        class="inner-wrapper router-wrapper h-100 w-100">
        <router-view/>
      </div>
    </v-main>
    <v-snackbar
      v-model="snack.show"
      :top="true">
      {{ snack.text }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          class="secondary--text"
          @click="snack.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { objectsEqual } from '@/lib/compare';
import { EventLog } from '@/lib/event-log';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import AnalyticsRouter from './components/analytics/AnalyticsRouter.vue';
import SideMenu from './components/SideMenu.vue';

export default {
  name: 'App',
  components: {
    AnalyticsRouter,
    SideMenu,
  },
  data() {
    return {
      snack: {
        text: null,
        show: false,
      },
    };
  },
  computed: {
    page() {
      let title;
      if (this.$route.meta.analyticsMenu) {
        if (this.subModule) {
          title = `${this.$route.meta.navbarTitle}: ${this.vendor || ''} ${this.subModule}`;
        } else {
          title = `${this.$route.meta.navbarTitle}`;
        }
      } else {
        title = this.$route.matched.reduce((result, route) => result || route.meta.navbarTitle, null);
      }
      return title || 'InfusionWare®';
    },
    loggedIn() {
      return (this.user !== null);
    },
    showSelectedTenant() {
      return this.tenants.length > 1 && this.tenantSwitchComplete;
    },
    ...mapGetters([
      'debugUser',
      'menuNavigationStart',
      'menuNavigationEnd',
      'subModule',
      'tabNavigationStart',
      'tabNavigationEnd',
      'tenant',
      'tenants',
      'tenantSwitchComplete',
      'user',
      'vendor',
    ]),
  },
  watch: {
    $route: {
      handler(to, from) {
        const now = moment();
        this.setCurrentPath();
        let loggingData;
        if (to && from) {
          if (
            (to.meta.analyticsMenu && !objectsEqual(from.params, to.params)) ||
            from.name !== to.name
          ) {
            const { params } = from;
            const menuTitle = from.meta.displayName ? from.meta.displayName.toLowerCase().replace(/ |-/g, '_') : null;
            let menuName;
            if (params && params.id) {
              menuName = menuTitle ? `${menuTitle}.${params.id.replace(/ |-/g, '_')}` : null;
            } else {
              menuName = menuTitle;
            }
            const menuData = {
              timestamp: now,
              menu: menuName,
            };
            this.setMenuNavigationStart(menuData);
            this.setTabNavigationStart({});
            this.setTabNavigationEnd({});
          }
        }
        // Log initial page load
        if (!from) {
          loggingData = new EventLog({
            event: 'page.load',
          });
          this.$services.users.postTrackingLog(loggingData);
        } else if (to.meta.displayName && !to.meta.analyticsMenu && to.name !== 'LandingPage') {
          loggingData = new EventLog({
            event: 'navigate',
          });
          this.$services.users.postTrackingLog(loggingData);
        }
      },
      deep: true,
      immediate: true,
    },
    debugUser: {
      handler() {
        if (this.debugUser) {
          const _log = console.log; //eslint-disable-line
          const _warn = console.warn; //eslint-disable-line
          const _error = console.error; //eslint-disable-line
          console.log = (...args) => {  //eslint-disable-line
            const loggingData = new EventLog({
              event: 'debug.console_message',
              level: 'log',
              arguments: args,
            });
            this.$services.users.postTrackingLog(loggingData);
            _log.apply(console, args);
          };
          console.warn = (...args) => {  //eslint-disable-line
            const loggingData = new EventLog({
              event: 'debug.console_message',
              level: 'warn',
              arguments: args,
            });
            this.$services.users.postTrackingLog(loggingData);
            _warn.apply(console, args);
          };
          console.error = (...args) => {  //eslint-disable-line
            const loggingData = new EventLog({
              event: 'debug.console_message',
              level: 'error',
              arguments: args,
            });
            this.$services.users.postTrackingLog(loggingData);
            _error.apply(console, args);
          };
          window.onclick = (e) => {
            const loggingData = new EventLog({
              event: 'debug.click_event',
              target: e.target,
            });
            this.$services.users.postTrackingLog(loggingData);
          };
        }
      },
    },
    menuNavigationEnd: {
      handler() {
        if (
          this.menuNavigationStart && this.menuNavigationEnd &&
          'timestamp' in this.menuNavigationStart && 'timestamp' in this.menuNavigationEnd
        ) {
          const loggingData = new EventLog({
            event: 'metric.track_menu_navigation',
            milliseconds: this.menuNavigationEnd.timestamp.diff(this.menuNavigationStart.timestamp),
            startMenu: this.menuNavigationStart.menu,
            endMenu: this.menuNavigationEnd.menu,
          });
          this.$services.users.postTrackingLog(loggingData);
          this.setMenuNavigationStart({});
          this.setMenuNavigationEnd({});
        }
      },
      immediate: true,
    },
    tabNavigationEnd: {
      handler() {
        if (
          this.tabNavigationStart && this.tabNavigationEnd &&
          'timestamp' in this.tabNavigationStart && 'timestamp' in this.tabNavigationEnd
        ) {
          const loggingData = new EventLog({
            event: 'metric.track_tab_navigation',
            milliseconds: this.tabNavigationEnd.timestamp.diff(this.tabNavigationStart.timestamp),
            startTab: this.tabNavigationStart.tab,
            endTab: this.tabNavigationEnd.tab,
          });
          this.$services.users.postTrackingLog(loggingData);
          this.setTabNavigationStart({});
          this.setTabNavigationEnd({});
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.logPageClose);

    this.$bus.$on('showSnackbar', (message) => {
      this.snack.text = message;
      this.snack.show = true;
    });
  },
  methods: {
    logPageClose() {
      const loggingData = new EventLog({
        event: 'page.close',
      });
      this.$services.users.postTrackingLog(loggingData);
    },
    ...mapActions([
      'logout',
      'setCurrentPath',
      'setMenuNavigationEnd',
      'setMenuNavigationStart',
      'setTabNavigationEnd',
      'setTabNavigationStart',
    ]),
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.navbar-title {
  font-weight: normal;
}
.inner-wrapper {
  position: absolute;
}
.router-wrapper {
  overflow-y: auto;
}
.app-loader {
  height: 100% !important;
}
.menus-loading {
  cursor: progress !important;
}
.v-snack {
  padding-top: 0px !important;
  .v-snack__content {
    margin: 6px;
    font-size: 14px;
  }
  .v-snack__action {
    margin-right: 0px !important;
  }
}
</style>
