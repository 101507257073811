var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"transition":"slide-x-transition","content-class":"menu","max-width":"230","max-height":"90%","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"value":_vm.errorState,"color":"warning","icon":"mdi-alert","offset-x":"25","offset-y":"25","bottom":"","overlap":""}},[_c('v-btn',_vm._g({class:{
          'w-100': _vm.expanded,
          'mx-0': _vm.expanded,
        },attrs:{"disabled":_vm.disabled,"icon":!_vm.expanded,"text":"","depressed":""},on:{"click":function($event){(!_vm.isCurrent && !_vm.hasSubMenus) ? _vm.goToMainPage() : null}}},on),[_c('v-tooltip',{attrs:{"disabled":(_vm.menu && _vm.hasSubMenus) || _vm.expanded,"z-index":"9","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var subMenuOn = ref.subMenuOn;
return [_c('v-icon',_vm._g({class:{
                'menu-icon': true,
                'bainbridge-green--text': _vm.isCurrent && !_vm.disabled,
                'bainbridge-blue--text': !_vm.isCurrent && !_vm.disabled,
                'bainbridge-grey--text': _vm.disabled,
              },style:(_vm.iconStyle ? _vm.iconStyle : null)},subMenuOn),[_vm._v("\n              "+_vm._s(_vm.icon)+"\n            ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),(_vm.expanded)?_c('v-subheader',{staticClass:"main-title pr-0",class:{
            'bainbridge-green--text': _vm.isCurrent && !_vm.disabled,
            'bainbridge-blue--text': !_vm.isCurrent && !_vm.disabled,
            'bainbridge-grey--text': _vm.disabled,
            'current': _vm.isCurrent && !_vm.hasSubMenus,
            'top-menu-title': _vm.hasSubMenus,
            'pl-4': _vm.expanded,
          }},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),(_vm.hasSubMenus)?_c('v-card',{staticClass:"sub-menu-card"},[_c('v-list',{attrs:{"dense":""}},[(!_vm.expanded)?_c('v-list-item',[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-subheader',{staticClass:"main-title pl-0",class:{
              'bainbridge-green--text': _vm.isCurrent && !_vm.disabled,
              'bainbridge-blue--text': !_vm.isCurrent && !_vm.disabled,
              'bainbridge-grey--text': _vm.disabled,
            }},[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")])],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.subMenus),function(sub,index){return _c('div',{key:sub.title},[('sections' in sub)?_c('v-list-item',[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-subheader',{staticClass:"header-title pl-4",class:{
                'bainbridge-green--text': _vm.isSubCurrent(sub) && !_vm.disabled,
                'bainbridge-blue--text': !_vm.isSubCurrent(sub) && !_vm.disabled,
                'bainbridge-grey--text': _vm.disabled,
                'mt-4': (index !== 0),
              }},[_vm._v("\n              "+_vm._s(sub.title)+"\n            ")])],1)],1):_c('v-list-item',{on:{"click":function($event){return _vm.goToSubPage(sub)}}},[_c('v-list-item-content',{staticClass:"py-0 pl-4"},[_c('v-list-item-title',{staticClass:"sub-title",class:{
                'bainbridge-green--text': _vm.isSubCurrent(sub) && !_vm.disabled,
                'bainbridge-blue--text': !_vm.isSubCurrent(sub) && !_vm.disabled,
                'bainbridge-grey--text': _vm.disabled,
                'current': _vm.isSubCurrent(sub),
              }},[_vm._v("\n              "+_vm._s(sub.title)+"\n            ")])],1)],1),_vm._v(" "),('sections' in sub)?_c('div',_vm._l((sub.sections),function(section){return _c('v-list-item',{key:section.title,on:{"click":function($event){return _vm.goToSubSectionPage(sub, section)}}},[_c('v-list-item-content',{staticClass:"py-0 pl-8"},[_c('v-list-item-title',{staticClass:"sub-title",class:{
                  'bainbridge-green--text': _vm.isSubSectionCurrent(sub, section) && !_vm.disabled,
                  'bainbridge-blue--text': !_vm.isSubSectionCurrent(sub, section) && !_vm.disabled,
                  'bainbridge-grey--text': _vm.disabled,
                  'current': _vm.isSubSectionCurrent(sub, section),
                }},[_vm._v("\n                "+_vm._s(section.title)+"\n              ")])],1)],1)}),1):_vm._e()],1)})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }