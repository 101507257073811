import { objectsEqual } from '@/lib/compare';
import { LOGGED_OUT_MESSAGE, NO_TENANTS_MESSAGE } from '@/lib/http';
import router from '@/router/index';
import InfusionMetricsService from '@/services/infusion-metrics';

export default {
  state: {
    collectionId: null,
    dashboard: null,
    menuNavigationStart: {},
    menuNavigationEnd: {},
    menus: [],
    module: null,
    navbarItems: [],
    subModule: null,
    tabNavigationEnd: {},
    tabNavigationStart: {},
    vendor: null,
  },
  mutations: {
    RESET_MENUS(state) {
      state.collectionId = null;
      state.dashboard = null;
      state.menuNavigationStart = {};
      state.menuNavigationEnd = {};
      state.menus = [];
      state.module = null;
      state.navbarItems = [];
      state.subModule = null;
      state.tabNavigationEnd = {};
      state.tabNavigationStart = {};
      state.vendor = null;
    },
    SET_COLLECTION_ID(state, collectionId) {
      state.collectionId = collectionId;
    },
    SET_DASHBOARD(state, dashboard) {
      state.dashboard = dashboard;
    },
    SET_MENUS(state, menus) {
      menus.sort((a, b) => {
        if (a.priority < b.priority) { return -1; }
        if (a.priority > b.priority) { return 1; }
        return 0;
      });
      state.menus = menus;
    },
    SET_MENU_NAVIGATION_END(state, menuNavigationEnd) {
      state.menuNavigationEnd = menuNavigationEnd;
    },
    SET_MENU_NAVIGATION_START(state, menuNavigationStart) {
      state.menuNavigationStart = menuNavigationStart;
    },
    SET_MODULE(state, module) {
      state.module = module;
    },
    SET_NAVBAR_ITEMS(state, navbar) {
      navbar.sort((a, b) => {
        if (a.priority < b.priority) { return -1; }
        if (a.priority > b.priority) { return 1; }
        return 0;
      });
      state.navbarItems = navbar;
    },
    SET_SUB_MODULE(state, subModule) {
      state.subModule = subModule;
    },
    SET_TAB_NAVIGATION_END(state, tabNavigationEnd) {
      state.tabNavigationEnd = tabNavigationEnd;
    },
    SET_TAB_NAVIGATION_START(state, tabNavigationStart) {
      state.tabNavigationStart = tabNavigationStart;
    },
    SET_VENDOR(state, vendor) {
      state.vendor = vendor;
    },
  },
  actions: {
    resetMenus({ commit }) {
      commit('RESET_MENUS');
    },
    setCollectionId({ commit }, collectionId) {
      commit('SET_COLLECTION_ID', collectionId);
    },
    setCurrentPath({ commit, state, dispatch }) {
      const route = router.currentRoute;
      const moduleName = route.meta.displayName;
      let collectionId;
      let subModule;
      let vendor;
      if (route.meta.analyticsMenu) {
        const currentMenu = state.menus.find((menu) => menu.title === route.name);
        if (currentMenu) {
          collectionId = route.params.id;
          if (!collectionId) {
            const params = { ...route.params };
            [subModule] = currentMenu.subs;
            if ('sections' in subModule) {
              vendor = subModule.title;
              [subModule] = subModule.sections;
            }
            collectionId = subModule.url;
            params.id = collectionId;
            if (!objectsEqual(route.params, params)) {
              router.push({ params, query: route.query });
            }
          } else {
            currentMenu.subs.forEach((sub) => {
              if ('sections' in sub) {
                const subSection = sub.sections.find((section) => section.url === collectionId);
                if (subSection) {
                  subModule = subSection;
                  vendor = sub.title;
                }
              } else if ('url' in sub && sub.url === collectionId) {
                subModule = sub;
              }
            });
          }
        }
      } else {
        dispatch('setDashboard');
      }
      dispatch('setModule', moduleName);
      dispatch('setCollectionId', collectionId);
      dispatch('setSubModule', subModule ? subModule.title : moduleName);
      dispatch('setVendor', vendor);
    },
    setDashboard({ commit }, dashboard) {
      commit('SET_DASHBOARD', dashboard);
    },
    setMenuNavigationEnd({ commit }, menuNavigationEnd) {
      commit('SET_MENU_NAVIGATION_END', menuNavigationEnd);
    },
    setMenuNavigationStart({ commit }, menuNavigationStart) {
      commit('SET_MENU_NAVIGATION_START', menuNavigationStart);
    },
    setMenus({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        InfusionMetricsService.getMenus().then((menus) => {
          commit('SET_MENUS', menus.items);
          commit('SET_NAVBAR_ITEMS', menus.navbar);
          resolve();
        }).catch((error) => {
          if (error === LOGGED_OUT_MESSAGE || error === NO_TENANTS_MESSAGE) {
            dispatch('logout');
            router.push({ name: 'LoginPortal' });
          }
          reject();
        });
      });
    },
    setModule({ commit }, module) {
      commit('SET_MODULE', module);
    },
    setSubModule({ commit }, subModule) {
      commit('SET_SUB_MODULE', subModule);
    },
    setTabNavigationEnd({ commit }, tabNavigationEnd) {
      commit('SET_TAB_NAVIGATION_END', tabNavigationEnd);
    },
    setTabNavigationStart({ commit }, tabNavigationStart) {
      commit('SET_TAB_NAVIGATION_START', tabNavigationStart);
    },
    setVendor({ commit }, vendor) {
      commit('SET_VENDOR', vendor);
    },
  },
  getters: {
    collectionId: (state) => state.collectionId,
    dashboard: (state) => state.dashboard,
    menus: (state) => state.menus,
    menuNavigationEnd: (state) => state.menuNavigationEnd,
    menuNavigationStart: (state) => state.menuNavigationStart,
    module: (state) => state.module,
    navbarItems: (state) => state.navbarItems,
    subModule: (state) => state.subModule,
    tabNavigationStart: (state) => state.tabNavigationStart,
    tabNavigationEnd: (state) => state.tabNavigationEnd,
    vendor: (state) => state.vendor,
  },
};
