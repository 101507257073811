<template>
  <div class="looker-view h-100">
    <iframe
      v-if="lookerUrl"
      :ref="lookerFrameRef"
      :src="lookerUrl"
      :style="dashboardDef.style"
      :name="lookerFrameName"
      :class="[{
        'h-100': isVisible,
        'h-0': !isVisible || (drillThrough && initialDrill),
      }, `looker-frame-${dashboardLoadStatus}`]"
      class="looker-frame mb-2 px-2 w-100"
      @load="checkEmbedListenerCalled"
      />
    <div
      v-if="!lookerUrl || (drillThrough && initialDrill)"
      class="d-flex h-100">
      <v-progress-circular
        indeterminate
        color="primary"
        class="loader"/>
    </div>
  </div>
</template>

<script>
import { EventLog } from '@/lib/event-log';
import { mapGetters } from 'vuex';
import LoadStatus from '@/lib/enums/load-status-enum';
import moment from 'moment';

import {
  IS_BLANK,
  IS_NULL,
  IS_NOT_BLANK,
  IS_NOT_NULL,
} from '@/lib/constants';

// The Looker iframe events we want to track and the corresponding data fields
const lookerTrackEvents = {
  'dashboard:download': [
    'dashboard.title',
    'dashboard.absoluteUrl',
  ],
  'dashboard:tile:download': [
    'dashboard.title',
    'dashboard.absoluteUrl',
    'tile.title',
  ],
  'dashboard:tile:explore': [
    'url',
    'dashboard.title',
    'dashboard.absoluteUrl',
    'tile.title',
  ],
  'dashboard:tile:view': [
    'url',
    'dashboard.title',
    'dashboard.absoluteUrl',
    'tile.title',
  ],
  'drillmenu:click': [
    'label',
    'url',
  ],
  'drillmodal:explore': [
    'url',
  ],
  'explore:run:start': [
    'explore.absoluteUrl',
  ],
};

export default {
  name: 'LookerView',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    dashboardDef: {
      type: Object,
      required: true,
    },
    drillTarget: {
      type: Object,
      default: () => {},
      required: false,
    },
    filterValues: {
      type: Object,
      required: true,
    },
    dashboardLoadStatus: {
      type: String,
      required: true,
    },
    reload: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dashboardLoadEnd: null,
      dashboardLoadStart: null,
      lookerEventCalled: false,
      filtersUpdated: false,
      currentLookerQuery: {},
      lookerUrl: null,
      reloadAttempts: 0,
      initialDrill: false,
      LoadStatus,
    };
  },
  computed: {
    ...mapGetters([
      'dashboard',
      'filters',
      'filterDefaults',
      'filterSet',
      'tenant',
      'tenantConfig',
    ]),
    drillThrough() {
      return this.dashboardDef.type === 'drill_through';
    },
    lookerId() {
      return `${this.dashboardDef.looker.model}::${this.dashboardDef.looker.name}`;
    },
    lookerOrigin() {
      return `https://${this.tenantConfig.lookerHost}`;
    },
    lookerFrameRef() {
      return `looker-frame-${this.dashboardDef.uri}`;
    },
    lookerFrameName() {
      return `looker-frame-${this.dashboardDef.name.toLowerCase().replace(/ /g, '-')}`;
    },
    dashboardFilters() {
      return this.drillThrough ? (
        [...this.dashboardDef.drill_filters, ...this.dashboardDef.filters]
      ) : this.dashboardDef.filters;
    },
    filterValuesRequired() {
      const requiredFilters = this.dashboardFilters.filter((f) => f.required);
      const valueOptions = [IS_BLANK, IS_NULL, IS_NOT_BLANK, IS_NOT_NULL];
      return requiredFilters.some((f) => (
        !this.filterValues[f.id] || (this.filterValues[f.id] && (!this.filterValues[f.id].value ||
          (this.filterValues[f.id].value && !valueOptions.includes(this.filterValues[f.id].value.option) &&
            !this.filterValues[f.id].value.value)))));
    },
  },
  watch: {
    isVisible: {
      handler(newValue, prevValue) {
        if (newValue && !prevValue && this.dashboardLoadStatus === LoadStatus.LOADING) {
          this.initialDrill = true;
        } else {
          this.initialDrill = false;
        }
      },
    },
    dashboardLoadStatus() {
      if (this.dashboardLoadStatus === LoadStatus.STOPPING && this.lookerUrl) {
        this.postMessageAction('dashboard:stop');
      }
      if (this.lookerUrl && this.dashboardLoadStatus === LoadStatus.LOADING) {
        this.postMessageAction('dashboard:run');
      }
    },
    filterValues: {
      handler() {
        if (this.lookerUrl) {
          this.postMessageAction('dashboard:filters:update', this.filterValues.lookerQuery);
        }
      },
      deep: true,
    },
    dashboardDef: {
      handler() {
        if (this.dashboardLoadStatus === LoadStatus.LOADING) {
          this.lookerUrl = null;
          this.fetchLookerUrl();
        }
      },
      deep: true,
    },
    reload() {
      if (this.reload) {
        this.reloadDashboard();
      }
    },
    dashboardLoadEnd() {
      if (this.dashboardLoadStart && this.dashboardLoadEnd) {
        const loggingData = new EventLog({
          dashboard: this.dashboardDef.name,
          event: 'metric.track_dashboard_load',
          milliseconds: this.dashboardLoadEnd.diff(this.dashboardLoadStart),
        });
        this.$services.users.postTrackingLog(loggingData);
        this.dashboardLoadStart = null;
        this.dashboardLoadEnd = null;
      }
    },
  },
  mounted() {
    this.fetchLookerUrl();
    window.addEventListener('message', this.lookerEmbeddedEventListener);
  },
  destroyed() {
    window.removeEventListener('message', this.lookerEmbeddedEventListener);
  },
  methods: {
    reloadDashboard() {
      this.reloadAttempts += 1;
      this.lookerUrl = null;
      this.fetchLookerUrl();
    },
    fetchLookerUrl() {
      const dashboardQuery = this.dashboardDef.looker.model === 'internal' ? (
        `${this.filterValues.dashboardQuery}_Tenant=${this.tenant.name}`
      ) : this.filterValues.dashboardQuery;
      const query = {
        filters: dashboardQuery,
      };
      if (this.dashboardLoadStatus !== LoadStatus.STOPPING) {
        this.updateLoadStatus(LoadStatus.LOADING);
      }
      this.$services.dashboards.getDashboard(this.dashboardDef.uri, query).then((result) => {
        this.lookerUrl = result.looker.url;
        this.dashboardLoadStart = moment();
      });
    },
    updateLoadStatus(loadStatus) {
      this.$emit('updateLoadStatus', {
        componentId: this.dashboardDef.uri,
        loadStatus,
      });
    },
    async isDrillEvent(eventData, self) {
      if (!self.dashboardDef.drill_id || !this.drillTarget ||!this.drillTarget.looker) {
        return false;
      }
      if (!this.drillTarget.looker.tenanted) {
        return eventData.url.includes(self.dashboardDef.drill_id);
      }
      const drillTargetName = `t_${self.tenant.name}_${this.drillTarget.looker.model_subject}::${this.drillTarget.looker.name}`;
      return eventData.url.includes(drillTargetName);
    },
    drillAction(drillInfo) {
      const { filterSet, dashboardId } = drillInfo;
      this.$emit('drillAction', {
        componentId: this.dashboardDef.uri,
        state: true,
        filterSet,
        dashboardId,
      });
    },
    linkAction(drillInfo) {
      const { filterSet, dashboardId } = drillInfo;
      this.$emit('linkAction', {
        componentId: this.dashboardDef.uri,
        filterSet,
        dashboardId,
      });
    },
    checkEmbedListenerCalled() {
      setTimeout(() => { 
        if (!this.lookerEventCalled) {
          const loggingData = new EventLog({
            event: 'looker.fail_dashboard_load',
            message: "lookerEmbeddedEventListener was never called, This could potentially be that the users browser is not allowing third party cookies",
            retries: this.reloadAttempts,
          });
          this.$services.users.postTrackingLog(loggingData);
        }
      }, 5000); 
    },
    lookerEmbeddedEventListener(event) {
      const lookerFrame = this.$refs[this.lookerFrameRef];
      this.lookerEventCalled = true
      if (lookerFrame === undefined) { return; }
      if (event.source === lookerFrame.contentWindow) {
        try {
          const eventData = JSON.parse(event.data);
          const currentDashboard = eventData.dashboard && eventData.dashboard.id === this.lookerId;
          if (eventData.type === 'dashboard:loaded' && currentDashboard) {
            const loggingData = new EventLog({
                event: 'looker.fail_dashboard_load',
                message: eventData,
                retries: this.reloadAttempts,
              });
            if (eventData.status === LoadStatus.ERROR) {
              this.updateLoadStatus(LoadStatus.ERROR);
              const loggingData = new EventLog({
                event: 'looker.fail_dashboard_load',
                message: eventData,
                retries: this.reloadAttempts,
              });
              this.$services.users.postTrackingLog(loggingData);
              let message;
              if (this.reloadAttempts > 2) {
                message = 'The dashboard was unable to be loaded at this time. Please refresh the page to try again.';
              } else if (this.reloadAttempts === 2) {
                message = 'There was an error loading the dashboard. The dashboard is being reloaded now.';
                this.reloadDashboard();
              } else {
                this.reloadDashboard();
              }
              if (this.isVisible && message) {
                this.$notify(message);
              }
            } else if (this.dashboardLoadStatus === LoadStatus.STOPPING) {
              this.postMessageAction('dashboard:stop');
            } else if (this.filterValuesRequired) {
              this.updateLoadStatus(LoadStatus.LOADED);
            }
          } else {
            if (eventData.type === 'dashboard:run:complete' && currentDashboard) {
              this.updateLoadStatus(LoadStatus.LOADED);
              this.initialDrill = false;
              this.dashboardLoadEnd = moment();
              this.reloadAttempts = 0;
            }
            if (this.isVisible) {
              if (Object.keys(lookerTrackEvents).includes(eventData.type)) {
                const eventType = eventData.type;
                const trackEvents = lookerTrackEvents[eventType];
                const lookerData = {};
                trackEvents.forEach((e) => {
                  if (e === 'url') {
                    eventData.url = !eventData.url.includes('https://') ? this.lookerOrigin + eventData.url : eventData.url;
                  }
                  const eventFields = e.split('.');
                  const displayNames = eventFields.map((f) => f.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1_$2').toLowerCase());
                  if (eventFields.length > 1) {
                    lookerData[displayNames[1]] = eventData[eventFields[0]][eventFields[1]];
                  } else {
                    lookerData[displayNames[0] === 'url' ? 'absolute_url' : displayNames[0]] = eventData[eventFields[0]];
                  }
                });
                const loggingData = new EventLog({
                  event: `looker.${eventType.replace(/:/g, '.')}`,
                  filterSetId: this.$route.query.filter_set || '',
                  lookerData,
                });
                this.$services.users.postTrackingLog(loggingData);
              }
              if (eventData.type === 'drillmenu:click' && eventData.link_type && eventData.link_type === 'dashboard') {
                this.isDrillEvent(eventData, this).then((drillEvent) => {
                  if (drillEvent) {
                    this.drillAction(this.parseDrillUrl(eventData.url));
                  } else {
                    this.linkAction(this.parseDrillUrl(eventData.url));
                  }
                });
              }
            }
            if (eventData.type === 'dashboard:filters:changed' && this.filtersUpdated) {
              this.filtersUpdated = false;
              this.$emit('cleanFilters');
              this.postMessageAction('dashboard:run');
            }
          }
        } catch (error) {
          this.updateLoadStatus(LoadStatus.ERROR);
        }
      }
    },
    parseDrillUrl(url) {
      const queryObj = {};
      const dashboardId = url.match(/\/[\w|:|-]*\?/)[0].replace(/\/|\?/g, '');
      const separator = url.indexOf('?');
      const queryString = url.slice(separator + 1).trim().replace(/(\r\n|\n|\r)/gm, '');
      const queryParams = queryString.split('&');
      queryParams.forEach((param) => {
        const keyVal = param.trim().split('=');
        const key = decodeURIComponent(keyVal[0].replace(/\+/g, ' ')).trim();
        const val = decodeURIComponent(keyVal[1].replace(/\+/g, ' ').replace(/\"/g, '')).replace(/^"(.+(?="$))"$/, '$1').trim(); //eslint-disable-line
        queryObj[key] = val;
      });
      return {
        filterSet: queryObj,
        dashboardId,
      };
    },
    postMessageAction(type, filters) {
      const filterRequest = JSON.stringify({
        type,
        filters: filters || {},
      });
      if (filters) this.filtersUpdated = true;
      if (this.$refs[this.lookerFrameRef]) {
        this.$refs[this.lookerFrameRef].contentWindow.postMessage(filterRequest, this.lookerOrigin);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.looker-view {
  @import "@/sass/colors.scss";
  z-index: 1;

  iframe.looker-frame {
    border: none;
    margin-top: -1px;
  }
}
</style>
