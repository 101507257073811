<template>
  <!-- min-width comes from https://vuetifyjs.com/en/components/date-pickers -->
  <v-menu
    ref="dateMenu"
    v-model="dateMenu"
    :close-on-content-click="false"
    :disabled="!isActive"
    :return-value.sync="localDate"
    min-width="290px"
    offset-y>
    <template #activator="{ on }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        :disabled="!isActive"
        :rules="rules"
        :hint="`${dateFormat} format`"
        class="date-input"
        prepend-icon="event"
        validate-on-blur
        v-on="on"
        @blur="formatDate"
        @input="dateMenu = false"/>
    </template>
    <v-date-picker
      v-model="localDate"
      :disabled="!isActive"
      no-title
      scrollable>
      <v-spacer/>
      <v-btn
        :disabled="!isActive"
        text
        color="primary"
        @click="dateMenu = false">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!isActive"
        text
        color="primary"
        @click="$refs.dateMenu.save(localDate)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATE = moment().format(DEFAULT_DATE_FORMAT);

export default {
  name: 'DateSelector',
  props: {
    isActive: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => 'Date',
    },
    passedDate: {
      type: String,
      default: () => DEFAULT_DATE,
    },
    dateFormat: {
      type: String,
      default: () => DEFAULT_DATE_FORMAT,
    },
    field: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dateMenu: false,
      formattedDate: DEFAULT_DATE,
      localDate: DEFAULT_DATE,
    };
  },
  watch: {
    localDate: {
      handler() {
        this.formattedDate = this.localDate;
        this.dateChanged();
      },
      immediate: true,
    },
    passedDate: {
      handler() {
        this.formattedDate = this.passedDate;
        this.localDate = this.passedDate;
      },
      immediate: true,
    },
  },
  methods: {
    formatDate() {
      if (!this.formattedDate || !moment(this.formattedDate, this.dateFormat, true).isValid()) {
        this.formattedDate = DEFAULT_DATE;
        this.localDate = DEFAULT_DATE;
      } else {
        this.formattedDate = moment(this.formattedDate).format(this.dateFormat);
        this.localDate = moment(this.formattedDate).format(this.dateFormat);
      }
    },
    dateChanged() {
      this.$emit('dateChanged', {
        field: this.field,
        date: this.localDate,
      });
    },
  },
};
</script>
