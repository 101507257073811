<template>
  <div
    class="source-card"
    :class="isSelected ? 'source-card-selected' : ''"
    @click="sourceClicked">
    <v-icon
      class="source-icon"
      :class="isSelected ? 'source-icon-selected' : ''">
      inbox
    </v-icon>
    <p>
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SourceView',
  props: {
    title: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sourceClicked() {
      this.$emit('source-clicked', this.title);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.source-card {
  display: flex;
  gap: 20px;
  height: 45px;
  margin: 1rem;
  padding: .5rem;
  border: 1px solid $bainbridge-gray-light;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: $bainbridge-blue;
    color: #fff;
    font-weight: 600;
  }
}

.source-card-selected {
  background-color: $bainbridge-blue;
  color: #fff;
  font-weight: 600;
}

.source-icon-selected {
  color: #fff !important;
}

.source-card:hover .source-icon {
  color: #fff !important;
}
</style>
