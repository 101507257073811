<template>
  <v-layout
    class="pt-12"
    justify-center
    wrap>
    <v-flex
      v-for="item in items"
      :key="item.link"
      class="portal-card-container"
      xl3
      lg4
      md5
      sm6
      xs7>
      <v-card
        class="portal-card mx-7 mb-7"
        width="310">
        <a
          :href="`/#/${item.link}`"
          class="portal-link">
          <v-card-title class="white--text primary justify-center">
            {{ item.title }}
          </v-card-title>
          <v-icon class="secondary--text ma-3 center">
            {{ item.icon }}
          </v-icon>
          <div class="portal-link-section pa-4 black">
            <span
              class="white--text"
              v-text="item.text"/>
          </div>
        </a>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  name: 'DebugPortal',
  data() {
    return {
      items: [
        {
          title: 'Sentry Testing',
          icon: 'mdi-alert-decagram',
          text: 'Test Sentry integration by triggering an event',
          link: 'debug/sentry-testing',
        },
        {
          title: 'User Troubleshooting',
          icon: 'mdi-account-search',
          text: 'Troubleshoot user workflow issues by tracking a user session',
          link: 'debug/user-testing',
        },
      ],
    };
  },
  mounted() {
    const now = moment();
    const menuData = {
      timestamp: now,
      menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
    };
    this.setMenuNavigationEnd(menuData);
  },
  methods: {
    navigateTo(link) {
      this.$router.push({ name: link });
    },
    ...mapActions([
      'setMenuNavigationEnd',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.portal-card-container {
  min-width: 250px;
  max-width: 380px;
}
.portal-card {
  @import '../../sass/colors.scss';
  display: inline-block;
  text-align: center;

  i.mdi {
    font-size: 200px;
    margin: 0 auto;
  }

  &:hover {
    cursor: pointer;
  }

  li {
    list-style-type: circle;
  }

  a.portal-link {
    text-decoration: none;
  }
  .portal-link-section {
    height: 95px;
    overflow: auto;
  }
}
</style>
