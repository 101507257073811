<template>
  <v-layout
    class="h-100 w-100"
    justify-center
    wrap>
    <div
      v-if="!sourcesLoaded"
      class="d-flex align-center justify-center h-100 w-100"
      wrap>
      <span>
        <v-progress-circular
          class="center"
          color="primary"
          indeterminate/>
      </span>
    </div>
    <v-flex
      v-else-if="items.length === 0"
      lg8
      md10
      xs12>
      <v-card>
        <v-card-title>
          <div>
            <h3 class="headline">
              There are no data sources available.
            </h3>
          </div>
        </v-card-title>
      </v-card>
    </v-flex>
    <div
      v-else
      class="pt-12">
      <v-flex
        v-for="(item, index) in items"
        :key="index"
        class="portal-card-container"
        xl3
        lg4
        md5
        sm6
        xs7>
        <v-card
          class="portal-card mx-7 mb-7"
          width="310">
          <a
            :href="`/#/data-admin/${item.type}-data-source`"
            class="portal-link">
            <v-card-title class="white--text primary justify-center">
              {{ item.title }}
            </v-card-title>
            <v-badge
              :value="sourceFailed"
              class="portal-badge"
              color="warning"
              icon="mdi-alert"
              offset-x="55"
              offset-y="60"
              bottom
              overlap>
              <v-tooltip
                v-if="sourceFailed"
                right>
                <template #activator="{ on }">
                  <v-icon
                    class="secondary--text ma-3 center"
                    v-on="on">
                    mdi-laptop
                  </v-icon>
                </template>
                <span>One or more jobs have failed</span>
              </v-tooltip>
              <v-icon
                v-else
                class="secondary--text ma-3 center">
                mdi-laptop
              </v-icon>
            </v-badge>
            <div class="portal-link-section pa-4 black">
              <span
                class="white--text"
                v-text="`View the status of each of the ${item.title} data fetch jobs.`"/>
            </div>
          </a>
        </v-card>
      </v-flex>
    </div>
  </v-layout>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdminPortal',
  computed: {
    items() {
      const items = [];
      if (this.sources.length > 0) {
        this.sources.forEach((source) => {
          if (!items.find((item) => item.type === source.source_type)) {
            items.push({
              type: source.source_type,
              title: source.source_title,
            });
          }
        });
      }
      return items;
    },
    ...mapGetters([
      'sourceFailed',
      'sources',
      'sourcesLoaded',
    ]),
  },
  watch: {
    sourcesLoaded: {
      handler() {
        if (this.$route.name === 'AdminPortal' && this.sourcesLoaded) {
          const now = moment();
          const menuData = {
            timestamp: now,
            menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
    },
  },
  mounted() {
    if (this.sourcesLoaded) {
      const now = moment();
      const menuData = {
        timestamp: now,
        menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
      };
      this.setMenuNavigationEnd(menuData);
    }
  },
  methods: {
    navigateTo(link) {
      this.$router.push({ name: link });
    },
    ...mapActions([
      'setMenuNavigationEnd',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.portal-card-container {
  min-width: 250px;
  max-width: 380px;
}
.portal-card {
  @import '../../sass/colors.scss';
  display: inline-block;
  text-align: center;

  i.mdi {
    font-size: 200px;
    margin: 0 auto;
  }

  &:hover {
    cursor: pointer;
  }

  li {
    list-style-type: circle;
  }

  a.portal-link {
    text-decoration: none;
  }
  .portal-link-section {
    height: 95px;
    overflow: auto;
  }
}
</style>
