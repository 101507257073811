<template>
  <div
    v-if="user"
    class="user-profile">
    <v-container>
      <div class="d-flex justify-center">
        <v-dialog
          v-model="mfaRequiredDialog"
          max-width="500">
          <v-card>
            <v-card-title
              class="headline">
              2-Step Verification
            </v-card-title>
            <v-card-text>
              Your organization requires that you set up 2-step verification for your account.
              Please click the
              <b class="secondary--text">
                Add 2-Step Verification
              </b>
              button to complete the setup.
            </v-card-text>
            <v-card-actions
              class="justify-end">
              <v-btn
                color="primary"
                depressed
                @click="mfaRequiredDialog = false">
                Okay
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="confirmDialog"
          max-width="350">
          <v-card>
            <v-card-title
              class="title dialog-title">
              Remove 2-Step Verification?
            </v-card-title>
            <v-card-actions
              class="justify-end">
              <v-btn
                :disabled="removing"
                light
                default
                depressed
                @click="confirmDialog = false">
                No
              </v-btn>
              <v-btn
                :loading="removing"
                :disabled="removing"
                color="primary"
                depressed
                @click="removeSecondFactor">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="authDialog"
          max-width="350">
          <v-card>
            <v-card-title class="headline">
              2-Step Verification
            </v-card-title>
            <v-card-text>
              Please enter the 6-digit verification code sent to your phone.
              <v-text-field
                v-model="token"
                :disabled="verifyLoading"
                name="Code"
                label="Code"
                autofocus
                @keyup.native="(event) => {
                  if (event.key === 'Enter') {
                    verifyCode();
                  }
                }"/>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                :disabled="verifyLoading"
                light
                depressed
                @click="authDialog = false">
                Cancel
              </v-btn>
              <v-btn
                :loading="verifyLoading"
                :disabled="verifyLoading"
                color="secondary"
                depressed
                @click="verifyCode">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="passwordDialog"
          max-width="350">
          <v-card>
            <v-card-title
              class="title dialog-title">
              Please enter your password.
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="authentication"
                name="password"
                label="Password"
                type="password"
                autofocus
                @keyup.native="(event) => {
                  if (event.key === 'Enter') {
                    enrollSecondFactor();
                  }
                }"/>
            </v-card-text>
            <v-card-actions
              class="justify-end">
              <v-btn
                :disabled="passwordLoading"
                light
                default
                depressed
                @click="passwordDialog = false">
                Cancel
              </v-btn>
              <v-btn
                :loading="passwordLoading"
                :disabled="passwordLoading"
                color="secondary"
                depressed
                @click="enrollSecondFactor">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-layout justify-center>
        <v-flex
          xl6
          lg7
          md8
          xs12
          class="profile-flex">
          <v-card
            id="user-profile-card"
            class="mt-12">
            <div>
              <v-card-title class="secondary disable-flex pa-6">
                <div class="float-left">
                  <v-icon class="profile">
                    account_circle
                  </v-icon>
                </div>
                <div class="text-left float-right mr-12 pr-12">
                  <h4 class="white--text headline">
                    {{ name }}
                  </h4>
                  <div class="white--text joined">
                    Joined on {{ user.created.split(' ')[0] }}
                  </div>
                </div>
                <div class="clearfix"/>
              </v-card-title>
            </div>
            <v-card-text
              v-if="!changePasswordPage"
              class="mt-12 pa-12">
              <div class="mt-4 text-left">
                <p class="mb-0 value-header">
                  Username
                </p>
              </div>
              <div>
                <v-text-field
                  v-model="user.username"
                  :disabled="true"
                  name="username"
                  class="my-0 py-0"/>
              </div>
              <div class="text-left">
                <p class="mb-0 value-header">
                  Email
                </p>
              </div>
              <div>
                <v-text-field
                  v-model="user.email"
                  :disabled="true"
                  name="email"
                  class="my-0 py-0"/>
              </div>
              <div
                v-if="internalIdentityProvider"
                class="text-left">
                <p class="mb-0 value-header">
                  Phone Number
                </p>
              </div>
              <div class="d-flex align-center">
                <v-text-field
                  v-if="internalIdentityProvider"
                  v-model="phoneNumber"
                  :disabled="!enrolling"
                  name="phoneNumber"
                  type="tel"
                  class="my-0 py-0"
                  hide-details
                  @keyup.native="(event) => {
                    formatPhone();
                    if (event.key === 'Enter') {
                      passwordDialog = true;
                    }
                    if (event.key === 'Escape') {
                      $refs.cancelButton.$el.focus();
                      enrolling = false;
                    }
                  }"/>
                <v-btn
                  v-if="internalIdentityProvider && enrolling"
                  :disabled="!phoneNumberValid || enrollLoading || passwordDialog"
                  :loading="enrollLoading || passwordDialog"
                  class="white--text change-to-password-page secondary ml-4"
                  depressed
                  @click="passwordDialog = true">
                  Submit
                </v-btn>
                <v-btn
                  v-if="internalIdentityProvider && enrolling"
                  ref="cancelButton"
                  :disabled="enrollLoading || passwordDialog"
                  class="white--text change-to-password-page primary"
                  depressed
                  @click="enrolling = false">
                  Cancel
                </v-btn>
                <span
                  v-if="internalIdentityProvider && phoneNumber && !enrolling"
                  class="auto-vertical-margins">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        :disabled="removing || confirmDialog"
                        color="primary"
                        small
                        text
                        icon
                        v-on="on"
                        @click="confirmDialog = true">
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </template>
                    <span>Click to remove 2-Step Verification.</span>
                  </v-tooltip>
                </span>
              </div>
              <div class="float-left mt-10">
                <v-btn
                  id="log-out-button"
                  class="float-right back black--text"
                  depressed
                  @click="logout">
                  Log Out
                </v-btn>
                <v-btn
                  v-if="internalIdentityProvider"
                  :disabled="enrolling || removing || confirmDialog || passwordDialog"
                  class="float-right white--text change-to-password-page secondary"
                  depressed
                  @click="renderPasswordChange">
                  Change Password
                </v-btn>
                <v-btn
                  v-if="internalIdentityProvider && !phoneNumber || enrolling"
                  :disabled="enrolling"
                  class="float-right white--text change-to-password-page secondary"
                  depressed
                  @click="enrolling = true">
                  Add 2-Step Verification
                </v-btn>
              </div>
              <div
                id="recaptcha-container"
                style="display: none"/>
              <div class="clearfix"/>
            </v-card-text>
            <v-card-text
              v-if="changePasswordPage"
              class="mt-12 pa-12">
              <div>
                <v-text-field
                  v-model="oldPassword"
                  label="Current Password"
                  name="currentPassword"
                  type="password"
                  class="mb-0"/>
              </div>
              <div class="mb-0">
                <p class="text-left mb-0">
                  Your new password must be at least 8 characters and
                  contain an uppercase letter, a lowercase letter, and a number.
                </p>
              </div>
              <div>
                <v-text-field
                  v-model="newPassword"
                  :disabled="loading === 'loading'"
                  :rules="[() => validation()]"
                  label="New Password"
                  name="newPassword"
                  type="password"
                  min="8"/>
              </div>
              <div>
                <v-text-field
                  v-model="newPasswordConfirm"
                  :disabled="loading === 'loading'"
                  :rules="[() => matchTest()]"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  min="8"/>
              </div>
              <div>
                <v-btn
                  :disabled="disabled || !!loading"
                  :loading="loading"
                  class="float-right white--text update-password secondary"
                  depressed
                  @click="changePassword">
                  Update Password
                </v-btn>
                <v-btn
                  light
                  default
                  depressed
                  class="back float-left black--text"
                  @click="backToProfile">
                  Back
                </v-btn>
              </div>
              <div class="clearfix"/>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { EventLog } from '@/lib/event-log';
import { mapActions, mapGetters } from 'vuex';

import cloneDeep from 'lodash.clonedeep';
import identity from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'UserProfile',
  data() {
    return {
      loading: null,
      authentication: null,
      newPassword: null,
      newPasswordConfirm: null,
      oldPassword: null,
      changePasswordPage: false,
      enrolling: false,
      enrollLoading: false,
      passwordLoading: false,
      removing: false,
      phoneNumber: '',
      token: null,
      authDialog: false,
      mfaRequiredDialog: false,
      passwordDialog: false,
      confirmDialog: false,
      recaptchaVerifier: null,
      verificationId: null,
      verifyLoading: false,
    };
  },
  computed: {
    disabled() {
      return !(
        this.newPassword &&
        this.newPassword === this.newPasswordConfirm &&
        this.newPassword.length >= 8 &&
        this.passwordTest() &&
        this.oldPassword
      );
    },
    name() {
      return `${this.user.given_name} ${this.user.family_name}`;
    },
    phoneNumberValid() {
      return this.phoneNumber.replace(/\D/g, '').length === 10;
    },
    ...mapGetters([
      'internalIdentityProvider',
      'multiFactor',
      'multiFactorRequired',
      'tenant',
      'user',
    ]),
  },
  watch: {
    multiFactor: {
      handler() {
        if (this.multiFactor) {
          this.phoneNumber = cloneDeep(this.multiFactor.phoneNumber).substring(2, 12);
          this.formatPhone();
        } else {
          this.phoneNumber = '';
        }
      },
      deep: true,
      immediate: true,
    },
    multiFactorRequired: {
      handler() {
        if (this.multiFactorRequired) {
          this.mfaRequiredDialog = true;
        } else {
          this.mfaRequiredDialog = false;
        }
      },
      deep: true,
      immediate: true,
    },
    authDialog: {
      handler() {
        if (!this.authDialog) {
          this.token = null;
          this.verifyLoading = false;
          this.enrolling = false;
          this.enrollLoading = false;
        }
      },
      deep: true,
      immediate: true,
    },
    passwordDialog: {
      handler() {
        if (!this.passwordDialog) {
          this.authentication = null;
          this.passwordLoading = false;
        }
      },
      deep: true,
      immediate: true,
    },
    enrolling: {
      handler() {
        if (!this.enrolling) {
          this.enrollLoading = false;
          const user = identity.auth().currentUser;
          if (user && user.multiFactor && user.multiFactor.enrolledFactors.length > 0) {
            if (user.multiFactor.enrolledFactors[0].phoneNumber !== this.phoneNumber) {
              this.setMultiFactor(user.multiFactor.enrolledFactors[0]);
            }
          } else {
            this.setMultiFactor();
          }
        }
      },
    },
  },
  mounted() {
    if (this.multiFactorRequired) {
      this.mfaRequiredDialog = true;
    }
    const now = moment();
    const menuData = {
      timestamp: now,
      menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
    };
    this.setMenuNavigationEnd(menuData);
  },
  methods: {
    changePassword() {
      this.loading = 'loading';
      if (this.passwordTest()) {
        const passwordsObject = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        this.$services.users.postChangePassword(this.user.id, passwordsObject).then((json) => {
          if (json) {
            const successLog = new EventLog({
              event: 'account.password.change',
            });
            this.$services.users.postTrackingLog(successLog);
            this.$notify('Your password has been updated.');
            this.changePasswordPage = false;
          }
        }).catch((error) => {
          const failLog = new EventLog({
            event: 'account.password.fail_change',
            error: error.message,
          });
          this.$services.users.postTrackingLog(failLog);
          this.$notify(error);
          Sentry.withScope((scope) => {
            Sentry.setContext('action_attributes', {
              module: 'profile',
              action: 'change_password',
            });
            scope.setLevel('warning');
            Sentry.captureException(new Error(error));
          });
        }).finally(() => {
          this.loading = null;
        });
      }
    },
    formatPhone() {
      const input = cloneDeep(this.phoneNumber.replace(/\D/g, '').substring(0, 10));
      const zip = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        this.phoneNumber = `(${zip}) ${middle} - ${last}`;
      } else if (input.length > 3) {
        this.phoneNumber = `(${zip}) ${middle}`;
      } else if (input.length > 0) {
        this.phoneNumber = `(${zip}`;
      }
    },
    enrollSecondFactor() {
      this.passwordLoading = true;
      if (!this.recaptchaVerifier) {
        // Initialize reCAPTCHA verifier for multi-factor authentication
        this.recaptchaVerifier = new identity.auth.RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',
        });
      }
      const failMessage = 'Adding 2-Step Verification failed. Please try again later.';
      const phoneNumber = `+1${this.phoneNumber.replace(/\D/g, '')}`;
      identity.auth().signInWithEmailAndPassword(this.user.email, this.authentication).then((userInfo) => {
        this.enrollLoading = true;
        this.passwordLoading = false;
        this.passwordDialog = false;
        this.setAccessToken(userInfo.user._lat);
        const user = identity.auth().currentUser;
        user.multiFactor.getSession().then((multiFactorSession) => {
          const phoneInfoOptions = {
            phoneNumber,
            session: multiFactorSession,
          };
          const phoneAuthProvider = new identity.auth.PhoneAuthProvider();
          phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier).then((verificationId) => {
            this.verificationId = verificationId;
            // Ask user for the SMS verification code to continue enrollment
            this.authDialog = true;
          }).catch((error) => {
            const failLog = new EventLog({
              event: 'account.second_factor.fail_add',
              error: error.message,
            });
            this.$services.users.postTrackingLog(failLog);
            this.$notify(failMessage);
            this.enrollLoading = false;
            Sentry.withScope((scope) => {
              Sentry.setContext('action_attributes', {
                module: 'profile',
                action: 'add_second_factor',
                phone_number: phoneNumber,
              });
              scope.setLevel('warning');
              Sentry.captureException(new Error(error));
            });
          });
        }).catch((error) => {
          const failLog = new EventLog({
            event: 'account.second_factor.fail_add',
            error: error.message,
          });
          this.$services.users.postTrackingLog(failLog);
          this.$notify(failMessage);
          this.enrollLoading = false;
          Sentry.withScope((scope) => {
            Sentry.setContext('action_attributes', {
              module: 'profile',
              action: 'add_second_factor',
              phone_number: phoneNumber,
            });
            scope.setLevel('warning');
            Sentry.captureException(new Error(error));
          });
        });
      }).catch((error) => {
        const failLog = new EventLog({
          event: 'account.second_factor.fail_add',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        this.$notify('Invalid password.');
        this.passwordLoading = false;
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'profile',
            action: 'add_second_factor',
            phone_number: phoneNumber,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      });
    },
    removeSecondFactor() {
      this.removing = true;
      const successMessage = '2-Step Verification has been removed.';
      const user = identity.auth().currentUser;
      user.multiFactor.unenroll(user.multiFactor.enrolledFactors[0]).then(() => {
        this.$services.users.postUserMfa(this.user.id, { mfa_enabled: false }).then((updatedUser) => {
          this.setUser(updatedUser);
          this.setMultiFactor();
          const successLog = new EventLog({
            event: 'account.second_factor.remove',
          });
          this.$services.users.postTrackingLog(successLog);
          if (this.tenant.mfa_required) {
            const options = {
              initialLoad: true,
              withClaims: true,
            };
            this.updateTenant(options).then(() => {
              this.$notify(successMessage);
            });
          } else {
            this.$notify(successMessage);
          }
        });
      }).catch((error) => {
        const failLog = new EventLog({
          event: 'account.second_factor.fail_remove',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        this.$notify('Removing 2-Step Verification failed. Please try again later.');
        this.removing = false;
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'profile',
            action: 'remove_second_factor',
            phone_number: this.phoneNumber,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      })
        .finally(() => {
          this.confirmDialog = false;
          this.removing = false;
        });
    },
    verifyCode() {
      this.verifyLoading = true;
      const failMessage = 'Adding 2-Step Verification failed. Please try again later.';
      const successMessage = '2-Step verification is enabled. You can now log in with the extra verification step.';
      try {
        const cred = identity.auth.PhoneAuthProvider.credential(this.verificationId, this.token);
        const multiFactorAssertion = identity.auth.PhoneMultiFactorGenerator.assertion(cred);
        const user = identity.auth().currentUser;
        user.multiFactor.enroll(multiFactorAssertion, 'Phone number').then(() => {
          this.$services.users.postUserMfa(this.user.id, { mfa_enabled: true }).then((updatedUser) => {
            const successLog = new EventLog({
              event: 'account.second_factor.add',
              phone_number: this.phoneNumber,
            });
            this.$services.users.postTrackingLog(successLog);
            if (this.multiFactorRequired) {
              this.setUser(updatedUser);
              const options = {
                initialLoad: true,
                withClaims: true,
              };
              this.updateTenant(options).then(() => {
                this.$notify(successMessage);
              });
            } else {
              this.setUser(updatedUser);
              this.$notify(successMessage);
            }
          }).catch((error) => {
            const updatedUser = identity.auth().currentUser;
            updatedUser.multiFactor.unenroll(updatedUser.multiFactor.enrolledFactors[0]);
            const failLog = new EventLog({
              event: 'account.second_factor.fail_add',
              error: error.message,
            });
            this.$services.users.postTrackingLog(failLog);
            this.$notify(failMessage);
          });
        }).catch((error) => {
          const failLog = new EventLog({
            event: 'account.second_factor.fail_add',
            error: error.message,
          });
          this.$services.users.postTrackingLog(failLog);
          this.$notify(failMessage);
          Sentry.withScope((scope) => {
            Sentry.setContext('action_attributes', {
              module: 'profile',
              action: 'verify_second_factor',
              phone_number: this.phoneNumber,
            });
            scope.setLevel('warning');
            Sentry.captureException(new Error(error));
          });
        })
          .finally(() => {
            this.authDialog = false;
          });
      } catch (error) {
        const failLog = new EventLog({
          event: 'account.second_factor.fail_add',
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        this.$notify(failMessage);
        this.authDialog = false;
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'profile',
            action: 'verify_second_factor',
            phone_number: this.phoneNumber,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }
    },
    matchTest() {
      if (
        this.newPasswordConfirm &&
          this.newPassword !== this.newPasswordConfirm
      ) {
        return 'Passwords do not match.';
      } else {
        return true;
      }
    },
    passwordTest() {
      // TODO: Get this value from the backend
      const strongRegex = /(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}/;
      return strongRegex.test(this.newPassword);
    },
    validation() {
      if (this.newPassword) {
        if (this.newPassword.length < 8) {
          return 'Password too short.';
        } else if (!this.passwordTest()) {
          return 'Password does not contain the required characters.';
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    renderPasswordChange() {
      this.changePasswordPage = true;
    },
    backToProfile() {
      this.changePasswordPage = false;
    },
    logout() {
      this.$router.push({ name: 'LogoutPortal' });
    },
    ...mapActions([
      'setAccessToken',
      'setClaimsToken',
      'setMenuNavigationEnd',
      'setMultiFactor',
      'setTenantSwitchComplete',
      'setUser',
      'updateTenant',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.user-profile {
  @import "../../sass/colors.scss";
  .material-icons.profile {
    font-size: 200px;
    position: absolute;
    top: 4px;
    color: $bainbridge-blue;
  }
  .joined {
    font-size: 14px;
  }
}
</style>
