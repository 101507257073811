<template>
  <v-tooltip
    v-if="linkCopied"
    v-model="linkCopied"
    color="secondary"
    top>
    <template #activator="{ on }">
      <v-btn
        :data-url="url"
        text
        icon
        class="copy-button ma-0"
        v-on="on"
        @click="copyLink">
        <v-icon>content_copy</v-icon>
      </v-btn>
    </template>
    <span class="copied-message">
      Link Copied!
    </span>
  </v-tooltip>
  <v-tooltip
    v-else
    bottom>
    <template #activator="{ on }">
      <v-btn
        :loading="loading"
        :data-url="url"
        :disabled="loading"
        text
        icon
        class="copy-button ma-0"
        v-on="on"
        @click="copyLink">
        <v-icon>content_copy</v-icon>
      </v-btn>
    </template>
    <span>Copy link to share.</span>
  </v-tooltip>
</template>

<script>
import { EventLog } from '@/lib/event-log';

const BASE_URL = window.location.origin;

export default {
  name: 'ShareButton',
  props: {
    pathType: {
      type: String,
      required: true,
    },
    queryParam: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      linkCopied: false,
    };
  },
  computed: {
    url() {
      if (this.pathType === 'base') {
        return `${BASE_URL}/?${this.queryParam}=${this.contentId}#/${this.$route.path.split('/', 2)[1]}`;
      } else if (this.pathType === 'full') {
        if ('tab' in this.$route.query) {
          return `${BASE_URL}/?${this.queryParam}=${this.contentId}&tab=${this.$route.query.tab}#${this.$route.path}`;
        } else {
          return `${BASE_URL}/?${this.queryParam}=${this.contentId}#${this.$route.path}`;
        }
      } else {
        return BASE_URL;
      }
    },
  },
  watch: {
    linkCopied: {
      handler() {
        if (this.linkCopied) {
          setTimeout(() => { this.linkCopied = false; }, 1500);
        }
      },
    },
  },
  methods: {
    copyLink(type) {
      if (!this.linkCopied) {
        const link = document.createElement('textarea');
        link.style.position = 'absolute';
        link.style.top = `${window.pageYOffset || document.documentElement.scrollTop}px`;
        link.value = this.url;

        document.body.appendChild(link);
        link.select();

        const copied = document.execCommand('copy');
        if (copied) {
          this.linkCopied = true;
        }

        document.body.removeChild(link);
      }
      const loggingData = new EventLog({
        event: `${this.contentType}.copy_link`,
        link: this.url,
      });
      this.$services.users.postTrackingLog(loggingData);
    },
  },
};
</script>
