import AutocompleteService from './autocomplete';
import ConfigService from './config';
import Dashboards from './dashboards';
import LibraryService from './library';
import FilesService from './files';
import FilterService from './filters';
import IdentityService from './identity';
import InfusionMetricsService from './infusion-metrics';
import IntelligenceService from './intelligence';
import SourceService from './sources';
import UsersService from './users';

const Services = {
  autocomplete: AutocompleteService,
  config: ConfigService,
  dashboards: Dashboards,
  files: FilesService,
  filters: FilterService,
  identity: IdentityService,
  infusionMetrics: InfusionMetricsService,
  intelligence: IntelligenceService,
  library: LibraryService,
  sources: SourceService,
  users: UsersService,
};

Services.install = (Vue, options) => {
  Object.defineProperty(Vue.prototype, '$services', {
    get() {
      return Services;
    },
  });
};

export default Services;
