<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-icon
        class="file-view-as-icon"
        size="30"
        v-bind="attrs"
        v-on="on">
        {{ viewAsFieldsIcons[viewState] }}
      </v-icon>
    </template>
    <v-list>
      <v-list-item-group
        v-model="viewState"
        mandatory
        @change="emitViewState()">
        <v-list-item
          v-for="(item, index) in viewStateFields"
          ref="viewStateList"
          :key="index"
          :value="item.value">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ViewStateMenu',
    props: {
    viewAs: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      viewState: '',
      viewStateFields: [
        { text: 'as list', value: 'list' },
        { text: 'as small icons', value: 'iconsSmall' },
        { text: 'as large icons', value: 'iconsLarge' },
      ],
      viewAsFieldsIcons: {
        list: 'list',
        iconsSmall: 'apps',
        iconsLarge: 'view_module',
      },
    };
  },
  watch: {
    viewAs() {
      if (this.viewState != this.viewAs)
        this.viewState = this.viewAs
    },
  },
  mounted() {
    this.viewState = this.viewAs
  },
  methods: {
    emitViewState() {
      this.$emit('view-state', this.viewState)
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

  .file-view-as-icon {
    color: $bainbridge-blue !important;
  }
</style>
