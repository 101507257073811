var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"ma-auto expand-button",class:{'expanded': !_vm.collapsed},attrs:{"small":"","absolute":"","fab":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.collapsed = !_vm.collapsed}}},[_c('v-icon',[_vm._v("\n      "+_vm._s(_vm.collapsed ? 'chevron_right' : 'chevron_left')+"\n    ")])],1),_vm._v(" "),_c('v-navigation-drawer',{staticClass:"elevation-5 side-menu bainbridge-gray-light pt-4 px-0 pb-0 align-center justify-center",attrs:{"mini-variant":_vm.collapsed,"mini-variant-width":80,"width":255,"height":"100%","absolute":"","permanent":"","floating":"","app":""}},[_c('img',{staticClass:"bainbridge-name-horiz",attrs:{"src":require("@/assets/images/BH-Alt.svg")},on:{"click":function($event){return _vm.redirectHome()}}}),_vm._v(" "),_c('v-list',{staticClass:"menu-list pt-2 flex-1",class:{'align-center': _vm.collapsed},attrs:{"dense":""}},[_vm._l((_vm.menus),function(item){return _c('NavMenu',{key:((item.title) + "-" + (item.subs.length)),attrs:{"expanded":!_vm.collapsed,"icon":item.icon,"icon-style":item.icon_style,"sub-menus":item.subs,"title":item.title,"url":item.url}})}),_vm._v(" "),_c('v-spacer',{staticClass:"flex-1"}),_vm._v(" "),(_vm.showTenantSelect)?_c('v-menu',{key:"tenant",attrs:{"close-on-content-click":false,"content-class":"tenant-menu pa-4","transition":"slide-x-transition","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{
              'w-100': !_vm.collapsed,
              'mx-0': !_vm.collapsed,
            },attrs:{"icon":_vm.collapsed,"text":"","depressed":""}},on),[_c('v-tooltip',{attrs:{"disabled":_vm.tenantMenu || !_vm.collapsed,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tenantMenuOn = ref.tenantMenuOn;
return [_c('v-icon',_vm._g({class:{
                    'menu-icon': true,
                    'bainbridge-green--text': _vm.tenantMenu,
                    'bainbridge-blue--text': !_vm.tenantMenu,
                    'pr-4': !_vm.collapsed,
                  }},tenantMenuOn),[_vm._v("\n                  people\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Select Tenant")])]),_vm._v(" "),(!_vm.collapsed)?_c('v-subheader',{staticClass:"main-title px-0",class:{
                'bainbridge-green--text': _vm.tenantMenu,
                'bainbridge-blue--text': !_vm.tenantMenu,
              }},[_vm._v("\n              Tenant\n            ")]):_vm._e()],1)]}}],null,false,2004553212),model:{value:(_vm.tenantMenu),callback:function ($$v) {_vm.tenantMenu=$$v},expression:"tenantMenu"}},[_vm._v(" "),_c('v-card',{staticClass:"d-flex elevation-0"},[_c('v-select',{staticClass:"bainbridge-blue--text ma-0 pl-4 py-1 pr-1",attrs:{"items":_vm.tenants,"item-text":"display_name","item-value":"id","hide-details":"","text":"","single-line":"","flat":""},on:{"change":_vm.changeTenant},model:{value:(_vm.selectedTenant),callback:function ($$v) {_vm.selectedTenant=$$v},expression:"selectedTenant"}}),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"loading":_vm.tenantUpdateRequested,"text":"","icon":""},on:{"click":_vm.updateDefaultTenant}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.isTenantDefault ? 'star' : 'star_outline'))])],1)]}}],null,false,3086678428)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.isTenantDefault ? 'Unset tenant as default' : 'Set tenant as default'))])])],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.navbarItems),function(item){return _c('NavMenu',{key:item.title,attrs:{"expanded":!_vm.collapsed,"icon":item.icon,"name":item.name,"title":item.title,"error-state":item.name === 'AdminPortal' && _vm.sourceFailed,"url":item.url}})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }