import Vue from 'vue';

const Bus = {};
const bus = new Vue();

Bus.install = (rootVue, options) => {
  Object.defineProperty(rootVue.prototype, '$bus', {
    get() {
      return bus;
    },
  });
};

export default Bus;
