<template>
  <v-card
    :class="{
      'align-center': loading,
    }"
    class="analytics d-flex flex-column h-100 w-100 elevation-0">
    <v-progress-circular
      v-if="loading || drillLoading"
      indeterminate
      :class="{ 'flex-1': drillLoading }"
      class="primary--text loader"/>
    <FilterBar
      v-if="!loading && dashboardFilters.filterIds.length > 0 && !drillLoading && !openModal"
      :dashboards-loaded="dashboardsLoaded"
      :dashboard-filters="dashboardFilters"
      :are-filters-saved="areFiltersSaved"
      :action-complete="actionComplete"
      :stopping-run="stoppingRun"
      @changeFilters="onChangeFilters"
      @saveFilters="onSaveFilters"
      @updateDefaultUserFilterSet="onUpdateDefaultUserFilterSet"
      @replaceUserFilterSet="onReplaceUserFilterSet"
      @removeUserFilterSet="onRemoveUserFilterSet"
      @stopDashboardRun="onStopDashboardRun"/>
    <div
      v-if="!loading && collectionsViewed.length > 0"
      :class="{ 'flex-1': !drillLoading }"
      class="w-100 px-3">
      <div
        v-for="(collection, id) in dashboardCollections"
        :key="id"
        :class="{ 'hidden': collectionId !== id }"
        class="h-100 w-100">
        <div
          v-if="collection.dashboards.filter(d => d.type !== 'drill_through').length === 1
            && collectionsViewed.includes(id)"
          class="h-100 w-100">
          <div
            v-show="visibleDashboards.length === 1 && visibleDashboard.uri === collection.dashboards[0].uri
              && !drillLoading"
            class="h-100">
            <ContentModal
              :is-visible="openModal"
              @onModalClose="closeModal">
              <template
                v-if="collection.dashboards[0].drill_id && getDrillDashboard(collection.dashboards[0])"
                #content>
                <AnalyticsView
                  v-if="getDrillDashboard(collection.dashboards[0])"
                  v-show="isDrilled(collection.dashboards[0])"
                  :is-visible="isVisible(collection.dashboards[0]) && isDrilled(collection.dashboards[0])"
                  :dashboard-def="getDrillDashboard(collection.dashboards[0])"
                  :filter-values="filterValues"
                  :dashboard-load-status="dashboardLoadStatuses[getDrillDashboard(collection.dashboards[0]).uri]
                    || LoadStatus.LOADING"
                  @updateLoadStatus="updateLoadStatus"/>
              </template>
            </ContentModal>
            <AnalyticsView
              :is-visible="isVisible(collection.dashboards[0])"
              :dashboard-def="collection.dashboards[0]"
              :drill-target="getDrillDashboard(collection.dashboards[0])"
              :filter-values="filterValues"
              :dashboard-load-status="dashboardLoadStatuses[collection.dashboards[0].uri] || LoadStatus.LOADING"
              :reload="forceReloadUri === collection.dashboards[0].uri"
              @updateLoadStatus="updateLoadStatus"
              @drillAction="onDrillAction"
              @linkAction="onLinkAction"
              @cleanFilters="onCleanFilters"/>
          </div>
        </div>
        <div
          v-if="collection.dashboards.filter(d => d.type !== 'drill_through').length !== 1
            && collectionsViewed.includes(id)"
          class="h-100 w-100">
          <div
            v-show="visibleDashboards.length !== 1 && collectionId === id && !drillLoading"
            class="h-100 w-100">
            <v-tabs
              id="analytics-container-tabs"
              v-model="active"
              class="h-100 d-flex flex-column"
              slider-color="secondary"
              grow>
              <v-tab
                v-for="dashboard in collection.dashboards.filter(d => d.type !== 'drill_through')"
                :key="dashboard.uri"
                :active-class="'black--text'"
                class="tab-name"
                ripple
                @click.native="setSelected(dashboard); trackTabNavigationStart();">
                {{ dashboard.name }}
              </v-tab>
              <v-tabs-items
                v-model="active"
                class="h-100 pt-1 d-flex flex-column flex-1 tab-item-container">
                <v-tab-item
                  v-for="dashboard in collection.dashboards.filter(d => d.type !== 'drill_through')"
                  :key="dashboard.uri"
                  class="h-100">
                  <v-card
                    v-show="dashboard.uri === visibleDashboard.uri && !drillLoading"
                    class="h-100 elevation-0">
                    <ContentModal
                      :is-visible="openModal"
                      @onModalClose="closeModal">
                      <template #header-content>
                        <span
                          class="modal-filter-toggle"
                          @click="toggleModalFilter()">
                          {{ showModalFilter ? 'Hide Filters' : 'Show Filters' }}
                        </span>
                      </template>
                      <template
                        #content>
                        <FilterBar
                          v-if="!loading && dashboardFilters.filterIds.length > 0 && !drillLoading"
                          :style="showModalFilter ? '' : 'display: none;'"
                          :dashboards-loaded="dashboardsLoaded"
                          :dashboard-filters="dashboardFilters"
                          :are-filters-saved="areFiltersSaved"
                          :action-complete="actionComplete"
                          :stopping-run="stoppingRun"
                          @changeFilters="onChangeFilters"
                          @saveFilters="onSaveFilters"
                          @updateDefaultUserFilterSet="onUpdateDefaultUserFilterSet"
                          @replaceUserFilterSet="onReplaceUserFilterSet"
                          @removeUserFilterSet="onRemoveUserFilterSet"
                          @stopDashboardRun="onStopDashboardRun"/>
                        <AnalyticsView
                          v-if="getDrillDashboard(dashboard)"
                          v-show="isDrilled(dashboard)"
                          :is-visible="isVisible(dashboard) && isDrilled(dashboard)"
                          :dashboard-def="getDrillDashboard(dashboard)"
                          :filter-values="filterValues"
                          :dashboard-load-status="dashboardLoadStatuses[dashboard.uri] || LoadStatus.LOADING"
                          @updateLoadStatus="updateLoadStatus"/>
                      </template>
                    </ContentModal>
                    <AnalyticsView
                      :is-visible="isVisible(dashboard)"
                      :dashboard-def="dashboard"
                      :drill-target="getDrillDashboard(dashboard)"
                      :filter-values="filterValues"
                      :dashboard-load-status="dashboardLoadStatuses[dashboard.uri] || LoadStatus.LOADING"
                      :reload="forceReloadUri === dashboard.uri"
                      @updateLoadStatus="updateLoadStatus"
                      @drillAction="onDrillAction"
                      @linkAction="onLinkAction"
                      @cleanFilters="onCleanFilters"/>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { LOGGED_OUT_MESSAGE, NO_TENANTS_MESSAGE } from '@/lib/http';
import { EventLog } from '@/lib/event-log';
import { FilterSet } from '@/lib/filter-set';
import { filterHasValue, parseLookerQueryString } from '@/lib/filter-methods';
import { objectsEqual } from '@/lib/compare';
import { mapActions, mapGetters } from 'vuex';
import LoadStatus from '@/lib/enums/load-status-enum';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import AnalyticsView from './AnalyticsView/AnalyticsView.vue';
import ContentModal from '../../utils/modal/ContentModal.vue';
import FilterBar from './FilterBar/FilterBar.vue';

export default {
  name: 'AnalyticsDashboard',
  components: {
    AnalyticsView,
    FilterBar,
    ContentModal,
  },
  props: {
    areFiltersSaved: {
      type: Boolean,
      default: () => true,
    },
    actionComplete: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      active: null,
      openModal: false,
      showModalFilter: false,
      localDashboardId: null,
      dashboards: [],
      collectionsViewed: [],
      dashboardCollections: {},
      visibleDashboard: null,
      drillLoading: false,
      loading: true,
      dashboardLoadStatuses: {},
      drillState: {},
      drillFilterSet: new FilterSet(),
      preDrillFilterSet: new FilterSet(),
      dirtyEmptyFilters: [],
      type: null,
      forceReloadUri: null,
      LoadStatus,
    };
  },
  computed: {
    ...mapGetters([
      'collectionId',
      'filterDefaults',
      'filters',
      'filterSet',
      'menus',
      'tabNavigationStart',
      'user',
      'userFilterSets',
      'userHasCapability',
    ]),
    filterValues() {
      const filterValues = new FilterSet();
      const filterIds = [...this.filterDefaults.filterIds, ...this.filterSet.filterIds];
      filterIds.forEach((filterId) => {
        if (this.filterSet[filterId]) {
          filterValues[filterId] = this.filterSet[filterId];
        } else {
          filterValues[filterId] = this.filterDefaults[filterId];
        }
      }, this);

      return filterValues;
    },
    tabName() {
      return this.visibleDashboard ? this.visibleDashboard.name : null;
    },
    stoppingRun() {
      return this.drillThrough ? (
        this.dashboardLoadStatuses[this.drilledDashboard.uri] === LoadStatus.STOPPING
      ) : this.dashboardLoadStatuses[this.visibleDashboard.uri] === LoadStatus.STOPPING;
    },
    dashboardsLoaded() {
      return this.drillThrough ? (
        this.dashboardLoadStatuses[this.drilledDashboard.uri] !== LoadStatus.LOADING
      ) : this.dashboardLoadStatuses[this.visibleDashboard.uri] !== LoadStatus.LOADING;
    },
    drillThrough() {
      if (this.visibleDashboard) {
        return this.drillState[this.visibleDashboard.uri] === true;
      } else {
        return false;
      }
    },
    drilledDashboard() {
      return this.getDrillDashboard(this.visibleDashboard) || {};
    },
    visibleDashboards() {
      return this.dashboards.filter((d) => d.type !== 'drill_through');
    },
    areFilterQueryParamsSet() {
      const queryParams = { ...this.$route.query };
      const paramKeys = Object.keys(queryParams);
      const { filters } = this;
      return paramKeys.length > 0 && (paramKeys.includes('filter_set') ||
        paramKeys.some((key) => filters.filterIds.some((id) => filters[id].fieldName === key)));
    },
    dashboardFilters() {
      const dashboardFilters = new FilterSet();
      const { filters } = this;
      if (this.drillThrough) {
        this.drilledDashboard.filters.forEach((f) => {
          if (!dashboardFilters.filterIds.includes(f.id)) {
            dashboardFilters[f.id] = filters[f.id];
          }
        }, this);
      } else if (this.visibleDashboard) {
        this.visibleDashboard.filters.forEach((f) => {
          if (!dashboardFilters.filterIds.includes(f.id)) {
            dashboardFilters[f.id] = filters[f.id];
          }
        }, this);
      }
      return dashboardFilters;
    },
  },
  watch: {
    drillThrough(newValue, prevValue) {
      if (!newValue && prevValue) {
        this.filters.filterIds.forEach((filterId) => {
          if (this.preDrillFilterSet[filterId]) {
            const filterValue = cloneDeep(this.preDrillFilterSet[filterId].value);
            this.setFilterValue({ id: filterId, key: 'value', value: filterValue });
            this.refreshFacetedFilters(filterId);
          } else if (this.filterSet[filterId]) {
            this.setFilterValue({ id: filterId, key: 'value', value: null });
          }
        });
        this.filterSet.saveFilterSet().then((response) => {
          const queryParams = { ...this.$route.query };
          queryParams.filter_set = response.success;
          if (!objectsEqual(this.$route.query, queryParams)) {
            this.$router.push({ query: queryParams });
          }
        });
        this.preDrillFilterSet = new FilterSet();
      }
    },
    'collectionId': {
      handler() {
        if (this.collectionId && (this.localDashboardId !== this.collectionId || !this.loading)) {
          this.localDashboardId = cloneDeep(this.collectionId);
          this.loading = true;
          this.fetchDashboards();
        }
      },
    },
    'tabName': {
      handler() {
        if (this.tabName) {
          this.setDashboard(this.tabName);
        }
      },
      deep: true,
    },
    '$route.query': {
      handler() {
        if (!this.drillLoading) {
          this.fetchDashboardQueryParams();
        }
      },
      deep: true,
    },
    'visibleDashboard': {
      handler(newValue, prevValue) {
        // Track visible dashboard changes
        if ((newValue && prevValue && prevValue.name !== newValue.name) || (!prevValue && newValue)) {
          this.trackDashboardNavigation();
        }
        if (this.visibleDashboard) {
          const { visibleDashboard } = this;
          this.active = this.dashboards.findIndex((dashboard) => dashboard.uri === visibleDashboard.uri);
          this.resetDrillState();
          this.filters.filterIds.forEach((filterId) => {
            const dashboardFilter = this.visibleDashboard.filters.find((f) => f.id === filterId);
            if (dashboardFilter && 'required' in dashboardFilter) {
              this.setFilterValue({ id: filterId, key: 'required', value: dashboardFilter.required });
            } else {
              this.setFilterValue({ id: filterId, key: 'required', value: null });
            }
            if (dashboardFilter && 'multiple' in dashboardFilter) {
              this.setFilterValue({ id: filterId, key: 'multiple', value: dashboardFilter.multiple });
            } else {
              this.setFilterValue({ id: filterId, key: 'multiple', value: null });
            }
            if (dashboardFilter && 'default_value' in dashboardFilter) {
              this.setFilterValue({ id: filterId, key: 'dashboardDefaultValue', value: dashboardFilter.default_value });
              this.setFilterValue({ id: filterId, key: 'dashboardDisplayName', value: dashboardFilter.display_name });
            } else {
              this.setFilterValue({ id: filterId, key: 'dashboardDefaultValue', value: null });
              this.setFilterValue({ id: filterId, key: 'dashboardDisplayName', value: null });
            }
            if (dashboardFilter && 'autocomplete_subject' in dashboardFilter) {
              if (this.filters[filterId].dashboardAutocompleteSubject !== dashboardFilter.autocomplete_subject) {
                this.refreshFacetedFilters(filterId);
                this.setFilterValue({ id: filterId, key: 'dashboardAutocompleteSubject', value: dashboardFilter.autocomplete_subject });
                this.setFilterAutocomplete(filterId);
              }
            } else if (dashboardFilter && this.filters[filterId].dashboardAutocompleteSubject) {
              this.refreshFacetedFilters(filterId);
              this.setFilterValue({ id: filterId, key: 'dashboardAutocompleteSubject', value: null });
              this.setFilterAutocomplete(filterId);
            } else if (dashboardFilter && this.filters[filterId].autocompleteSubject &&
              !this.filters[filterId].possibleValues) {
              this.setFilterAutocomplete(filterId);
            }
          }, this);
          if (this.tabNavigationStart && 'timestamp' in this.tabNavigationStart) {
            const now = moment();
            const tabData = {
              timestamp: now,
              tab: this.visibleDashboard.uri,
            };
            this.setTabNavigationEnd(tabData);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    'loading': {
      handler() {
        if (this.$route.meta.analyticsMenu && !this.loading) {
          const now = moment();
          const menuTitle = this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_');
          const menuData = {
            timestamp: now,
            menu: `${menuTitle}.${this.collectionId.replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
      deep: true,
      immediate: true,
    },
    '$route.meta': {
      handler(newValue, prevValue) {
        if (newValue.analyticsMenu && !prevValue.analyticsMenu && !this.loading) {
          const now = moment();
          const menuTitle = this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_');
          const menuData = {
            timestamp: now,
            menu: `${menuTitle}.${this.collectionId.replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.localDashboardId = cloneDeep(this.collectionId);
    this.loading = true;
    if (this.localDashboardId) {
      this.fetchDashboards();
    }
  },
  methods: {
    closeModal() {
      this.openModal = false;
      this.resetDrillState(true);
    },
    toggleModalFilter() {
      this.showModalFilter = !this.showModalFilter;
    },
    /**
     * Read the URL's query params to detect a dashboard tab to natvigate to.
     * Check if the dashboard tab is valid for the page's dashboard collection.
     * Currently runs on mount and when the query changes.
     */
    fetchDashboardQueryParams() {
      if ('tab' in this.$route.query && this.dashboards.length > 0) {
        const dashboard = this.dashboards.find((d) => d.formatted_name === this.$route.query.tab.trim());
        // Delete tab query param if invalid or if page is not a tabbed dashboard view
        if (!dashboard) {
          const query = { ...this.$route.query };
          delete query.tab;
          if (!objectsEqual(this.$route.query, query)) {
            this.$router.push({ query });
          }
        }
        // Set selected dashboard to tab query param if valid or to first dashboard
        if (dashboard) {
          this.visibleDashboard = dashboard;
          if (dashboard.uri === this.dashboards[0].uri) {
            const query = { ...this.$route.query };
            delete query.tab;
            if (!objectsEqual(this.$route.query, query)) {
              this.$router.push({ query });
            }
          }
        } else {
          this.setSelected(this.dashboards[0]);
        }
      }
    },
    /**
     * Fetch the dashboards for the current URI.
     * Does not actually populate the dashboards themselves.
     * Currently runs on mount and when the tenant changes.
     */
    fetchDashboards() {
      if (this.collectionId in this.dashboardCollections) {
        const collection = this.dashboardCollections[this.collectionId];
        this.dashboards = collection.dashboards;
        this.type = collection.type;
        this.fetchDashboardQueryParams();
        let currentDashboard = this.dashboards[0];
        // Set visible dashboard if not already set (relevant if switching tenants, to preserve the selected dashboard)
        if (this.visibleDashboard) {
          currentDashboard = this.dashboards.find((d) => d.uri === this.visibleDashboard.uri) || currentDashboard;
        }
        this.setSelected(currentDashboard);
        const collectionIndex = this.collectionsViewed.indexOf(this.collectionId);
        if (collectionIndex !== -1) {
          this.collectionsViewed.splice(collectionIndex, 1);
        }
        this.collectionsViewed.push(this.collectionId);
        if (this.collectionsViewed.length > 5) {
          this.collectionsViewed.shift();
        }
        this.loading = false;
      } else {
        this.$services.dashboards.getDashboards(this.collectionId).then((collection) => {
          if (collection.dashboards.length === 0) {
            const loggingData = new EventLog({
              event: 'navigate.fail',
              subModule: this.collectionId,
              dashboard: this.collectionId,
            });
            this.$services.users.postTrackingLog(loggingData);
            const currentMenu = this.menus.find((menu) => menu.title === this.$route.name);
            let redirect;
            if ('sections' in currentMenu.subs[0]) {
              redirect = `/${currentMenu.url}/${currentMenu.subs[0].sections[0].url}`;
            } else {
              redirect = `/${currentMenu.url}/${currentMenu.subs[0].url}`;
            }
            if (this.$route.path !== redirect) {
              this.$router.push({ path: redirect });
            }
            this.$notify('The dashboard you navigated to does not exist. You have been redirected to the default dashboard.');
          } else {
            collection.dashboards.forEach((dashboard) => {
              dashboard.formatted_name = dashboard.name.toLowerCase().replace(/ /g, '-');
            });
            const collectionId = collection.id;
            this.dashboards = collection.dashboards;
            this.type = collection.type;
            delete collection.id;
            this.$set(this.dashboardCollections, collectionId, collection);
            this.fetchDashboardQueryParams();
            let currentDashboard = this.dashboards[0];
            // Set visible dashboard if not already set (relevant if switching tenants, to preserve the selected dashboard)
            if (this.visibleDashboard) {
              currentDashboard = this.dashboards.find((d) => d.uri === this.visibleDashboard.uri) || currentDashboard;
            }
            this.setSelected(currentDashboard);
            if (!this.collectionsViewed.includes(this.collectionId)) {
              this.collectionsViewed.push(this.collectionId);
            }
            if (this.collectionsViewed.length > 5) {
              this.collectionsViewed.shift();
            }
            this.loading = false;
          }
        }).catch((error) => {
          if (error === LOGGED_OUT_MESSAGE || error === NO_TENANTS_MESSAGE) {
            this.logout();
            this.$router.push({ name: 'LoginPortal' });
          } else {
            const loggingData = new EventLog({
              event: 'navigate.fail',
              subModule: this.collectionId,
              dashboard: this.collectionId,
            });
            this.$services.users.postTrackingLog(loggingData);
            const currentMenu = this.menus.find((menu) => menu.title === this.$route.name);
            let redirect;
            if ('sections' in currentMenu.subs[0]) {
              redirect = `/${currentMenu.url}/${currentMenu.subs[0].sections[0].url}`;
            } else {
              redirect = `/${currentMenu.url}/${currentMenu.subs[0].url}`;
            }
            if (this.$route.path !== redirect) {
              this.$router.push({ path: redirect });
            }
          }
        });
      }
    },
    /**
    * Assess if the passed dashboard is drilled through.
    */
    isDrilled(dashboard) {
      const drillId = dashboard.drill_id;
      return this.drillThrough && (drillId === this.drilledDashboard.uri ||
        drillId === this.drilledDashboard.looker.name);
    },
    /**
    * Assess if the passed dashboard is visible.
    */
    isVisible(dashboard) {
      return dashboard.uri === this.visibleDashboard.uri;
    },
    /**
    * Return a given dashboard's drill-through dashboard.
    */
    getDrillDashboard(parentDashboard) {
      const drillId = parentDashboard.drill_id;
      return this.dashboards.find((d) => d.uri === drillId);
    },
    /**
    * Set the visible dashboard to the specified dashboard (for tabbed views with multiple dashboards).
    */
    setSelected(dashboard) {
      const query = { ...this.$route.query };
      if (this.dashboards.length > 1) {
        if (this.dashboards[0].uri === dashboard.uri) {
          delete query.tab;
        } else {
          query.tab = dashboard.formatted_name;
        }
        if (!objectsEqual(this.$route.query, query)) {
          this.$router.push({ query });
        }
      }
      this.visibleDashboard = dashboard;
    },
    /**
     * When filter values have been changed in a child component, update URI query and filter set.
     */
    onChangeFilters(filterSetData) {
      const { filterSet, filterSetId, forceRefresh } = filterSetData;
      if (forceRefresh) {
        // Set view state to loading for each data view component to refresh dashboards
        Object.keys(this.dashboardLoadStatuses).forEach((componentId) => {
          this.dashboardLoadStatuses[componentId] = LoadStatus.LOADING;
        });

        const loggingData = new EventLog({
          event: 'filters.refresh',
          filterSetId,
        });
        this.$services.users.postTrackingLog(loggingData);
      } else {
        this.filters.filterIds.forEach((filterId) => {
          const filterValue = filterSet[filterId] ? cloneDeep(filterSet[filterId].value) : null;
          const changed = this.filters[filterId].value !== filterValue;
          if (changed) {
            this.setFilterValue({ id: filterId, key: 'value', value: filterValue });
            this.refreshFacetedFilters(filterId);
            if (filterValue && !filterValue.value && !filterValue.absoluteValue && !filterValue.type === 'date') {
              this.dirtyEmptyFilters.push(filterId);
            }
          }
        });
        if (this.filterSet && !this.filterSet.isEmpty) {
          this.setFilterSetId(filterSetId);
        } else {
          this.setFilterSetId(null);
        }
        const loggingData = new EventLog({
          event: 'filters.run',
          filterSetId,
        });
        this.$services.users.postTrackingLog(loggingData);
      }
    },
    /**
     * Save passed filter set to user's saved filter sets.
     */
    onSaveFilters(userFilterSet) {
      return this.$emit('saveFilters', userFilterSet);
    },
    /**
     * Replace user's default filter set with passed filter set id.
     */
    onUpdateDefaultUserFilterSet(userFilterSetId) {
      this.$emit('updateDefaultUserFilterSet', userFilterSetId);
    },
    /**
     * Replace filter set in user's saved filter sets with passed filter set.
     */
    onReplaceUserFilterSet(updates) {
      this.$emit('replaceUserFilterSet', updates);
    },
    /**
     * Remove passed filter set from user's saved filter sets.
     */
    onRemoveUserFilterSet(userFilterSetId) {
      this.$emit('removeUserFilterSet', userFilterSetId);
    },
    /**
     * Set child view component's state to passed state.
     */
    updateLoadStatus(loadStatus) {
      this.$set(this.dashboardLoadStatuses, loadStatus.componentId, loadStatus.loadStatus);
      if (this.forceReloadUri === loadStatus.componentId && loadStatus.loadStatus !== LoadStatus.LOADING) {
        this.forceReloadUri = null;
      }
    },
    /**
     * Set child view component's drill state to passed state.
     */
    onDrillAction(drillInfo) {
      const {
        filterSet, dashboardId, componentId, state,
      } = drillInfo;
      this.openModal = true;
      if (!dashboardId || !this.visibleDashboard.drill_id) {
        this.failDrill(dashboardId, componentId);
        this.drillLoading = false;
        return;
      }
      const drillFilters = parseLookerQueryString(filterSet, this.filters, true);
      const filterIds = [...this.drillFilterSet.filterIds, ...drillFilters.filterIds];
      filterIds.forEach((filterId) => {
        if (drillFilters[filterId] && filterHasValue(drillFilters[filterId], this.filters[filterId])) {
          this.$set(this.drillFilterSet, filterId, cloneDeep(this.filters[filterId]));
          this.$set(this.drillFilterSet[filterId], 'value', drillFilters[filterId].value);
        } else if (this.drillFilterSet[filterId]) {
          this.$delete(this.drillFilterSet, filterId);
        }
      }, this);
      this.preDrillFilterSet = cloneDeep(this.filterSet);
      this.filters.filterIds.forEach((filterId) => {
        const setFilterValue = this.drillFilterSet[filterId] &&
          filterHasValue(this.drillFilterSet[filterId], this.filters[filterId], true);
        const filterValue = setFilterValue ? cloneDeep(this.drillFilterSet[filterId].value) : null;
        this.setFilterValue({ id: filterId, key: 'value', value: filterValue });
      }, this);
      const self = this;
      this.filterSet.saveFilterSet().then((response) => {
        self.$set(self.drillState, componentId, state);
        self.forceReloadUri = componentId;

        const queryParams = { ...self.$route.query };
        queryParams.filter_set = response.success;
        if (!objectsEqual(self.$route.query, queryParams)) {
          return self.$router.push({ query: queryParams }).then(() => response);
        }

        return response;
      }).then((response) => {
        const dashboardName = self.dashboards.find((d) => d.uri === self.visibleDashboard.drill_id).name;
        self.trackDashboardNavigation(dashboardName, response.success);
      });
    },
    /**
     * Navigate to linked dashboard with specified filters applied.
     */
    onLinkAction(drillInfo) {
      this.drillLoading = true;
      const { filterSet, dashboardId, componentId } = drillInfo;
      if (!dashboardId || !this.visibleDashboard.link_out) {
        this.failDrill(dashboardId, componentId);
        this.drillLoading = false;
      } else {
        const linkFilterSet = parseLookerQueryString(filterSet, this.filters, true);
        this.filters.filterIds.forEach((filterId) => {
          const setFilterValue = linkFilterSet[filterId] &&
            filterHasValue(linkFilterSet[filterId], this.filters[filterId], true);
          const filterValue = setFilterValue ? cloneDeep(linkFilterSet[filterId].value) : null;
          this.setFilterValue({ id: filterId, key: 'value', value: filterValue });
        });
        this.filterSet.saveFilterSet().then((response) => {
          const queryParams = { ...this.$route.query };
          queryParams.filter_set = response.success;
          const [collection, tab] = this.visibleDashboard.link_out.split('/');
          if (tab) {
            queryParams.tab = tab;
          }
          if (collection !== this.collectionId) {
            this.$router.push({
              params: {
                id: collection,
              },
              query: queryParams,
            }).finally(() => {
              this.forceReloadUri = componentId;
              this.drillLoading = false;
            });
          } else if (!objectsEqual(this.$route.query, queryParams)) {
            this.$router.push({ query: queryParams }).finally(() => {
              this.drillLoading = false;
            });
          }
        });
      }
    },
    onCleanFilters() {
      this.dirtyEmptyFilters.forEach((filterId) => {
        this.setFilterValue({ id: filterId, key: 'value', value: null });
      });
      this.filterSet.saveFilterSet();
      this.dirtyEmptyFilters = [];
    },
    /**
     * Emit message and error when drill-through dashboard workflow fails.
     */
    failDrill(drillId, parentDashboardId) {
      this.forceReloadUri = parentDashboardId;
      const loggingData = new EventLog({
        event: 'navigate.fail',
        subModule: this.collectionId,
        dashboard: drillId,
      });
      this.$services.users.postTrackingLog(loggingData);
      Sentry.withScope((scope) => {
        Sentry.setContext('action_attributes', {
          action: 'drill_through',
          subModule: this.collectionId,
          dashboard: drillId,
        });
        Sentry.captureException(new Error('Drill-through dashboard ID not found.'));
      });
      this.$notify('The dashboard you navigated to does not exist. You have been redirected to the previous dashboard.');
    },
    /**
     * Send a message to active dashboard to stop loading data.
     */
    onStopDashboardRun() {
      const dashboard = this.drillThrough ? this.drilledDashboard.uri : this.visibleDashboard.uri;
      this.$set(this.dashboardLoadStatuses, dashboard, LoadStatus.STOPPING);
    },
    /**
     * Reset drill-through dashboard state.
     */
    resetDrillState(track) {
      this.drillState = {};
      if (track) {
        this.trackDashboardNavigation();
      }
    },
    /**
     * Track dashboard navigation.
     */
    trackDashboardNavigation(dashboardName, passedFilterSetId) {
      const dashboard = dashboardName || this.visibleDashboard.name;
      const filterSetId = passedFilterSetId || this.$route.query.filter_set;
      const loggingData = new EventLog({
        event: 'navigate',
        filterSetId,
        dashboard,
      });
      this.$services.users.postTrackingLog(loggingData);
    },
    /**
     * Track when navigation to a dashboard tab has started.
     */
    trackTabNavigationStart() {
      const now = moment();
      const tabData = {
        timestamp: now,
        tab: this.visibleDashboard.uri,
      };
      this.setTabNavigationStart(tabData);
    },
    ...mapActions([
      'logout',
      'refreshFacetedFilters',
      'setDashboard',
      'setFilterAutocomplete',
      'setFilterSetId',
      'setFilterValue',
      'setMenuNavigationEnd',
      'setTabNavigationEnd',
      'setTabNavigationStart',
    ]),
  },
};
</script>
<style lang="scss">

.analytics {
  @import "../../../sass/colors.scss";
  display: flex;
  justify-content: center;

  .hidden {
    display: none;
  }

  .analytics-inner-container {
    display: flex;
    flex-flow: column;

    #analytics-container-tabs {
      .v-tabs__item {
        color: $bainbridge-gray-dark;
      }
    }

    .v-tabs-bar {
      min-height: 48px;
    }
  }

  .tab-name {
    font-size: 14px;
    letter-spacing: normal;
  }

  .drill-through-back-button {
    position: absolute;
  }

  .tab-item-container .v-window__container {
    flex: 1;
  }

  .summary-container {
    top: -8px;
    position: relative;
    flex: 1;
    overflow: scroll;

    .btn--icon {
      height: inherit;
      width: inherit;
    }

    .summary-inner-container {
      border-bottom: 1px solid $bainbridge-gray-light;

      .icon {
        font-size: 18px;
      }

      .summary-headers {
        align-items: center;
        display: flex;
        position: absolute;
        z-index: 2;

        p {
          font-size: 26px;
          letter-spacing: .05em;
          font-weight: 300;
        }

        a,
        p {
          // Explicitly setting z-index as we are rendering this over the <iframe>
          z-index: 1;
        }
      }

      .analytics-view {
        height: unset !important;

        iframe {
          border: none;
          left: 5px;
          position: absolute;
        }
      }
    }
  }
}

.modal-filter-toggle {
  cursor: pointer;
  color: $bainbridge-gray;
  margin: 10px 5px 5px 5px;
}
</style>
