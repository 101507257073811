var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-subheader',{staticClass:"align-center",class:{
    'disabled-filter-label': !_vm.isActive,
  }},[(!_vm.isActive)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({class:{
          'filter-name': !_vm.isOption,
          'option-name': _vm.isOption,
        }},on),[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(!_vm.isActive)?_c('v-icon',{attrs:{"small":""}},[_vm._v("\n          help\n        ")]):_vm._e()],1)]}}],null,false,293253175)},[_vm._v(" "),_c('span',[_vm._v("\n      Filter is set but is inactive for current dashboards.\n    ")])]):_c('span',{class:{
      'filter-name': !_vm.isOption,
      'option-name': _vm.isOption,
    }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }