<template>
  <v-container class="pt-12">
    <v-layout justify-center>
      <v-flex
        lg8
        md10
        xs12>
        <v-card class="text-left">
          <v-card-title class="primary justify-space-between">
            <span class="white--text">
              CareFusion Knowledge Portal Data Source
            </span>
            <span v-if="stream">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="streaming white--text"
                    v-on="on">
                    mdi-broadcast
                  </v-icon>
                </template>
                <span>Source statuses refreshing every 30 seconds</span>
              </v-tooltip>
            </span>
          </v-card-title>
          <v-card-text
            v-if="!sourcesLoaded"
            class="mt-2 text-center">
            <v-progress-circular
              indeterminate
              class="primary--text"/>
          </v-card-text>
          <v-card-text v-else>
            <div
              class="mt-4 pb-2 d-flex align-center justify-space-between">
              <span>
                Monitor each of {{ tenant.display_name }}'s CFKP data fetch jobs.
              </span>
              <div>
                <span>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        id="refresh-button"
                        :loading="loading && !singleSourceRefresh"
                        :disabled="loading && !singleSourceRefresh"
                        class="mx-2 elevation-1"
                        color="primary"
                        fab
                        small
                        v-on="on"
                        @click="refreshStatuses">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Refresh all job statuses</span>
                  </v-tooltip>
                </span>
                <span>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        id="stream-button"
                        :loading="!sourcesLoaded"
                        :disabled="!sourcesLoaded"
                        :color="stream ? 'warning' : 'primary'"
                        class="mx-2 elevation-1"
                        fab
                        small
                        v-on="on"
                        @click="toggleStatusStream">
                        <v-icon>
                          {{ stream ? 'mdi-broadcast-off' : 'mdi-broadcast' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ stream ? 'Stop streaming source statuses' : 'Stream source statuses' }}
                    </span>
                  </v-tooltip>
                </span>
              </div>
            </div>
            <v-list class="pt-0">
              <SourceRow
                v-for="source in sortedSources"
                :key="source.id"
                :source="source"
                :loading="Object.keys(statusLoading).includes(source.id) && statusLoading[source.id]"
                :job-starting="Object.keys(jobStarting).includes(source.id) && jobStarting[source.id]"
                @runSourceJob="runSourceJob"
                @refreshStatus="refreshStatus"/>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { EventLog } from '@/lib/event-log';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import SourceRow from './SourceRow.vue';

export default {
  name: 'CfkpDataSource',
  components: {
    SourceRow,
  },
  data() {
    return {
      jobStarting: {},
      singleSourceRefresh: false,
      statusLoading: {},
      stream: null,
    };
  },
  computed: {
    loading() {
      return Object.keys(this.statusLoading).some((k) => this.statusLoading[k] === true);
    },
    sortedSources() {
      const cfkpSources = cloneDeep(this.sources).filter((s) => s.source_type === 'cfkp');
      return cfkpSources.sort((a, b) => a.id > b.id);
    },
    ...mapGetters([
      'sources',
      'sourcesLoaded',
      'tenant',
    ]),
  },
  watch: {
    '$route.name': {
      handler() {
        if (!this.stream && this.$route.name === 'CfkpDataSource') {
          this.initSourceStream();
        } else if (this.$route.name !== 'CfkpDataSource' && this.stream) {
          clearInterval(this.stream);
          this.stream = null;
        }
      },
      deep: true,
    },
    'sourcesLoaded': {
      handler() {
        if (this.$route.name === 'CfkpDataSource' && this.sourcesLoaded) {
          if (!this.stream) {
            this.initSourceStream();
          }
          const now = moment();
          const menuData = {
            timestamp: now,
            menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
    },
  },
  mounted() {
    if (this.sourcesLoaded) {
      this.initSourceStream();
      const now = moment();
      const menuData = {
        timestamp: now,
        menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
      };
      this.setMenuNavigationEnd(menuData);
    }
  },
  beforeDestroy() {
    clearInterval(this.stream);
    this.stream = null;
  },
  methods: {
    initSourceStream() {
      if (!this.stream) {
        this.stream = setInterval(() => {
          this.streamStatuses();
        }, 30000);
      }
    },
    streamStatuses() {
      this.sortedSources.forEach((source) => this.setSource(source.id));
    },
    toggleStatusStream() {
      if (this.stream) {
        clearInterval(this.stream);
        this.stream = null;
      } else {
        this.initSourceStream();
      }
    },
    refreshStatuses() {
      this.sortedSources.forEach((source) => {
        this.$set(this.statusLoading, source.id, true);
        this.setSource(source.id).then(() => {
          this.$set(this.statusLoading, source.id, false);
        });
      });
    },
    refreshStatus(sourceId) {
      this.singleSourceRefresh = true;
      this.$set(this.statusLoading, sourceId, true);
      this.setSource(sourceId).then(() => {
        const successLog = new EventLog({
          event: 'data_admin.refresh_status',
          source: sourceId,
        });
        this.$services.users.postTrackingLog(successLog);
      }).catch((error) => {
        this.$notify('There was an error refreshing the source\'s status.');
        const failLog = new EventLog({
          event: 'data_admin.fail_refresh_status',
          source: sourceId,
          error,
        });
        this.$services.users.postTrackingLog(failLog);
      }).finally(() => {
        this.$set(this.statusLoading, sourceId, false);
        this.singleSourceRefresh = false;
      });
    },
    runSourceJob(sourceId) {
      this.$set(this.jobStarting, sourceId, true);
      this.$services.sources.runSourceJob(sourceId).then((result) => {
        if (!result.success) {
          this.$delete(this.jobStarting, sourceId);
          this.$notify('There was an error running the source job manually.');
          const failLog = new EventLog({
            event: 'data_admin.fail_run_job',
            source: sourceId,
          });
          this.$services.users.postTrackingLog(failLog);
        } else {
          this.$notify(`Successfully kicked off job for ${sourceId}.`);
          this.setSource(sourceId).then(() => {
            this.$delete(this.jobStarting, sourceId);
          });
          const successLog = new EventLog({
            event: 'data_admin.run_job',
            source: sourceId,
          });
          this.$services.users.postTrackingLog(successLog);
        }
      }).catch((error) => {
        this.$delete(this.jobStarting, sourceId);
        this.$notify('There was an error running the source job manually.');
        const failLog = new EventLog({
          event: 'data_admin.fail_run_job',
          source: sourceId,
          error,
        });
        this.$services.users.postTrackingLog(failLog);
      });
    },
    ...mapActions([
      'setMenuNavigationEnd',
      'setSource',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.headline, .title {
  word-break: break-word;
}
@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.streaming {
  animation: blinking normal 2s infinite ease-in-out;
}
</style>
