<template>
  <div class="list-wrapper">
    <div
      class="file-list-header">
      <div class="content-spacing-header">
        <ListHeader
          header-name="Status"
          sorter-type="status"
          :sorter="sorter.type === 'status' ? sorter : null"
          @sort-by="(type) => $emit('sort-by', type)"/>
        <ListHeader
          header-name="File Name"
          sorter-type="name"
          :sorter="sorter.type === 'name' ? sorter : null"
          :divider="true"
          @sort-by="(headerName) => $emit('sort-by', headerName)"/>
        <ListHeader
          header-name="Kind"
          sorter-type="kind"
          :sorter="sorter.type === 'kind' ? sorter : null"
          :divider="true"
          @sort-by="(headerName) => $emit('sort-by', headerName)"/>
        <ListHeader
          header-name="Date Created"
          sorter-type="date"
          :sorter="sorter.type === 'date' ? sorter : null"
          :divider="true"
          @sort-by="(headerName) => $emit('sort-by', headerName)"/>
      </div>
    </div>
    <div
      class="file-list-container">
      <v-list
        style="padding: 0px"
        dense>
        <v-list-item-group
          v-model="localSelectedFile"
          active-class="selected-item">
          <v-list-item
            v-for="(file, i) in sourceFiles"
            :key="i"
            class="file-item"
            :class="i % 2 ? 'file-item-even' : 'file-item-odd'">
            <v-list-item-content>
              <div class="content-spacing">
                <StatusIndicator
                  :state="file.status.state"
                  parent-type="list"/>
                <span>
                  {{ file.spec.name }}
                </span>
                <span>
                  {{ file.kind }}
                </span>
                <span>
                  {{ dateCreated(file.object.createdAt) }}
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ListHeader from './ListHeader/ListHeader.vue';
import StatusIndicator from '@/components/manual-upload/FileView/StatusIndicator/StatusIndicator.vue';

export default {
  name: 'ListView',
  components: {
    StatusIndicator,
    ListHeader,
  },
  props: {
    sourceFiles: {
      type: Array,
      required: true,
    },
    selectedFile: {
      type: Number,
      required: true,
    },
    sorter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSelectedFile: this.selectedFile,
      statusColor: {
        Available: '#7fb459',
        Pending: '#FFEA00',
        Failed: 'red',
      },
    };
  },
  computed: {
    createdAt() {
      const date = new Date(this.file.object.createdAt);
      const mDate = moment(date).format('MMM DD, YYYY [at] h:mm a');
      return mDate;
    },
  },
  watch: {
    selectedFile(newValue) {
      this.localSelectedFile = newValue;
    },
    localSelectedFile(index) {
      const selected = index >= 0 ? index : -1;
      this.$emit('selected-file', selected);
    },
  },
  methods: {
    dateCreated(date) {
      const mDate = moment(new Date(date)).format('MMM DD, YYYY');
      return mDate;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.list-wrapper {
  flex: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
}

.file-list-header {
  display: grid;
  align-items: center;
  border: 1px solid $bainbridge-gray-light;
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  height: 40px;
  margin: 1rem 1rem 0 1rem;
}

.list-header-title {
  border-left: 1px solid $bainbridge-gray-light;
  padding-left: .5rem;
}

.content-spacing-header {
  display: grid;
  grid-template-columns: 1fr 6fr 4fr 4fr 0;
  margin-left: 1rem;
}

.file-list-container {
  flex: 100%;
  margin: 0 1rem 1rem 1rem;
  overflow-y: auto;
}

.content-spacing {
  display: grid;
  grid-template-columns: 1fr 6fr 4fr 4fr;
  align-items: center;
  margin-left: 1rem;
}

.file-item {
  border-radius: 4px;
  &:hover {
    background-color: $bainbridge-blue;
    color: #fff !important;
  }
}

.file-item-even {
  border: 1px solid $bainbridge-gray-light;
  background-color: #f8f8f8;
}

.file-item-odd {
  border: 1px solid $bainbridge-gray-light;
}

</style>
