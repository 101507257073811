<template>
  <div class="py-5 user-row">
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-icon class="avatar-icon">
          mdi-account-circle
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content
        class="w-100 mr-5 py-0">
        <div v-if="!localEditing">
          <div class="pb-2 primary--text">
            {{ user.email }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Name:
            </span>
            {{ user.given_name }} {{ user.family_name }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Login Method:
            </span>
            {{ formatProvider(user.identity_provider) }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Roles:
            </span>
            {{ userRoleNames.join(', ') }}
          </div>
          <div
            v-if="tenant.allow_multiple"
            class="meta-text">
            <span class="primary--text">
              Tenants:
            </span>
            {{ userTenantNames.join(', ') }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Created:
            </span>
            {{ formatDate(user.created) }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Last Updated:
            </span>
            {{ formatDate(user.last_updated) }}
          </div>
        </div>
        <div v-else>
          <v-form
            ref="userForm"
            v-model="valid"
            lazy-validation>
            <v-text-field
              ref="email"
              v-model="localUser.email"
              :rules="[
                v => !!v || 'Required',
                v => v && validateEmail(v) || 'Invalid email address',
                v => v && tenant.domain.includes(v.split('@')[1]) || 'Email domain does not match tenant domain',
                v => v && !localUserEmails.includes(v.toLowerCase()) || 'Email already exists',
              ]"
              :validate-on-blur="true"
              label="Email"
              :disabled="!localEditing"
              class="w-100 mt-3"
              placeholder="Email"/>
            <v-text-field
              ref="firstName"
              v-model="localUser.given_name"
              :validate-on-blur="true"
              label="First Name"
              :disabled="!localEditing"
              class="w-100 mt-2"
              placeholder="First Name"/>
            <v-text-field
              ref="lastName"
              v-model="localUser.family_name"
              :validate-on-blur="true"
              :disabled="!localEditing"
              label="Last Name"
              class="w-100 mt-2"
              placeholder="Last Name"/>
            <v-combobox
              ref="loginMethod"
              v-model="localUser.identity_provider"
              :items="sortedProviders"
              :search-input.sync="providerQuery"
              :rules="[
                v => !!v || 'Required',
                v => sortedProviders.includes(v) || 'Invalid selection',
              ]"
              :validate-on-blur="true"
              label="Login Method"
              :disabled="!localEditing"
              class="w-100 mt-2"
              placeholder="Login Method"
              @input="providerQuery = ''"/>
            <v-combobox
              v-if="sortedRoles.length > 0"
              ref="roles"
              v-model="localUserRoleNames"
              :items="sortedRoles"
              :search-input.sync="roleQuery"
              :rules="[
                v => v.length > 0 || 'Required',
                v => v.every(r => sortedRoles.includes(r)) || 'One or more values are invalid',
              ]"
              :validate-on-blur="true"
              label="Roles"
              :disabled="!localEditing"
              class="w-100 mt-2"
              placeholder="Roles"
              multiple
              chips
              small-chips
              @input="roleQuery = ''"/>
            <v-combobox
              v-if="tenant.allow_multiple && sortedTenants.length > 0"
              ref="tenants"
              v-model="localUserTenantNames"
              :items="sortedTenants"
              :search-input.sync="tenantQuery"
              :rules="[
                v => v.every(t => sortedTenants.includes(t)) || 'One or more values are invalid',
              ]"
              :validate-on-blur="true"
              label="Tenants"
              :disabled="!editing"
              class="w-100 mt-2"
              placeholder="Tenants"
              multiple
              chips
              small-chips
              @input="tenantQuery = ''"/>
          </v-form>
        </div>
      </v-list-item-content>
      <v-list-item-action
        v-for="action in actions"
        :key="action.icon"
        class="mx-1 float-right user-action">
        <v-tooltip
          bottom>
          <template #activator="{ on }">
            <v-btn
              :loading="action.loading"
              :disabled="action.loading || action.disabled"
              :class="action.class"
              class="ma-0"
              text
              icon
              v-on="on"
              @click="action.click">
              <v-icon>{{ action.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ action.tooltip }}</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
    <v-dialog
      v-model="confirmDialog"
      max-width="400">
      <v-card>
        <v-card-title
          class="title dialog-title">
          {{ confirmMessage }}
        </v-card-title>
        <v-card-actions
          class="justify-end">
          <v-btn
            text
            @click="confirmDialog = false">
            No
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="confirmAction">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';

import { arraysEqual } from '@/lib/compare';
import {
  EMAIL_REGEX,
  INTERNAL_IDENTITY_PROVIDER,
} from '@/lib/identity-methods';

export default {
  name: 'UserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      default: () => false,
    },
    save: {
      type: Boolean,
      default: () => false,
    },
    saveComplete: {
      type: Boolean,
      default: () => false,
    },
    providerOptions: {
      type: Object,
      required: true,
    },
    resetComplete: {
      type: Boolean,
      default: () => true,
    },
    userEmails: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      localUser: {},
      localUserRoleNames: [],
      localUserTenantNames: [],
      localEditing: false,
      providerQuery: '',
      roleQuery: '',
      tenantQuery: '',
      confirmDialog: false,
      confirmMessage: '',
      confirmAction: '',
      userUpToDate: true,
      resetRequested: false,
      disableRequested: false,
    };
  },
  computed: {
    actions() {
      const actions = [
        {
          active: this.localEditing,
          disabled: !this.userUpToDate,
          click: this.cancelEditing,
          class: 'user-cancel-button',
          icon: 'mdi-pencil-off',
          tooltip: 'Cancel edits',
        },
        {
          active: !this.localEditing,
          disabled: !this.userUpToDate,
          click: this.startEditing,
          class: 'user-edit-button',
          icon: 'mdi-pencil',
          tooltip: 'Edit user information',
        },
        {
          active: this.localEditing,
          loading: !this.userUpToDate,
          disabled: !this.valid || !this.userInfoChanged,
          click: this.updateUser,
          class: 'user-save-button',
          icon: 'mdi-content-save',
          tooltip: 'Save user information',
        },
        {
          active: this.user.identity_provider === INTERNAL_IDENTITY_PROVIDER,
          loading: this.resetRequested,
          disabled: this.resetRequested || !this.userUpToDate,
          click: () => this.showDialog(`Reset password for ${this.user.username}?`, this.resetPassword),
          class: 'user-reset-password-button',
          icon: 'mdi-lock-reset',
          tooltip: 'Reset password',
        },
        {
          active: true,
          loading: this.disableRequested,
          disabled: this.disableRequested || !this.userUpToDate,
          click: () => this.showDialog(`Disable ${this.user.username}? They will no longer be able to log in.`, this.disableUser),
          class: 'user-remove-button',
          icon: 'mdi-delete',
          tooltip: 'Disable user account',
        },
      ];
      return actions.filter((action) => action.active);
    },
    localUserEmails() {
      return this.userEmails.filter((email) => email !== this.user.email.toLowerCase());
    },
    userInfoChanged() {
      return !(
        this.localUser.email === this.user.email &&
        this.localUser.given_name === this.user.given_name &&
        this.localUser.family_name === this.user.family_name &&
        this.localUser.identity_provider === this.formatProvider(this.user.identity_provider) &&
        arraysEqual(this.localUserRoleNames, this.userRoleNames) &&
        arraysEqual(this.localUserTenantNames, this.userTenantNames)
      );
    },
    userRoleNames() {
      const filteredRoles = this.user.roles.filter((r) => this.allowedRoles.some((role) => role.id === r.id));
      return filteredRoles.map((r) => r.display_name);
    },
    userTenantNames() {
      if (this.tenant.allow_multiple) {
        return this.user.tenants.map((t) => t.name);
      } else {
        return [];
      }
    },
    sortedProviders() {
      const results = Object.values(this.providerOptions);
      results.sort();
      if (this.providerQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.providerQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.providerQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    sortedRoles() {
      let results = this.allowedRoles.map((r) => r.display_name);
      results.forEach((result) => {
        if (this.localUserRoleNames.includes(result)) {
          const selectedRole = this.allowedRoles.find((r) => r.display_name === result);
          const parentRole = this.allowedRoles.find((r) => r.id === selectedRole.parent);
          const childRoles = this.allowedRoles.filter((r) => r.parent === selectedRole.id);
          const siblingRoles = this.allowedRoles
            .filter((r) => r.parent === selectedRole.parent && r.id !== selectedRole.id);
          if (childRoles.length > 0) {
            results = results.filter((r) => childRoles.every((role) => r !== role.display_name));
          }
          if (siblingRoles.length > 0) {
            results = results.filter((r) => siblingRoles.every((role) => r !== role.display_name));
          }
          if (parentRole) {
            results = results.filter((r) => r !== parentRole.display_name);
          }
        }
      });
      results.sort();
      if (this.roleQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.roleQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.roleQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    sortedTenants() {
      const results = this.allowedTenants.map((t) => t.name).filter((t) => t !== this.tenant.name);
      results.sort();
      if (this.tenantQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.tenantQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.tenantQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    ...mapGetters([
      'allowedRoles',
      'allowedTenants',
      'tenant',
    ]),
  },
  watch: {
    editing: {
      handler() {
        this.localEditing = cloneDeep(this.editing);
        if (!this.editing) {
          this.resetLocalUser();
        }
      },
      deep: true,
      immediate: true,
    },
    localEditing: {
      handler() {
        const editing = {
          id: this.user.id,
          editing: this.localEditing,
        };
        this.$emit('editingUser', editing);
      },
      deep: true,
      immediate: true,
    },
    save: {
      handler() {
        if (this.save) {
          if (!this.valid || !this.userInfoChanged) {
            this.resetLocalUser();
          } else {
            this.updateUser();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    saveComplete: {
      handler() {
        if (this.saveComplete) {
          this.localEditing = false;
        }
      },
      deep: true,
      immediate: true,
    },
    userInfoChanged: {
      handler() {
        const changes = {
          id: this.user.id,
          changes: this.userInfoChanged,
        };
        this.$emit('userInfoChanged', changes);
      },
      deep: true,
      immediate: true,
    },
    resetComplete: {
      handler() {
        if (this.resetComplete) {
          this.resetRequested = false;
        }
      },
      deep: true,
      immediate: true,
    },
    user: {
      handler() {
        this.resetLocalUser();
      },
      deep: true,
      immediate: true,
    },
    allowedRoles: {
      handler() {
        const filteredRoles = this.localUser.roles.filter((r) => this.allowedRoles.some((role) => role.id === r.id));
        this.localUserRoleNames = filteredRoles.map((r) => r.display_name).sort();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    validateEmail(email) {
      return EMAIL_REGEX.test(String(email).toLowerCase());
    },
    resetLocalUser() {
      Object.keys(this.user).forEach((key) => {
        if (key === 'identity_provider') {
          this.$set(this.localUser, key, this.formatProvider(this.user[key]));
        } else {
          this.$set(this.localUser, key, this.user[key]);
        }
      });
      if (this.tenant.allow_multiple) {
        this.localUserTenantNames = this.localUser.tenants.map((t) => t.name).sort();
      }
      this.userUpToDate = true;
      this.disableRequested = false;
      this.localEditing = false;
    },
    formatDate(date) {
      return moment(date).utc().format('MMMM Do, YYYY');
    },
    formatProvider(provider) {
      return this.providerOptions[provider];
    },
    startEditing() {
      this.localEditing = true;
    },
    cancelEditing() {
      this.resetLocalUser();
    },
    showDialog(message, action) {
      this.confirmDialog = true;
      this.confirmMessage = message;
      this.confirmAction = action;
    },
    disableUser() {
      this.disableRequested = true;
      const userInfo = {
        id: this.user.id,
        email: this.user.email,
      };
      userInfo.is_active = false;
      this.$emit('updateUser', userInfo);
      this.confirmDialog = false;
    },
    resetPassword() {
      this.resetRequested = true;
      this.$emit('resetPassword', { email: this.user.email });
      this.confirmDialog = false;
    },
    updateUser() {
      this.$refs.userForm.validate();
      if (this.valid && this.userInfoChanged) {
        const userInfo = {
          id: this.user.id,
          email: this.user.email,
        };
        if (this.localUser.email !== this.user.email) {
          userInfo.email = this.localUser.email;
        }
        if (this.localUser.given_name !== this.user.given_name) {
          userInfo.given_name = this.localUser.given_name;
        }
        if (this.localUser.family_name !== this.user.family_name) {
          userInfo.family_name = this.localUser.family_name;
        }
        if (this.localUser.identity_provider !== this.formatProvider(this.user.identity_provider)) {
          userInfo.identity_provider = Object.keys(this.providerOptions)
            .find((key) => this.providerOptions[key] === this.localUser.identity_provider);
        }
        if (!arraysEqual(this.localUserRoleNames, this.userRoleNames)) {
          userInfo.roles = this.localUserRoleNames.map(
            (r) => this.allowedRoles.find((role) => role.display_name === r).id,
          );
          const nonTenantRoles = this.user.roles.filter((r) => !this.allowedRoles.some((role) => role.id === r.id));
          userInfo.roles.concat(nonTenantRoles);
        }
        if (this.tenant.allow_multiple && !arraysEqual(this.localUserTenantNames, this.userTenantNames)) {
          userInfo.tenants = this.localUserTenantNames
            .map((t) => this.allowedTenants.find((tenant) => tenant.name === t).id);
        }
        this.userUpToDate = false;
        this.$emit('updateUser', userInfo);
      }
    },
  },
};
</script>

<style lang="scss">
.user-row {
  .avatar-icon {
    font-size: 50px;
  }
  .meta-text {
    font-size: 12px;
  }
  .user-action {
    min-width: 30px;
  }
  .user-action button {
    height: 30px;
    width: 30px;
    margin: 1px;
  }
  .dialog-title {
    line-height: unset !important;
  }
}
.title {
  word-break: break-word;
}
</style>
