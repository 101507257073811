<template>
  <div
    class="w-100"
    style="height: calc(100% - 3rem)">
    <FilterBar
      :raw-list="files[selectedSource]"
      @filter-list="(updatedList) => filteredFiles = updatedList"/>
    <div class="directory-view h-100">
      <div class="source-view">
        <div class="card-header">
          <span>Sources</span>
        </div>
        <div
          v-for="(value, propertyName) in files"
          :key="propertyName">
          <SourceView
            :title="propertyName"
            :is-selected="selectedSource === propertyName"
            @source-clicked="(title) => selectSource(title)"/>
        </div>
      </div>
      <div
        v-if="selectedSource"
        class="source-content">
        <FileView
          v-if="filteredFiles"
          :selected-source="selectedSource"
          :source-files="filteredFiles"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterBar from './FilterBar/FilterBar.vue';
import FileView from './FileView/FileView.vue';
import SourceView from './Source/SourceView.vue';

export default {
  name: 'UploadDashboard',
  components: {
    FilterBar,
    FileView,
    SourceView,
  },
  data() {
    return {
      selectedSource: 'Inbox',
      filteredFiles: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'files',
      'filesLoaded',
    ]),
  },
  async mounted() {
    if (!this.filesLoaded) {
      this.loading = true;
      await this.fetchFiles('ls=inbox');
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([
      'fetchFiles',
    ]),
    selectSource(title) {
      this.selectedSource = title;
      this.selectedFile = null;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.card-header {
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding: .5rem 1rem .5rem 1rem;
  font-size: 20px;
  font-weight: 400;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.directory-view {
  display: flex;
  height: auto;
}

.directory-spacer {
  border-right: 1px solid $bainbridge-gray-light;
  height: 100%;
}

.source-view {
  flex:25%;
  margin: 2rem 0 2rem 2rem;
  height: auto;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.source-content {
  flex: 75%;
  height: 100%;
}
</style>
