var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-12"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"lg8":"","md10":"","xs12":""}},[_c('v-card',{staticClass:"text-left"},[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"white--text"},[_vm._v("\n            User Configuration\n          ")])]),_vm._v(" "),(_vm.loading)?_c('v-card-text',{staticClass:"mt-2 text-center"},[_c('v-progress-circular',{staticClass:"primary--text",attrs:{"indeterminate":""}})],1):_c('v-card-text',[_c('div',{staticClass:"mt-4 pb-2 d-flex align-center justify-space-between"},[(!_vm.usersExist)?_c('span',[_vm._v("\n              There are no users for your organization. Click the new user button to create a user.\n            ")]):_c('span',[_vm._v("\n              Manage your organization's users.\n            ")]),_vm._v(" "),_c('div',[(_vm.usersExist && !_vm.editingUser)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"id":"edit-button","disabled":_vm.updateLoading || _vm.addLoading,"color":"primary","fab":"","small":""},on:{"click":function($event){_vm.editingAll = true}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,3279762015)},[_vm._v(" "),_c('span',[_vm._v("Edit all users")])])],1):_vm._e(),_vm._v(" "),(_vm.usersExist && _vm.editingUser)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"id":"cancel-button","disabled":_vm.updateLoading || _vm.addLoading,"color":"primary","fab":"","small":""},on:{"click":_vm.cancelEdits}},on),[_c('v-icon',[_vm._v("mdi-pencil-off")])],1)]}}],null,false,1621838933)},[_vm._v(" "),_c('span',[_vm._v("Cancel edits for all users")])])],1):_vm._e(),_vm._v(" "),(_vm.usersExist && _vm.editingUser)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"id":"save-button","disabled":_vm.updateLoading || _vm.addLoading || !_vm.changesMade,"color":"primary","fab":"","small":""},on:{"click":function($event){_vm.saveAll = true}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,1543161598)},[_vm._v(" "),_c('span',[_vm._v("Save updates for all users")])])],1):_vm._e(),_vm._v(" "),_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"id":"add-button","loading":_vm.addLoading,"disabled":_vm.updateLoading || _vm.addLoading,"color":"secondary","fab":"","small":""},on:{"click":function($event){_vm.createUserDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Create a new user")])])],1)])]),_vm._v(" "),(_vm.usersExist)?_c('v-list',{staticClass:"pt-0"},_vm._l((_vm.sortedUsers),function(user){return _c('div',{key:user.id},[(user.userType === 'domain')?_c('UserRow',{key:user.id,attrs:{"user":user,"editing":_vm.userEditing[user.id],"save":_vm.saveAll,"save-complete":_vm.saveComplete[user.id],"provider-options":_vm.providerOptions,"user-emails":_vm.userEmails,"reset-complete":!Object.keys(_vm.resetLoading).includes(user.email)},on:{"editingUser":_vm.trackEditing,"userInfoChanged":_vm.trackChanges,"updateUser":_vm.updateUser,"resetPassword":_vm.resetPassword}}):_vm._e()],1)}),0):_vm._e()],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.createUserDialog),callback:function ($$v) {_vm.createUserDialog=$$v},expression:"createUserDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"title dialog-title"},[_vm._v("\n        Create User\n      ")]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"createUserForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"w-100 mt-2",attrs:{"rules":[
              function (v) { return !!v || 'Required'; },
              function (v) { return v && _vm.validateEmail(v) || 'Invalid email address'; },
              function (v) { return v && _vm.tenant.domain.includes(v.split('@')[1]) || 'Email domain does not match tenant domain'; },
              function (v) { return v && !_vm.userEmails.includes(v.toLowerCase()) || 'Email already exists'; } ],"validate-on-blur":true,"disabled":_vm.addLoading,"placeholder":"Email"},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}}),_vm._v(" "),_c('v-text-field',{staticClass:"w-100 mt-2",attrs:{"validate-on-blur":true,"disabled":_vm.addLoading,"placeholder":"First Name"},model:{value:(_vm.newUser.given_name),callback:function ($$v) {_vm.$set(_vm.newUser, "given_name", $$v)},expression:"newUser.given_name"}}),_vm._v(" "),_c('v-text-field',{staticClass:"w-100 mt-2",attrs:{"validate-on-blur":true,"disabled":_vm.addLoading,"placeholder":"Last Name"},model:{value:(_vm.newUser.family_name),callback:function ($$v) {_vm.$set(_vm.newUser, "family_name", $$v)},expression:"newUser.family_name"}}),_vm._v(" "),_c('v-combobox',{staticClass:"w-100 mt-2",attrs:{"items":_vm.sortedProviders,"search-input":_vm.providerQuery,"rules":[
              function (v) { return !!v || 'Required'; },
              function (v) { return _vm.sortedProviders.includes(v) || 'Invalid selection'; } ],"validate-on-blur":true,"disabled":_vm.addLoading,"placeholder":"Login Method"},on:{"update:searchInput":function($event){_vm.providerQuery=$event},"update:search-input":function($event){_vm.providerQuery=$event},"input":function($event){_vm.providerQuery = ''}},model:{value:(_vm.newUser.identity_provider),callback:function ($$v) {_vm.$set(_vm.newUser, "identity_provider", $$v)},expression:"newUser.identity_provider"}}),_vm._v(" "),(_vm.sortedRoles.length > 0)?_c('v-combobox',{staticClass:"w-100 mt-2",attrs:{"items":_vm.sortedRoles,"search-input":_vm.roleQuery,"rules":[
              function (v) { return v.length > 0 || 'Required'; },
              function (v) { return v.every(function (r) { return _vm.sortedRoles.includes(r); }) || 'One or more values are invalid'; } ],"validate-on-blur":true,"disabled":_vm.addLoading,"placeholder":"Roles","multiple":"","chips":"","small-chips":""},on:{"update:searchInput":function($event){_vm.roleQuery=$event},"update:search-input":function($event){_vm.roleQuery=$event},"input":function($event){_vm.roleQuery = ''}},model:{value:(_vm.newUserRoleNames),callback:function ($$v) {_vm.newUserRoleNames=$$v},expression:"newUserRoleNames"}}):_vm._e(),_vm._v(" "),(_vm.tenant.allow_multiple && _vm.sortedTenants.length > 0)?_c('v-combobox',{staticClass:"w-100 mt-2",attrs:{"items":_vm.sortedTenants,"search-input":_vm.tenantQuery,"rules":[
              function (v) { return v.every(function (t) { return _vm.sortedTenants.includes(t); }) || 'One or more values are invalid'; } ],"validate-on-blur":true,"disabled":_vm.addLoading,"placeholder":"Tenants","multiple":"","chips":"","small-chips":""},on:{"update:searchInput":function($event){_vm.tenantQuery=$event},"update:search-input":function($event){_vm.tenantQuery=$event},"input":function($event){_vm.tenantQuery = ''}},model:{value:(_vm.tenantNames),callback:function ($$v) {_vm.tenantNames=$$v},expression:"tenantNames"}}):_vm._e()],1)],1),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.createUserDialog = false}}},[_vm._v("\n          Cancel\n        ")]),_vm._v(" "),_c('v-btn',{attrs:{"loading":_vm.addLoading,"disabled":_vm.addDisabled,"color":"secondary","depressed":""},on:{"click":_vm.addUser}},[_vm._v("\n          Submit\n        ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }