<template>
  <div>
    <div
      class="upload-card"
      @click="() => browseModal = true">
      <div class="upload-card-title">
        Upload
      </div>
      <v-icon
        size="20"
        dark>
        file_upload
      </v-icon>
    </div>
    <FileUploadBrowse
      v-if="browseModal"
      :show-modal="browseModal"
      @filesSelected="(files) => editFileModal(files)"
      @onCloseBrowse="() => browseModal = false"/>
    <FileUploadPreparer
      v-if="editModal"
      :show-modal="editModal"
      :selected-files="selectedFiles"
      @startFileUpload="(uploadPackage) => uploadFile(uploadPackage)"
      @openBrowseUpload="() => browseFileModal()"
      @onModalClose="() => editModal = false"/>
    <FileUpload
      v-if="uploadModal"
      :upload-package="uploadPackage"
      @onUploadingDone="closeModal()"/>
  </div>
</template>

<script>
import FileUpload from '../FileUpload/FileUpload.vue';
import FileUploadPreparer from '../FileUploadPreparer/FileUploadPreparer.vue';
import FileUploadBrowse from '../FileUploadBrowse/FileUploadBrowse.vue';

export default {
  name: 'FileUploadController',
  components: {
    FileUpload,
    FileUploadBrowse,
    FileUploadPreparer,
  },
  data() {
    return {
      selectedFiles: null,
      uploadPackage: null,
      browseModal: false,
      editModal: false,
      uploadModal: false,
    };
  },
  methods: {
    closeModal() {
      this.uploadModal = false;
      this.selectedFiles = null,
      this.uploadPackage = null;
    },

    browseFileModal() {
      this.editModal = false;
      this.selectedFiles = null;
      this.browseModal = true;
    },

    editFileModal(files) {
      this.browseModal = false;
      this.selectedFiles = files;
      this.editModal = true;
    },

    uploadFile(uploadPackage) {
      this.uploadPackage = uploadPackage;
      this.editModal = false;
      this.uploadModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/colors.scss";

#confirm-upload {
  margin: 15px !important;
  height: 40px;
  width: 120px;
  font-weight: 600;
}
.upload-card {
  display: flex;
  padding: .3rem .8rem .35rem .8rem;
  border-radius: 4px;
  color: #fff;
  background-color: $bainbridge-green;
  font-weight: 600;
  font-size: 12px;
  gap: 12px;

  &:hover {
    cursor: pointer;
    background-color: #8cbc6a;
  }

  &:active {
    background-color: #99c37a;
  }
}

.upload-card-title {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 14px;
}

.custom-file-upload {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#file-upload {
  display: none;
}

.upload-content {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-inputs {
  margin:0 2rem 0 2rem
}
</style>
