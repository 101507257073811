<template>
  <div
    v-if="isVisible"
    class="modal-overlay"
    @click="(event) => clickAway(event)">
    <v-card
      class="modal"
      :class="size ? size : ''">
      <div class="modal-header">
        <div class="modal-header-title">
          <slot name="header-content"/>
        </div>
        <div
          v-if="!hideClose"
          class="close"
          @click="() => closeModal()">
          <v-icon left>
            mdi-window-close
          </v-icon>
        </div>
      </div>
      <div :class="size === 'small' ? 'modal-content-small' : 'modal-content'">
        <slot name="content"/>
      </div>
      <div>
        <slot name="footer-content"/>
      </div>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'ContentModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    allowClickAway: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickAway(event) {
      if (event.target.classList.contains('modal-overlay') && this.allowClickAway) {
        this.closeModal();
      }
    },
    closeModal() {
      if (this.isVisible) {
        this.$emit('onModalClose');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/colors.scss";
.modal-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(133, 133, 133, .5);
}
.modal {
  margin: 0;
  position: absolute;
  top: 50%;
  display: flex;
  flex-flow: column;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  background-color: white;
  overflow: hidden;
  height: calc(95% - 5rem);
  width: calc(95% - 5rem);
  border-radius: .25rem;
}
.modal-header {
  display: flex;
  border-bottom: 1px solid $bainbridge-gray-light;
}
.modal-header-title {
  height: 30px;
  margin: 5px;
  margin-left: 1rem;
  font-size: 20px;
  color: $bainbridge-gray;
}
.modal-content {
  height: calc(100%);
  overflow-y:auto;
}
.modal-content-small {
  height: calc(90%);
  overflow-y:auto;
}
.small {
  height: 200px;
  width: 400px;
}
.medium {
  height: 400px;
  width: 500px;
}

.close {
  margin: 5px 5px 5px auto;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.close-img {
  width: 25px;
}
button {
  background-color: #ac003e;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}
</style>
