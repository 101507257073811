<template>
  <div
    :class="`numeric-filter ${formattedLabel}-row`">
    <v-layout
      class="align-center"
      wrap>
      <v-flex
        xs2
        class="mr-2">
        <FilterLabel
          :label="label"
          :is-active="isActive"/>
      </v-flex>
      <v-flex
        xs3
        class="mr-2">
        <v-select
          v-model="localValue.option"
          :items="possibleModifiers"
          :menu-props="{ 'contentClass': `${formattedLabel}-option-select` }"
          :disabled="!isActive"/>
      </v-flex>
      <v-flex
        v-if="showSecondaryValueField"
        xs2
        class="mr-1">
        <v-select
          v-model="localValue.secondaryOption"
          :items="possibleSecondaryModifiers"
          :menu-props="{ 'contentClass': `${formattedLabel}-secondary-option-select` }"
          :disabled="!isActive"/>
      </v-flex>
      <v-flex
        :xs1="showSecondaryValueField"
        :xs3="!showSecondaryValueField"
        class="mr-1">
        <v-text-field
          ref="numericFilter"
          v-model="localValue.value"
          :rules="valueRequired ? [v => v.length > 0 || 'Required'] : []"
          :disabled="!isActive"
          class="number-field"
          type="number"/>
      </v-flex>
      <v-flex
        v-if="showSecondaryValueField"
        xs1
        class="mr-1">
        <v-subheader
          class="value-separator">
          <span>and</span>
        </v-subheader>
      </v-flex>
      <v-flex
        v-if="showSecondaryValueField"
        xs1>
        <v-text-field
          v-model="localValue.secondaryValue"
          :disabled="!isActive"
          class="number-field"
          type="number"/>
      </v-flex>
      <FilterActions
        :is-active="isActive"
        @removeFilter="removeFilter"/>
    </v-layout>
  </div>
</template>

<script>
import {
  IS_EQUAL_TO,
  IS_LESS_THAN,
  IS_LESS_THAN_OR_EQUAL_TO,
  IS_GREATER_THAN,
  IS_GREATER_THAN_OR_EQUAL_TO,
  IS_BETWEEN,
  IS_NOT_EQUAL_TO,
  IS_NOT_BETWEEN,
} from '@/lib/constants';

import FilterLabel from './FilterLabel.vue';
import FilterActions from './FilterActions.vue';

const DEFAULT_OPTION = IS_EQUAL_TO;
const DEFAULT_SECONDARY_OPTION = 'inclusive';
const DEFAULT_VALUE = null;

export default {
  name: 'NumericFilter',
  components: {
    FilterLabel,
    FilterActions,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    valueRequired: {
      type: Boolean,
      default: () => false,
    },
    passedValue: {
      type: Object,
      default: () => ({ value: DEFAULT_VALUE, option: DEFAULT_OPTION }),
    },
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      localValue: {
        value: DEFAULT_VALUE,
        secondaryValue: DEFAULT_VALUE,
        option: DEFAULT_OPTION,
        secondaryOption: DEFAULT_SECONDARY_OPTION,
      },
      possibleModifiers: [
        { text: IS_EQUAL_TO, value: IS_EQUAL_TO },
        { text: IS_LESS_THAN, value: IS_LESS_THAN },
        { text: IS_LESS_THAN_OR_EQUAL_TO, value: IS_LESS_THAN_OR_EQUAL_TO },
        { text: IS_GREATER_THAN, value: IS_GREATER_THAN },
        { text: IS_GREATER_THAN_OR_EQUAL_TO, value: IS_GREATER_THAN_OR_EQUAL_TO },
        { text: IS_BETWEEN, value: IS_BETWEEN },
        { text: IS_NOT_EQUAL_TO, value: IS_NOT_EQUAL_TO },
        { text: IS_NOT_BETWEEN, value: IS_NOT_BETWEEN },
      ],
      possibleSecondaryModifiers: [
        { text: '[inclusive]', value: 'inclusive' },
        { text: '(exclusive)', value: 'exclusive' },
        { text: '[right-exclusive)', value: 'right-exclusive' },
        { text: '(left-exclusive]', value: 'left-exclusive' },
      ],
    };
  },
  computed: {
    formattedLabel() {
      return this.label.toLowerCase().replace(/ /g, '-');
    },
    showSecondaryValueField() {
      return this.localValue.option === IS_BETWEEN || this.localValue.option === IS_NOT_BETWEEN;
    },
  },
  watch: {
    passedValue: {
      handler() {
        if (this.passedValue) {
          if (this.passedValue.option) {
            this.localValue.option = this.passedValue.option;
          } else {
            this.localValue.option = DEFAULT_OPTION;
          }
          if (this.passedValue.secondaryOption) {
            this.localValue.secondaryOption = this.passedValue.secondaryOption;
          } else {
            this.localValue.secondaryOption = DEFAULT_SECONDARY_OPTION;
          }

          if (this.passedValue.value) {
            if (typeof this.passedValue.value === 'string') {
              try {
                this.localValue.value = parseFloat(this.passedValue.value);
              } catch (e) {
                this.localValue.value = null;
              }
            } else {
              this.localValue.value = this.passedValue.value;
            }
          } else {
            this.localValue.value = DEFAULT_VALUE;
          }

          if (this.passedValue.secondaryValue) {
            if (typeof this.passedValue.secondaryValue === 'string') {
              try {
                this.localValue.secondaryValue = parseFloat(this.passedValue.secondaryValue);
              } catch (e) {
                this.localValue.secondaryValue = null;
              }
            } else {
              this.localValue.secondaryValue = this.passedValue.secondaryValue;
            }
          } else {
            this.localValue.secondaryValue = DEFAULT_VALUE;
          }
        } else {
          this.localValue = {
            value: DEFAULT_VALUE,
            secondaryValue: DEFAULT_VALUE,
            option: DEFAULT_OPTION,
            secondaryOption: DEFAULT_SECONDARY_OPTION,
          };
        }
      },
      immediate: true,
    },
    localValue: {
      handler() {
        this.$refs.numericFilter.validate();
        const value = {
          value: (this.localValue.value || this.localValue.value === 0) ? (
            this.localValue.value.toString()
          ) : DEFAULT_VALUE,
          option: this.localValue.option ? this.localValue.option : DEFAULT_OPTION,
        };

        if (this.showSecondaryValueField) {
          value.secondaryValue = (this.localValue.secondaryValue || this.localValue.secondaryValue === 0) ? (
            this.localValue.secondaryValue.toString()
          ) : DEFAULT_VALUE;
          value.secondaryOption = this.localValue.secondaryOption ? (
            this.localValue.secondaryOption
          ) : DEFAULT_SECONDARY_OPTION;
        }

        if ((this.passedValue && Object.keys(value).some((valueKey) => value[valueKey] !==
          this.passedValue[valueKey]))) {
          this.$emit('valueChanged', {
            key: this.filterId,
            value,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$refs.numericFilter.validate();
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter', this.filterId);
    },
  },
};
</script>
<style lang="scss" scoped>
.numeric-filter {
  .value-separator {
    justify-content: center;
    flex-wrap: wrap;
    span {
      align-self: flex-end;
    }
  }
}
</style>
