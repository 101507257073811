// user-filter-set.js
// User filter set class that defines properties and methods for a set of filters saved by a user.

import { FilterSet } from '@/lib/filter-set';
import FilterServices from '@/services/filters';
import cloneDeep from 'lodash.clonedeep';

export class UserFilterSet {
  constructor(
    created,
    filterSetId,
    filters,
    id,
    isDefault,
    name,
    user,
  ) {
    this.created = created;
    this.filterSetId = filterSetId;

    // Set filters object to passed filters value if value is a FilterSet object
    if (filters && filters instanceof FilterSet) {
      this.filters = filters;
    } else {
      this.filters = new FilterSet();
    }

    this.id = id;
    this.isDefault = isDefault;
    this.name = name;
    this.user = user;
  }

  // Get all filters in user filter set that are not "option" type
  get filtersNoOptions() {
    const filtersNoOptions = new FilterSet();
    this.filters.filterIds.forEach((filterId) => {
      if (!this.filters[filterId].isOption) {
        filtersNoOptions[filterId] = this.filters[filterId];
      }
    });
    return filtersNoOptions;
  }

  // Get all filters in user filter set that are "option" type
  get filterOptions() {
    const filterOptions = new FilterSet();
    this.filters.filterIds.forEach((filterId) => {
      if (!this.filters[filterId].isOption) {
        filterOptions[filterId] = this.filters[filterId];
      }
    });
    return filterOptions;
  }

  // Determine if user filter set contains any filters or not
  get isEmpty() {
    return this.filters.filterIds.length === 0;
  }

  // Delete user filter set by sending a request to API
  delete() {
    return FilterServices.deleteUserFilterSet(this.user, this.id);
  }

  // Save user filter set by sending a request to API
  save() {
    const saveFormat = cloneDeep(this);
    saveFormat.filters = saveFormat.filters.saveFormat;
    return FilterServices.postUserFilterSet(this.user, saveFormat);
  }

  // Update user filter set's filters with passed filter set by sending a request to API
  update(userFilterSet) {
    const saveFormat = cloneDeep(userFilterSet);
    saveFormat.filters = saveFormat.filters.saveFormat;
    return FilterServices.putUserFilterSet(this.user, this.id, saveFormat);
  }

  // Set user filter set as user's default filter set, to be applied when they log in
  updateDefaultStatus(replace) {
    if (this.isDefault) {
      return FilterServices.deleteDefaultUserFilterSet(this.user);
    } else if (replace) {
      return FilterServices.putDefaultUserFilterSet(this.user, this.id);
    }
    return FilterServices.postDefaultUserFilterSet(this.user, this.id);
  }
}

export default {
  UserFilterSet,
};
