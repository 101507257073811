import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import identity from 'firebase/app';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify, { VLayout } from 'vuetify/lib';
import { v1 as uuidv1 } from 'uuid';

import { EventLog } from '@/lib/event-log';
import { INTERNAL_IDENTITY_METHOD } from '@/lib/identity-methods';
import browserDetect from 'vue-browser-detect-plugin';
import theme from './lib/color-theme';
import './sass/icons.scss'; // Icons are loaded separately due to relative namespacing.
import './sass/main.scss';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import Services from './services/index';
import './directives/focus';
import Bus from './bus';
import Notifications from './notifications';

import './lib/remove-polyfill';
import './lib/url-polyfill';

Vue.use(browserDetect);

const queryParams = new URLSearchParams(window.location.search);
const TRACK_ENABLED = queryParams.has('track');
const DEBUG_ENABLED = VUE_DEBUG_ENABLED || TRACK_ENABLED;
if (DEBUG_ENABLED) {
  /* eslint-disable no-console */
  console.info('debug: enabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
  /* eslint-enable no-console */

  Vue.config.devtools = true;
  Vue.config.debug = true;
  Vue.config.silent = false;
} else {
  /* eslint-disable no-console */
  console.info('debug: disabled (t=%s, v=%s)', TRACK_ENABLED, VUE_DEBUG_ENABLED);
  /* eslint-enable no-console */

  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

let vue;

const initializeVue = (message) => {
  if (!vue) {
    Vue.use(Vuetify, {
      components: { VLayout },
    });
    Vue.use(Services);
    Vue.use(Bus);

    vue = new Vue({
      el: '#app',
      components: { App },
      router,
      store,
      vuetify: new Vuetify({ theme }),
      render: (h) => h(App),
    });

    Vue.use(Notifications, { bus: vue.$bus });

    if (DEBUG_ENABLED) {
      window.App = vue;

      /* eslint no-console: ["error", { allow: ["error"] }] */
      console.error('cf. window.App');
    }
  }
  if (message) {
    vue.$notify(message);
  }
};

// Set session ID to track user's current app session
sessionStorage.setItem('track_id', uuidv1());

Services.config.getConfig().then((config) => {
  // Initialize Google Identity Platform
  identity.initializeApp(config.identity);
  initializeVue();

  // Detect redirect results from SSO, as well as users who are already logged in
  identity.auth().onIdTokenChanged((userInfo) => {
    if (userInfo && userInfo._lat) {
      store.dispatch('setAccessToken', userInfo._lat);
      if (!localStorage.getItem('claimsToken') && !store.getters.user) {
        const ssoUser = userInfo.providerData && userInfo.providerData.length > 0 &&
          userInfo.providerData[0].providerId !== INTERNAL_IDENTITY_METHOD;
        if (ssoUser) {
          let accountMatch = true;
          const identityEmail = userInfo.email;
          const signinEmail = localStorage.getItem('signinEmail');
          if (signinEmail) {
            accountMatch = identityEmail && signinEmail.toLowerCase() === identityEmail.toLowerCase();
            if (!accountMatch) {
              store.dispatch('logout').then(() => {
                vue.$notify('Account does not match the email address initially provided.');
                const failLog = new EventLog({
                  event: 'account.fail_login',
                  email: signinEmail,
                  error: 'account_mismatch',
                });
                Services.users.postTrackingLog(failLog);
              });
            }
          }
          if (!signinEmail || accountMatch) {
            store.dispatch('login').catch((error) => {
              store.dispatch('logout').then(() => {
                vue.$notify('Login failed.');
                const failLog = new EventLog({
                  event: 'account.fail_login',
                  email: userInfo.email,
                  error: error.message,
                });
                Services.users.postTrackingLog(failLog);
              });
            });
          }
        } else {
          store.dispatch('setLoginComplete', true);
        }
      } else if (!store.getters.user) {
        store.dispatch('setLoginComplete', true);
      }
    } else {
      store.dispatch('setLoginComplete', true);
    }
  });

  store.dispatch('setTenantConfig', config.tenant);

  if (SENTRY_ENABLED) {
    Sentry.init({
      dsn: config.dsn,
      environment: config.environment,
      integrations: [new VueIntegration({ Vue, logErrors: true })],
    });
    Sentry.setTag('track_id', sessionStorage.getItem('track_id'));
  }
});
