<template>
  <div id="enter-password-form">
    <p class="text-left">
      Please enter your password.
    </p>
    <div class="mt-5">
      <v-text-field
        v-model="authentication"
        name="password"
        label="Password"
        type="password"
        autofocus
        @keyup.native="(event) => {
          if (event.key === 'Enter') {
            submit();
          }
        }"/>
      <div id="forgot-password">
        <a
          class="float-left mb-2 pointer"
          @click="() => { switchPage('forgot') }">
          Forgot Password?
        </a>
      </div>
    </div>
    <div class="clearfix">
      <v-btn
        v-if="securityScan"
        :loading="loading"
        :disabled="!authentication || authentication.length === 0 || !!loading"
        color="secondary"
        class="float-right next white--text"
        depressed
        @click="submit">
        Submit
      </v-btn>
      <v-btn
        v-else
        :loading="loading"
        :disabled="!authentication || authentication.length === 0 || !!loading"
        color="secondary"
        class="float-right next white--text"
        depressed
        @click="submit">
        Next
      </v-btn>
      <div
        v-if="authentication && authentication.length > 0 && !loading"
        id="next-active"
        style="display: none">
        <!-- this exists for pingdom purposes -->
      </div>
      <v-btn
        v-if="!securityScan"
        class="float-left back black--text"
        light
        default
        depressed
        @click="() => { switchPage('email') }">
        Back
      </v-btn>
    </div>
  </div>
</template>

<script>
import { EventLog } from '@/lib/event-log';

export default {
  name: 'EnterPassword',
  props: {
    email: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    securityScan: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      authentication: null,
    };
  },
  methods: {
    submit() {
      const attemptLog = new EventLog({
        event: 'sign_in.enter_password',
        email: this.email,
      });
      this.$services.users.postTrackingLog(attemptLog);
      this.$emit('login', this.authentication);
    },
    switchPage(page) {
      this.$emit('switchPage', page);
    },
  },
};
</script>
