<template>
  <div :ref="`page-${pageNum}`">
    <canvas
      :ref="`canvas-${pageNum}`"
      class="pdf-canvas"/>
  </div>
</template>
<script>

export default {
  name: 'PdfPage',
  props: {
    page: {
      type: Object,
      required: true,
    },
    pageNum: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      listenerContext: null,
    };
  },
  mounted() {
    this.handlePage();
    this.listenerContext = this.resizePage.bind(this);
    window.addEventListener('resize', this.listenerContext);
  },
  destroyed() {
    window.removeEventListener('resize', this.listenerContext);
  },
  methods: {
    resizePage() {
      clearTimeout(this.timeId);
      this.timeId = setTimeout(() => this.handlePage(), 200);
    },
    handlePage() {
      const viewer = this.$refs[`page-${this.pageNum}`];
      const canvas = this.$refs[`canvas-${this.pageNum}`];
      const context = canvas.getContext('2d');

      let viewport = this.page.getViewport({ scale: 1, rotation: 0 });
      const calculatedScale = (viewer.offsetWidth - (viewer.offsetWidth * 0.2)) / viewport.width;

      viewport = this.page.getViewport({ scale: calculatedScale, rotation: 0 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      this.page.render({ canvasContext: context, viewport });
    },
  },
};
</script>

<style>
.pdf-canvas {
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 2px;
  margin-top: 15px;
  margin-bottom: 10px;
}
</style>
