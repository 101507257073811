import { render, staticRenderFns } from "./UserPortal.vue?vue&type=template&id=9da727f0&scoped=true&"
import script from "./UserPortal.vue?vue&type=script&lang=js&"
export * from "./UserPortal.vue?vue&type=script&lang=js&"
import style0 from "./UserPortal.vue?vue&type=style&index=0&id=9da727f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9da727f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VFlex,VIcon,VLayout})
