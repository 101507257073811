<template>
  <ContentModal
    :is-visible="isVisible"
    :allow-click-away="false"
    size="large"
    @onModalClose="() => $emit('onModalClose')">
    <template
      #header-content>
      <div>
        {{ selectedFile.name }}
      </div>
    </template>
    <template
      #content>
      <div class="upload-content">
        <span v-if="csvLength <= 0">
          Loading File...
        </span>
        <v-simple-table
          v-else
          dense>
          <thead>
            <tr>
              <th
                v-for="(col, i) in headerRows"
                :key="i"
                class="text-left">
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, i) in viewCSV"
              :key="i">
              <th
                v-for="(col, j) in row"
                :key="`${i}${j}`">
                {{ col }}
              </th>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </template>
    <template
      #footer-content>
      <div class="pagination-footer">
        <div class="row-count-block">
          <span>view</span>
          <select
            v-model="rowCount"
            class="row-count-select">
            <option
              v-for="count in rowCountOptions"
              :key="count"
              :value="count">
              {{ count }}
            </option>
          </select>
          <span>per page</span>
        </div>
        <div class="pagination-navigation">
          <span>showing {{ viewOffset + 1 }} to {{ lastEntry }} of {{ csvLength }} entries</span>
          <div
            v-if="viewPagination - 2 > 0"
            class="navigation-arrows"
            @click="changePage(-10)">
            <v-icon style="margin-right: -22px;">
              keyboard_arrow_left
            </v-icon>
            <v-icon style="margin-right: -5px;">
              keyboard_arrow_left
            </v-icon>
          </div>
          <div
            v-if="viewPagination - 1 > 0"
            class="navigation-arrows"
            @click="changePage(-1)">
            <v-icon>keyboard_arrow_left</v-icon>
          </div>
          <div
            v-for="i in 5"
            :key="i">
            <div
              v-if="totalPages > 1 && verifyPagination(i)"
              class="pagination-numbers"
              :class="i - 3 === 0 ? 'active' : ''"
              @click="() => viewPagination = viewPagination + (i - 3)">
              {{ viewPagination + (i - 3) }}
            </div>
          </div>
          <div
            v-if="viewPagination < totalPages"
            class="navigation-arrows"
            @click="changePage(1)">
            <v-icon style="margin-right: -5px;">
              keyboard_arrow_right
            </v-icon>
          </div>
          <div
            v-if="viewPagination + 1 < totalPages"
            class="navigation-arrows"
            @click="changePage(10)">
            <v-icon style="margin-right: -22px;">
              keyboard_arrow_right
            </v-icon>
            <v-icon>
              keyboard_arrow_right
            </v-icon>
          </div>
        </div>
      </div>
    </template>
  </ContentModal>
</template>

<script>
import ContentModal from '@/components/utils/modal/ContentModal.vue';

export default {
  name: 'CsvViewer',
  components: {
    ContentModal,
  },
  props: {
    selectedFile: {
      type: File,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      csvRows: [],
      headerRows: [],
      csvLength: 0,
      viewCSV: [],
      viewPagination: 1,
      rowCountOptions: [5, 10, 25, 50, 100],
      rowCount: 25,
      loading: true,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.csvLength / this.rowCount);
    },
    lastEntry() {
      const max = this.viewOffset + this.rowCount;
      return max < this.csvLength ? max : this.csvLength;
    },
    viewOffset() {
      return (this.viewPagination - 1) * this.rowCount;
    },
  },
  watch: {
    rowCount() {
      this.structureView();
    },
    viewPagination() {
      this.structureView();
    },
  },
  mounted() {
    this.loadFile();
  },
  beforeDestroy() {
    this.csvRows = []
    this.viewCSV = []
    this.headerRows = []
  },
  methods: {
    verifyPagination(num) {
      const offset = this.viewPagination + (num - 3);
      return offset > 0 && offset <= this.totalPages;
    },
    changePage(num) {
      if (this.viewPagination + num < 1) {
        this.viewPagination = 1;
        return;
      }
      if (this.viewPagination + num > this.totalPages) {
        this.viewPagination = this.totalPages;
        return;
      }
      this.viewPagination += num;
    },
    async loadFile() {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
          this.splitFile(e.target.result)
          resolve('done');
        };

        fileReader.onloadend = () => {
          this.structureView();
          this.loading = false;
        };

        fileReader.onerror = () => {
          reject();
        };

        /*
          This line of code is meant to prevent larger csv files from
          locking up the browser.

          I did look into using Web Workers as a way to asyncronously
          load the file while not locking up the browser. This would add
          more size to the overall package size which I determined may 
          not be worth it.

          Web workers could still be an option if we decide a user being
          able to view the whole file in the browser as something that
          adds value. Otherwise this will allow them to get quick
          confirmation that it is the data they are intending to upload.
        */
        const previewFile = this.selectedFile.slice(0, 20480000);
        fileReader.readAsText(previewFile);
      });
    },

    splitFile(data) {
      this.csvRows = data.split('\r\n');
      this.headerRows = this.csvRows.shift().split(',');
      this.csvLength = this.csvRows.length;
    },
    structureView() {
      const parsedCSV = [];
      if (this.viewPagination > this.totalPages) {
         this.viewPagination = this.totalPages;
      }
      for (let i = this.viewOffset; i < this.lastEntry; i++) {
        parsedCSV.push(this.csvRows[i].split(','));
      }
      this.viewCSV = parsedCSV;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/colors.scss";
.pagination-numbers {
  font-size: 18px;
  color: $bainbridge-gray;
  margin-right: .2rem;
}
.pagination-numbers {
  font-size: 18px;
  color: $bainbridge-gray;
  margin-right: .2rem;
  cursor: pointer;

  &.active {
    color: black;
    cursor: default;
  }
}
.pagination-navigation {
  display: flex;
  flex-flow: row;
  margin-right: 2rem;
  align-items: center;
}

.pagination-navigation div{
  cursor: pointer;
  &:active i {    
    color: black !important;
  }
}

.pagination-footer {
  display: flex;
  justify-content: space-between;
  height: 50px;
  border-top: 1px solid $bainbridge-gray-light;

  span {
    color: $bainbridge-gray;
    font-size: 14px;
    margin: 0 .4rem 0 .4rem;
  }
}
.row-count-block {
  display: flex;
  margin-left: 2rem;
  align-items: center;
  span {
    line-height:50px;
  }
}
.row-count-select {
  appearance: listbox;
  height: 30px;
  border-right: 6px solid transparent;
  border-radius: 4px;
  text-align: center;
  background-color: $bainbridge-gray-light;
  vertical-align: middle;
}
</style>
