var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-12"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"lg8":"","md10":"","xs12":""}},[_c('v-card',{staticClass:"text-left"},[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"white--text"},[_vm._v("\n            User Troubleshooting\n          ")])]),_vm._v(" "),(!_vm.usersLoaded)?_c('v-card-text',{staticClass:"mt-2 text-center"},[_c('v-progress-circular',{staticClass:"primary--text",attrs:{"indeterminate":""}})],1):_c('v-card-text',[_c('div',{staticClass:"mt-4"},[(!_vm.usersExist)?_c('span',[_vm._v("\n              There are no active users to utilize this feature for.\n            ")]):_c('span',[_vm._v("\n              Select a user and generate a unique tracking ID to troubleshoot his/her workflow through the portal.\n            ")]),_vm._v(" "),(_vm.usersExist)?_c('div',{staticClass:"w-100 pt-4 d-flex"},[_c('v-form',{ref:"userForm",staticClass:"flex-1",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-combobox',{staticClass:"mt-2",attrs:{"disabled":_vm.actionStatus.generatingID,"items":_vm.sortedUsers,"search-input":_vm.userQuery,"validate-on-blur":true,"rules":[
                    function (v) { return !!v && v.length > 0 || 'Required'; },
                    function (v) { return !!v && _vm.sortedUsers.includes(v.toLowerCase()) || 'Invalid selection'; } ],"label":"User"},on:{"update:searchInput":function($event){_vm.userQuery=$event},"update:search-input":function($event){_vm.userQuery=$event},"input":function($event){_vm.userQuery = ''}},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_vm._v(" "),_c('v-btn',{staticClass:"secondary ml-4",attrs:{"disabled":_vm.actionStatus.generatingID,"loading":_vm.actionStatus.generatingID,"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.generateId()}}},[_vm._v("\n                Generate ID\n              ")])],1):_vm._e()])]),_vm._v(" "),(_vm.trackToken && !_vm.actionStatus.generatingID)?_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"pb-2"},[_vm._v("\n            Send the track URL below to the user to begin logging his/her workflow with the generated track ID.\n            Instruct the user to log out, then log back in using this URL.\n            Click the link next to the track ID to view the tracking logs for the user once they begin their workflow.\n            Note that the URL and ID expire in 24 hours.\n          ")]),_vm._v(" "),_c('v-list',{attrs:{"text":""}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',{staticClass:"pt-0"},[_c('v-list-item-title',[_vm._v("\n                  Track ID\n                  "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"href":_vm.dashboardLink,"target":"_blank","icon":"","text":""}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,false,653607994)},[_vm._v(" "),_c('span',[_vm._v("View user tracking logs")])])],1),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("\n                  "+_vm._s(_vm.trackId)+"\n                ")])],1)],1),_vm._v(" "),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                  Track URL\n                  "),(_vm.linkCopied)?_c('v-tooltip',{attrs:{"color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0",attrs:{"data-url":_vm.trackLink,"icon":"","text":""},on:{"click":_vm.copyLink}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,2073520288),model:{value:(_vm.linkCopied),callback:function ($$v) {_vm.linkCopied=$$v},expression:"linkCopied"}},[_vm._v(" "),_c('span',[_vm._v("Link Copied!")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0",attrs:{"data-url":_vm.trackLink,"icon":"","text":""},on:{"click":_vm.copyLink}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,2073520288)},[_vm._v(" "),_c('span',[_vm._v("Copy link")])])],1),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("\n                  "+_vm._s(_vm.trackLink)+"\n                ")])],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }