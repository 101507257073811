<template>
  <div>
    <v-btn
      small
      absolute
      fab
      color="primary"
      class="ma-auto expand-button"
      :class="{'expanded': !collapsed}"
      @click.stop="collapsed = !collapsed">
      <v-icon>
        {{ collapsed ? 'chevron_right' : 'chevron_left' }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      :mini-variant="collapsed"
      :mini-variant-width="80"
      :width="255"
      height="100%"
      class="elevation-5 side-menu bainbridge-gray-light pt-4 px-0 pb-0 align-center justify-center"
      absolute
      permanent
      floating
      app>
      <img
        class="bainbridge-name-horiz"
        src="~@/assets/images/BH-Alt.svg"
        @click="redirectHome()">
      <v-list
        :class="{'align-center': collapsed}"
        class="menu-list pt-2 flex-1"
        dense>
        <NavMenu
          v-for="item in menus"
          :key="`${item.title}-${item.subs.length}`"
          :expanded="!collapsed"
          :icon="item.icon"
          :icon-style="item.icon_style"
          :sub-menus="item.subs"
          :title="item.title"
          :url="item.url"/>
        <v-spacer class="flex-1"/>
        <v-menu
          v-if="showTenantSelect"
          key="tenant"
          v-model="tenantMenu"
          :close-on-content-click="false"
          content-class="tenant-menu pa-4"
          transition="slide-x-transition"
          offset-x>
          <template #activator="{ on }">
            <v-btn
              :class="{
                'w-100': !collapsed,
                'mx-0': !collapsed,
              }"
              :icon="collapsed"
              text
              depressed
              v-on="on">
              <v-tooltip
                :disabled="tenantMenu || !collapsed"
                right>
                <template #activator="{ tenantMenuOn }">
                  <v-icon
                    :class="{
                      'menu-icon': true,
                      'bainbridge-green--text': tenantMenu,
                      'bainbridge-blue--text': !tenantMenu,
                      'pr-4': !collapsed,
                    }"
                    v-on="tenantMenuOn">
                    people
                  </v-icon>
                </template>
                <span>Select Tenant</span>
              </v-tooltip>
              <v-subheader
                v-if="!collapsed"
                :class="{
                  'bainbridge-green--text': tenantMenu,
                  'bainbridge-blue--text': !tenantMenu,
                }"
                class="main-title px-0">
                Tenant
              </v-subheader>
            </v-btn>
          </template>
          <v-card class="d-flex elevation-0">
            <v-select
              v-model="selectedTenant"
              :items="tenants"
              item-text="display_name"
              item-value="id"
              class="bainbridge-blue--text ma-0 pl-4 py-1 pr-1"
              hide-details
              text
              single-line
              flat
              @change="changeTenant"/>
            <v-tooltip
              top>
              <template
                #activator="{ on }">
                <v-btn
                  :loading="tenantUpdateRequested"
                  class="ma-0"
                  text
                  icon
                  v-on="on"
                  @click="updateDefaultTenant">
                  <v-icon>{{ isTenantDefault ? 'star' : 'star_outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ isTenantDefault ? 'Unset tenant as default' : 'Set tenant as default' }}</span>
            </v-tooltip>
          </v-card>
        </v-menu>
        <NavMenu
          v-for="item in navbarItems"
          :key="item.title"
          :expanded="!collapsed"
          :icon="item.icon"
          :name="item.name"
          :title="item.title"
          :error-state="item.name === 'AdminPortal' && sourceFailed"
          :url="item.url"/>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import moment from 'moment';

import { objectsEqual } from '@/lib/compare';
import { EventLog } from '@/lib/event-log';
import { mapActions, mapGetters } from 'vuex';
import NavMenu from './utils/NavMenu.vue';

export default {
  name: 'SideMenu',
  components: {
    NavMenu,
  },
  data() {
    return {
      collapsed: true,
      selectedTenant: null,
      tenantMenu: false,
      tenantUpdateRequested: false,
    };
  },
  computed: {
    isTenantDefault() {
      if (this.defaultTenant) {
        return this.tenant.id === this.defaultTenant.id;
      } else {
        return false;
      }
    },
    showTenantSelect() {
      return this.tenants.length > 1;
    },
    ...mapGetters([
      'defaultTenant',
      'menus',
      'navbarItems',
      'sourceFailed',
      'tenant',
      'tenants',
      'user',
      'userHasCapability',
    ]),
  },
  watch: {
    '$route.query': {
      handler() {
        const query = { ...this.$route.query };
        if ('tenant' in query) {
          const tenantName = query.tenant;
          const validTenant = this.tenants.find((tenant) => tenant.name === tenantName);
          if (validTenant) {
            this.selectedTenant = validTenant.id;
            this.changeTenant();
          }
          delete query.tenant;
          if (!objectsEqual(this.$route.query, query)) {
            this.$router.push({ query });
          }
        }
      },
    },
    'tenant': {
      handler() {
        if (this.tenant) {
          if (this.selectedTenant !== this.tenant.id) {
            this.selectedTenant = this.tenant.id;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.tenant) {
      this.selectedTenant = this.tenant.id;
    }
  },
  methods: {
    changeTenant() {
      if (this.selectedTenant !== this.tenant.id) {
        const tenant = this.tenants.find((t) => t.id === this.selectedTenant);
        const options = {
          tenant,
          resetRequests: true,
          withClaims: true,
        };
        this.updateTenant(options).then(() => {
          const loggingData = new EventLog({
            event: 'account.change_tenant',
            oldTenant: this.tenant.name,
            newTenant: tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
          const now = moment();
          const { params } = this.$route;
          const menuTitle = this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_');
          const menuName = (params && params.id) ? `${menuTitle}.${params.id.replace(/ |-/g, '_')}` : menuTitle;
          const menuData = {
            timestamp: now,
            menu: menuName,
          };
          this.setMenuNavigationStart(menuData);
        }).catch((error) => {
          this.$notify('Failed to set tenant.');
          const loggingData = new EventLog({
            event: 'account.fail_change_tenant',
            oldTenant: this.tenant.name,
            newTenant: tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      }
    },
    redirectHome() {
      this.$router.push({ name: 'LandingPage' });
    },
    updateDefaultTenant() {
      this.tenantUpdateRequested = true;
      if (!this.defaultTenant) {
        this.$services.users.postDefaultUserTenant(this.user.id, this.tenant.id).then(() => {
          this.setDefaultTenant(this.tenant);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.set_default_tenant',
            default_tenant: this.tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
        }).catch((error) => {
          this.$notify(error);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.fail_set_default_tenant',
            default_tenant: this.tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      } else if (this.isTenantDefault) {
        this.$services.users.deleteDefaultUserTenant(this.user.id, this.tenant.id).then(() => {
          this.setDefaultTenant(null);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.unset_default_tenant',
            default_tenant: this.tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
        }).catch((error) => {
          this.$notify(error);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.fail_unset_default_tenant',
            default_tenant: this.tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      } else {
        this.$services.users.putDefaultUserTenant(this.user.id, this.tenant.id).then(() => {
          this.setDefaultTenant(this.tenant);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.set_default_tenant',
            default_tenant: this.tenant.name,
          });
          this.$services.users.postTrackingLog(loggingData);
        }).catch((error) => {
          this.$notify(error);
          this.tenantUpdateRequested = false;
          const loggingData = new EventLog({
            event: 'account.fail_set_default_tenant',
            default_tenant: this.tenant.name,
            error: error.message,
          });
          this.$services.users.postTrackingLog(loggingData);
        });
      }
    },
    ...mapActions([
      'setDefaultTenant',
      'setMenuNavigationStart',
      'updateTenant',
    ]),
  },
};
</script>

<style lang="scss">
@import '../sass/colors.scss';
.v-navigation-drawer.side-menu {
  box-shadow: none;
  z-index: 3;
  .v-navigation-drawer__content {
    display: flex;
    flex-flow: column;
  }
  img.bainbridge-name-horiz {
    height: 35px;
    width: auto;
    &:hover {
      cursor: pointer;
    }
  }
  .menu-list {
    display: flex;
    flex-flow: column;
    .v-btn {
      height: 45px;
      width: 45px;
    }
    .menu-icon {
      font-size: 30px;
    }
    .mdi-alert {
      color: white !important;
      font-size: 12px !important;
      margin-top: -1px;
    }
  }
}
.expand-button {
  height: 30px !important;
  width: 30px !important;
  bottom: 45px;
  left: 65px;
  border-width: 2px !important;
  color: $bainbridge-gray-light !important;
  .v-btn__content {
    height: 100%;
    .v-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      line-height: -moz-block-height;
    }
  }
}
.expanded {
  left: 240px;
}
.tenant-menu {
  position: fixed;
  background-color: $bainbridge-gray-light !important;
  box-shadow: none;
  .v-card {
    background-color: $bainbridge-gray-light !important;
    box-shadow: none;
  }
}
</style>
