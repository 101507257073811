<template>
  <span
    v-if="!isActive"
    class="auto-vertical-margins">
    <v-tooltip
      v-if="!isActive"
      bottom>
      <template #activator="{ on }">
        <v-btn
          color="primary"
          small
          text
          icon
          v-on="on"
          @click="removeFilter">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
      <span>Click to remove.</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'FilterActions',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter');
    },
  },
};
</script>
