var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"enter-email-form"}},[_c('p',{staticClass:"text-left"},[_vm._v("\n    Please enter the e-mail address associated with your Bainbridge Health account.\n  ")]),_vm._v(" "),_c('div',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"name":"email","label":"Email","autofocus":""},on:{"blur":function (event) {
        if (_vm.securityScan) {
          _vm.submit();
        }
      }},nativeOn:{"keyup":function($event){return (function (event) {
        if (event.key === 'Enter' && !_vm.securityScan) {
          _vm.submit();
        }
      }).apply(null, arguments)}},model:{value:(_vm.identity),callback:function ($$v) {_vm.identity=$$v},expression:"identity"}})],1),_vm._v(" "),(!_vm.securityScan)?_c('div',[_c('v-btn',{staticClass:"float-right next white--text",attrs:{"disabled":!_vm.identity || _vm.identity.length === 0 || !!_vm.localLoading,"loading":_vm.localLoading,"color":"secondary","depressed":""},on:{"click":_vm.submit}},[_vm._v("\n      Next\n    ")]),_vm._v(" "),(_vm.identity && _vm.identity.length > 0 && !_vm.localLoading)?_c('div',{staticStyle:{"display":"none"},attrs:{"id":"next-active"}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }