<template>
  <v-container class="pt-12">
    <v-layout justify-center>
      <v-flex
        lg8
        md10
        xs12>
        <v-card class="text-left">
          <v-card-title class="primary">
            <span class="white--text">
              User Troubleshooting
            </span>
          </v-card-title>
          <v-card-text
            v-if="!usersLoaded"
            class="mt-2 text-center">
            <v-progress-circular
              indeterminate
              class="primary--text"/>
          </v-card-text>
          <v-card-text v-else>
            <div
              class="mt-4">
              <span v-if="!usersExist">
                There are no active users to utilize this feature for.
              </span>
              <span v-else>
                Select a user and generate a unique tracking ID to troubleshoot his/her workflow through the portal.
              </span>
              <div
                v-if="usersExist"
                class="w-100 pt-4 d-flex">
                <v-form
                  ref="userForm"
                  v-model="valid"
                  class="flex-1"
                  lazy-validation>
                  <v-combobox
                    v-model="userEmail"
                    :disabled="actionStatus.generatingID"
                    :items="sortedUsers"
                    :search-input.sync="userQuery"
                    :validate-on-blur="true"
                    :rules="[
                      v => !!v && v.length > 0 || 'Required',
                      v => !!v && sortedUsers.includes(v.toLowerCase()) || 'Invalid selection',
                    ]"
                    class="mt-2"
                    label="User"
                    @input="userQuery = ''"/>
                </v-form>
                <v-btn
                  :disabled="actionStatus.generatingID"
                  :loading="actionStatus.generatingID"
                  class="secondary ml-4"
                  text
                  @click.stop="generateId()">
                  Generate ID
                </v-btn>
              </div>
            </div>
          </v-card-text>
          <v-card-text
            v-if="trackToken && !actionStatus.generatingID"
            class="pt-0">
            <div class="pb-2">
              Send the track URL below to the user to begin logging his/her workflow with the generated track ID.
              Instruct the user to log out, then log back in using this URL.
              Click the link next to the track ID to view the tracking logs for the user once they begin their workflow.
              Note that the URL and ID expire in 24 hours.
            </div>
            <v-list text>
              <v-list-item two-line>
                <v-list-item-content class="pt-0">
                  <v-list-item-title>
                    Track ID
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          :href="dashboardLink"
                          target="_blank"
                          icon
                          text
                          v-on="on">
                          <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>View user tracking logs</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ trackId }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    Track URL
                    <v-tooltip
                      v-if="linkCopied"
                      v-model="linkCopied"
                      color="secondary"
                      top>
                      <template #activator="{ on }">
                        <v-btn
                          :data-url="trackLink"
                          icon
                          text
                          class="mr-0"
                          v-on="on"
                          @click="copyLink">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Link Copied!</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else
                      bottom>
                      <template #activator="{ on }">
                        <v-btn
                          :data-url="trackLink"
                          icon
                          text
                          class="mr-0"
                          v-on="on"
                          @click="copyLink">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>Copy link</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ trackLink }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { EventLog } from '@/lib/event-log';
import { IS_EQUAL_TO } from '@/lib/constants';
import { EMPLOYEE_TENANT_NAME } from '@/lib/identity-methods';

const BASE_URL = window.location.origin;

export default {
  name: 'UserTesting',
  data() {
    return {
      valid: false,
      userEmail: '',
      userQuery: '',
      actionStatus: {
        generatingID: false,
      },
      trackToken: '',
      trackId: '',
      filterSetId: '',
      linkCopied: false,
    };
  },
  computed: {
    loading() {
      return !this.usersLoaded || Object.keys(this.actionStatus).some((k) => this.actionStatus[k] === true);
    },
    usersExist() {
      return this.users.length > 0;
    },
    sortedUsers() {
      const users = cloneDeep(this.users);
      users.sort((x, y) => ((x.email.toLowerCase() > y.email.toLowerCase()) ? 1 : -1));
      const results = users.map((u) => u.email.toLowerCase());
      if (this.userQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.userQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.userQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    trackLink() {
      return `${BASE_URL}/?track=${this.trackToken}`;
    },
    dashboardLink() {
      return `${BASE_URL}/?filter_set=${this.filterSetId}&tenant=${EMPLOYEE_TENANT_NAME}#/analytics`;
    },
    ...mapGetters([
      'users',
      'usersLoaded',
    ]),
  },
  watch: {
    usersLoaded: {
      handler() {
        if (this.$route.name === 'UserTesting' && this.usersLoaded) {
          const now = moment();
          const menuData = {
            timestamp: now,
            menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
    },
  },
  mounted() {
    if (this.usersLoaded) {
      const now = moment();
      const menuData = {
        timestamp: now,
        menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
      };
      this.setMenuNavigationEnd(menuData);
    }
  },
  methods: {
    generateId() {
      this.$refs.userForm.validate(true);
      if (this.valid) {
        this.$set(this.actionStatus, 'generatingID', true);
        this.trackToken = '';
        this.trackId = '';
        this.filterSetId = '';
        const user = this.users.find((u) => u.email.toLowerCase() === this.userEmail.toLowerCase());
        this.$services.users.getTrackToken(user.id).then((result) => {
          const filterSet = {
            tracking_id: {
              value: result.track_id,
              option: IS_EQUAL_TO,
            },
            tracking_user_type: {
              value: null,
              option: IS_EQUAL_TO,
            },
          };
          this.$services.filters.postFilterSet(filterSet).then((response) => {
            this.trackToken = result.track_token;
            this.trackId = result.track_id;
            this.filterSetId = response.success;
            const loggingData = new EventLog({
              event: 'debug.generate_track_token',
              trackedUser: this.userEmail,
            });
            this.$services.users.postTrackingLog(loggingData);
          }).catch((error) => {
            const failLog = new EventLog({
              event: 'debug.fail_generate_track_token',
              trackedUser: this.userEmail,
              error: error.message,
            });
            this.$services.users.postTrackingLog(failLog);
            this.$notify(error);
          });
        }).catch((error) => {
          const failLog = new EventLog({
            event: 'debug.fail_generate_track_token',
            trackedUser: this.userEmail,
            error: error.message,
          });
          this.$services.users.postTrackingLog(failLog);
          this.$notify(error);
        }).finally(() => {
          this.$set(this.actionStatus, 'generatingID', false);
        });
      }
    },
    copyLink() {
      if (!this.linkCopied) {
        const link = document.createElement('textarea');
        link.style.position = 'absolute';
        link.style.top = `${window.pageYOffset || document.documentElement.scrollTop}px`;
        link.value = this.trackLink;
        document.body.appendChild(link);
        link.select();
        const copied = document.execCommand('copy');
        if (copied) {
          this.linkCopied = true;
        }
        document.body.removeChild(link);
      }
    },
    ...mapActions([
      'setMenuNavigationEnd',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.headline {
  word-break: break-word;
}
.expire-message {
  font-weight: bold;
}
.link-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>
