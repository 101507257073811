<template>
  <div class="h-100 w-100 d-flex">
    <v-progress-circular
      indeterminate
      class="primary--text loader"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  created() {
    this.$services.identity.postLogout().finally(() => {
      this.logout().then(() => window.location.replace('/'));
    });
  },
  methods: {
    ...mapActions([
      'logout',
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>
