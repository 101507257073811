<template>
  <div
    :class="`boolean-filter ${formattedLabel}-row`">
    <v-layout
      class="align-center"
      wrap>
      <v-flex
        xs2
        class="mr-2">
        <FilterLabel
          :label="label"
          :is-active="isActive"/>
      </v-flex>
      <v-flex xs3>
        <v-select
          v-model="value"
          :items="possibleStates"
          :menu-props="{ 'contentClass': `${formattedLabel}-select` }"
          :disabled="!isActive"/>
      </v-flex>
      <FilterActions
        :is-active="isActive"
        @removeFilter="removeFilter"/>
    </v-layout>
  </div>
</template>

<script>

import FilterLabel from './FilterLabel.vue';
import FilterActions from './FilterActions.vue';

const possibleStates = [
  { text: 'Any', value: 'Any' },
  { text: 'Yes', value: 'Yes' },
  { text: 'No', value: 'No' },
];

const DEFAULT_VALUE = { value: possibleStates[0].value };

export default {
  name: 'BooleanFilter',
  components: {
    FilterLabel,
    FilterActions,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    passedValue: {
      type: Object,
      default: () => DEFAULT_VALUE,
    },
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      value: DEFAULT_VALUE.value,
      possibleStates,
    };
  },
  computed: {
    formattedLabel() {
      return this.label.toLowerCase().replace(/ /g, '-');
    },
  },
  watch: {
    passedValue: {
      handler() {
        this.value = this.passedValue.value ? this.passedValue.value : DEFAULT_VALUE.value;
      },
      deep: true,
      immediate: true,
    },
    value() {
      const value = this.value === DEFAULT_VALUE.value ? null : this.value;
      if ((this.passedValue.value && this.value !== this.passedValue.value) || this.value !== DEFAULT_VALUE.value) {
        this.$emit('valueChanged', {
          key: this.filterId,
          value: { value },
        });
      }
    },
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter', this.filterId);
    },
  },
};
</script>
