import { render, staticRenderFns } from "./DataAdmin.vue?vue&type=template&id=3779f3b6&"
import script from "./DataAdmin.vue?vue&type=script&lang=js&"
export * from "./DataAdmin.vue?vue&type=script&lang=js&"
import style0 from "./DataAdmin.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VContainer,VFlex,VIcon,VLayout})
