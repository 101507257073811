<template>
  <v-container class="data-admin h-100 w-100">
    <v-layout
      align-center
      justify-center>
      <v-flex xs12>
        <v-breadcrumbs
          v-if="!mainPage"
          :items="navItems"
          class="data-admin-breadcrumbs w-100 mt-2">
          <template #divider>
            <v-icon>
              chevron_right
            </v-icon>
          </template>
          <template #item="props">
            <v-breadcrumbs-item
              :key="props.item.name"
              :href="props.item.link"
              :target="'_self'"
              :disabled="props.item.isCurrent">
              {{ props.item.name }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <div class="clearfix"/>
    <router-view/>
  </v-container>
</template>

<script>
export default {
  name: 'DataAdmin',
  computed: {
    mainPage() {
      return this.$route.name === 'AdminPortal';
    },
    navItems() {
      const navItems = [
        {
          name: 'Data Administration Portal',
          link: '#/data-admin',
        },
      ];
      switch (this.$route.name) {
        case 'CfkpDataSource':
          navItems.push({
            name: 'CFKP Data Source',
            link: '#/data-admin/cfkp-data-source',
            isCurrent: true,
          });
          break;
        default:
          navItems[0].isCurrent = true;
      }
      return navItems;
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/colors.scss';
.data-admin {
  .breadcrumbs, .data-admin-breadcrumbs {
    display: block;
    float: left;
    text-align: left;
    li {
      display: inline-block;
      &:last-child {
        a {
          color: $bainbridge-green-dark;
        }
      }
    }
  }
}
.portal-badge {
  .v-badge__wrapper {
    .v-badge__badge {
      height: 45px;
      width: 45px;
      border-radius: 30px;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .mdi-alert {
        font-size: 30px;
        margin-top: -4px;
        color: white !important;
      }
    }
  }
}
</style>
