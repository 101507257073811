<template>
  <div class="py-5 source-row">
    <v-list-item class="pl-4 pr-2">
      <v-list-item-avatar class="source-avatar">
        <v-tooltip
          bottom>
          <template #activator="{ on }">
            <v-icon
              :color="(runRequested || notRun || started || created) ? 'primary' : (
                succeeded ? 'secondary' : 'warning')"
              :class="{
                'running': runRequested || started || created,
              }"
              class="source-icon"
              v-on="on">
              {{ (runRequested || started || created) ? 'mdi-sync' : (succeeded ? 'mdi-check-circle' : (
                notRun ? 'mdi-calendar-range' : 'mdi-alert')) }}
            </v-icon>
          </template>
          <span>{{ statusText }}</span>
        </v-tooltip>
      </v-list-item-avatar>
      <v-list-item-content
        class="w-100 mx-3 py-0">
        <div>
          <div class="pb-2 primary--text">
            {{ source.id }}:
            <span
              :class="{
                'status-text': true,
                'primary--text': runRequested || started || created || notRun,
                'secondary--text': succeeded && !started && !created && !runRequested,
                'warning--text': !succeeded && !started && !created && !notRun && !runRequested,
              }">
              {{ statusText }}
            </span>
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Stream:
            </span>
            {{ source.stream }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Type:
            </span>
            {{ capitalize(source.refresh.name) }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Start Date:
            </span>
            {{ source.refresh.start_date }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              End Date:
            </span>
            {{ source.refresh.end_date }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Min Date:
            </span>
            {{ source.refresh.min_date }}
          </div>
          <div class="meta-text">
            <span class="primary--text">
              Runs:
            </span>
            {{ refreshSchedule }}
          </div>
          <div
            v-if="fetchStatus && fetchStatus.last_ran"
            class="meta-text">
            <span class="primary--text">
              Last Ran:
            </span>
            {{ formatDate(fetchStatus.last_ran) }}
          </div>
          <div
            v-if="errored && error"
            class="meta-text">
            <span class="primary--text">
              Error:
            </span>
            {{ errorMessage }}
          </div>
        </div>
      </v-list-item-content>
      <v-list-item-action
        v-for="action in actions"
        :key="action.icon"
        class="mx-1 float-right source-action">
        <v-tooltip
          bottom>
          <template #activator="{ on }">
            <v-btn
              :loading="action.loading"
              :disabled="action.loading || action.disabled"
              :class="action.class"
              class="ma-0"
              text
              icon
              v-on="on"
              @click="action.click">
              <v-icon>{{ action.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ action.tooltip }}</span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import cronstrue from 'cronstrue';
import moment from 'moment';

const CREATED_STATE = 'created';
const STARTED_STATE = 'started';
const SUCCESS_STATE = 'succeeded';
const FAILED_STATE = 'failed';

export default {
  name: 'SourceRow',
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    jobStarting: {
      type: Boolean,
      default: () => false,
    },
    source: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      refreshRequested: false,
      runRequested: false,
    };
  },
  computed: {
    statusText() {
      if (this.runRequested) {
        return 'Creating';
      } else if (this.created) {
        return 'Created';
      } else if (this.started) {
        return 'Running';
      } else if (this.notRun) {
        return 'Not Started';
      } else if (this.succeeded) {
        return 'Succeeded';
      }
      return 'Failed';
    },
    notRun() {
      return !this.source.status;
    },
    succeeded() {
      if (this.notRun || !this.loadStatus || !this.fetchLoadDatesMatch) {
        return false;
      }
      return this.fetchStatus.status === SUCCESS_STATE && this.loadStatus.status === SUCCESS_STATE;
    },
    created() {
      if (this.fetchStatus && this.fetchStatus.status === CREATED_STATE) {
        return true;
      }
      return false;
    },
    started() {
      if (this.runRequested) {
        return true;
      } else if (this.notRun) {
        return false;
      }
      const fetchStarted = this.fetchStatus.status === STARTED_STATE;
      return fetchStarted || (this.fetchStatus.status === SUCCESS_STATE && (!this.loadStatus ||
        !this.fetchLoadDatesMatch || [CREATED_STATE, STARTED_STATE].includes(this.loadStatus.status)));
    },
    errored() {
      if (this.notRun) {
        return false;
      }
      return this.fetchStatus.status === FAILED_STATE || (this.fetchLoadDatesMatch &&
        this.fetchStatus.status === SUCCESS_STATE && this.loadStatus && this.loadStatus.status === FAILED_STATE);
    },
    fetchLoadDatesMatch() {
      if (!this.fetchStatus || !this.loadStatus) {
        return false;
      }
      return this.formatDate(this.fetchStatus.last_ran) === this.formatDate(this.loadStatus.last_ran);
    },
    fetchStatus() {
      if (this.notRun) {
        return null;
      }
      return this.source.status.find((status) => status.type === 'fetch');
    },
    loadStatus() {
      if (this.notRun) {
        return null;
      }
      return this.source.status.find((status) => status.type === 'load');
    },
    refreshSchedule() {
      let readableSchedule = cronstrue.toString(this.source.refresh.schedule);
      readableSchedule = readableSchedule.replace('only on', 'every').replace('AM', 'AM UTC').replace('PM', 'PM UTC');
      readableSchedule = readableSchedule.slice(3);
      if (readableSchedule[0] === '0') {
        readableSchedule = readableSchedule.slice(1);
      }
      if (readableSchedule.substr(-3) === 'UTC') {
        readableSchedule = `${readableSchedule}, every day`;
      }
      return readableSchedule;
    },
    error() {
      const fetchSucceeded = this.fetchStatus.status === SUCCESS_STATE;
      if (this.notRun) {
        return null;
      } else if (this.fetchStatus.status === FAILED_STATE) {
        return this.fetchStatus.status.error;
      } else if (fetchSucceeded && this.loadStatus && this.loadStatus.status === FAILED_STATE) {
        return this.loadStatus.status.error;
      }
      return null;
    },
    actions() {
      const actions = [
        {
          active: !this.runRequested && !this.created && !this.started,
          loading: this.runRequested,
          disabled: this.loading || this.runRequested,
          click: this.runSourceJob,
          class: 'run-button',
          icon: 'mdi-play',
          tooltip: 'Run manual job',
        },
        {
          active: true,
          loading: this.loading || this.refreshRequested,
          disabled: this.loading || this.refreshRequested,
          click: this.refreshStatus,
          class: 'refresh-button',
          icon: 'mdi-refresh',
          tooltip: 'Refresh status',
        },
      ];
      return actions.filter((action) => action.active);
    },
  },
  watch: {
    loading: {
      handler() {
        if (!this.loading) {
          this.refreshRequested = false;
        }
      },
      deep: true,
    },
    jobStarting: {
      handler() {
        if (!this.jobStarting) {
          this.runRequested = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    capitalize(string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    },
    formatDate(date) {
      if (date) {
        return moment(date).utc().format('MMMM Do, YYYY');
      }
      return date;
    },
    refreshStatus() {
      this.refreshRequested = true;
      this.$emit('refreshStatus', this.source.id);
    },
    runSourceJob() {
      this.runRequested = true;
      this.$emit('runSourceJob', this.source.id);
    },
  },
};
</script>

<style lang="scss">
.source-row {
  .source-avatar {
    height: unset !important;
    width: unset !important;
    border-radius: 0px;
  }
  .running {
    animation: rotation 8s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .status-text {
    font-style: italic;
  }
  .source-icon {
    font-size: 50px;
  }
  .meta-text {
    font-size: 12px;
  }
  .source-action {
    min-width: 30px;
  }
  .source-action button {
    height: 30px;
    width: 30px;
    margin: 1px;
  }
  .dialog-title {
    line-height: unset !important;
  }
}
.title {
  word-break: break-word;
}
</style>
