<template>
  <div>
    <div class="details-card">
      <div class="card-header details-header mb-5">
        {{ file.spec.name }}
        <div class="file-view-actions">
          <div>
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-icon
                  size="30"
                  v-bind="attrs"
                  v-on="on">
                  more_vert
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  @click="actionClicked('base')">
                  <v-list-item-title>{{ isArchived ? 'Restore' : 'Archive' }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="(item) in possibleValues"
                  :key="item.value"
                  @click="actionClicked(item.value)">
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="details-block">
        Status:
        <span>
          {{ file.status.state }}
        </span>
      </div>
      <div class="details-block">
        Type:
        <span>
          {{ file.spec.contentType }}
        </span>
      </div>
      <div class="details-block">
        Size:
        <span>
          {{ file.spec.expectedTotalBytes }}
        </span>
      </div>
      <div class="details-block">
        Date Created:
        <span>
          {{ createdAt }}
        </span>
      </div>
      
    </div>
    <EditResource
        v-if="showEditModal"
        :showModal="showEditModal"
        :selectedFile="file"
        @onModalClose="() => showEditModal = false"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import EditResource from './detail-actions/EditResource.vue'

export default {
  name: 'FileDetails',
  components: {
    EditResource,
  },
  props: {
    isArchived: {
      type: Boolean,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditModal: false,
      possibleValues: [
        { text: 'Edit', value: 'edit' },
      ],
      fileFilter: 'ls=inbox',
    };
  },
  computed: {
    createdAt() {
      const date = new Date(this.file.object.createdAt);
      const mDate = moment(date).format('MMM DD, YYYY [at] h:mm a');
      return mDate;
    },
  },
  methods: {
    ...mapActions([
      'putUploadFile',
      'fetchFiles',
    ]),
    async actionClicked(action) {
      switch(action) {
        case 'base':
          this.defaultAction()
          break;
        case 'edit':
          this.showEditModal = true;
          break;
      }
    },
    async defaultAction() {
      if (this.isArchived) {
          const body = {
            ...this.file,
            meta: {
              name: this.file.meta.name,
              labels: {
                "inbox": ""
              }
            },
          };
          await this.putUploadFile(body);
          this.fetchFiles(this.fileFilter);
        } else {
          const body = {
            ...this.file,
            meta: {
              name: this.file.meta.name,
              labels: {
                "inbox": "archive"
              }
            },
          };
          await this.putUploadFile(body);
          this.fetchFiles(this.fileFilter);
        }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.details-header {
  display: flex;
  align-items: center;
  font-size: 16px !important;
}

.file-view-actions {
  display: flex;
  gap: 10px
}

.details-card {
  display: flex;
  flex-direction: column;
  height: auto;
  font-size: 14px;
}

.details-block {
  color: $bainbridge-gray;
  margin-left: 1rem;
  height: 35px;

  span {
    color: black;
    margin-left: 5px;
  }
  p {
    color: black;
    margin-left: 20px;
  }
}
</style>
