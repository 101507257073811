<template>
  <v-text-field
    v-model="query"

    label="Search by medication name"
    append-icon="search"
    single-line
    hide-details
    @input="filterLibrary"/>
</template>

<script>
export default {
  name: 'LibrarySearchBar',
  props: {
    search: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      query: null,
    };
  },
  methods: {
    filterLibrary() {
      this.search(this.query);
    },
  },
};
</script>
