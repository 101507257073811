const Notifications = {};

Notifications.install = (rootVue, options) => {
  if (!options.bus) {
    throw Error('Notifications requires a bus passed in');
  }
  Object.defineProperty(rootVue.prototype, '$notify', {
    get() {
      return (message) => {
        options.bus.$emit('showSnackbar', message);
      };
    },
  });
};

export default Notifications;
