<template>
  <div class="mx-4">
    <v-container class="pl-0 pr-0 pb-12 text-left drug-container">
      <v-layout>
        <v-flex xs12>
          <v-card class="mt-4 drug-info">
            <v-card-title class="mb-4 card-header secondary flex-column align-start">
              <div class="drug-name">
                {{ name }}
              </div>
              <div class="type">
                Type: {{ type }}
              </div>
            </v-card-title>
            <v-card-text>
              <v-layout class="outer-row">
                <v-card
                  v-for="category in Object.keys(items[type])"
                  :key="category"
                  class="item-card drug-card">
                  <v-card-title class="primary darken">
                    <span class="white--text sub-header">
                      {{ category }}
                    </span>
                  </v-card-title>
                  <v-card-text class="pt-4">
                    <div
                      v-for="item in Object.keys(items[type][category])"
                      :key="item"
                      class="items">
                      <p class="mb-0 value-header">
                        {{ item }}
                      </p>
                      <v-text-field
                        v-model="items[type][category][item]"
                        :disabled="true"
                        class="my-0 pt-1 bainbridge-black--text"/>
                    </div>
                  </v-card-text>
                </v-card>
              </v-layout>
              <div class="outer-row">
                <v-card class="w-100 mt-4 drug-card">
                  <v-card-title class="primary darken">
                    <span class="white--text sub-header">
                      COMMENTS
                    </span>
                  </v-card-title>
                  <v-card-text class="pt-4">
                    <div
                      v-for="item in Object.keys(comments)"
                      :key="item"
                      class="items">
                      <p class="mb-0 value-header">
                        {{ item }}
                      </p>
                      <v-text-field
                        v-model="comments[item]"
                        :disabled="true"
                        class="my-0 pt-1"
                        rows="2"
                        multi-line/>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'DrugInfo',
  data() {
    return {
      drug: null,
      name: null,
      type: null,
      items: null,
      comments: null,
    };
  },
  created() {
    this.fetchDrug();
  },
  methods: {
    fetchDrug() {
      const name = decodeURIComponent(this.$route.path.split('/')[2]);
      const sub = this.$route.query.sub ? decodeURIComponent(this.$route.query.sub) : null;
      this.$services.library.getLibrary().then((json) => {
        const filteredDrug = !sub ?
          json.items[name][0] :
          json.items[name].filter((subDrug) => subDrug.result.toLowerCase() === sub.toLowerCase())[0];
        this.name = filteredDrug.drug['Drug Name'];
        this.type = filteredDrug.type;
        this.drug = filteredDrug.drug;
        this.items = { [this.type]: {} };
        if (filteredDrug.type === 'Intermittent') {
          this.populateIntermittentItems();
        } else if (filteredDrug.type === 'Continuous') {
          this.populateContinuousItems();
        }
        this.getCommentItems();
      });
    },
    populateIntermittentItems() {
      this.getConcentrationItems();
      this.getDosingItems();
      this.getRateItems();
    },
    populateContinuousItems() {
      this.getAdministrationItems();
      this.getDosingLimits();
      this.getBolusLimits();
    },
    getAdministrationItems() {
      const items = {};
      ['Concentration', 'Infusion Type'].forEach((category) => {
        let value = this.drug[category];
        if (value === '') {
          value = 'None';
        }
        items[category] = value;
      });
      this.items.Continuous.administration = items;
    },
    getDosingLimits() {
      const items = {};
      ['Delivery Units', 'Hard Maximum', 'Hard Minimum', 'Soft Maximum', 'Soft Minimum'].forEach((category) => {
        let value = this.drug[category];
        if (value === '') {
          value = 'None';
        }
        items[category] = value;
      });
      this.items.Continuous['Dosing Limits'] = items;
    },
    getBolusLimits() {
      const items = {};
      if (this.drug['Bolus Enabled'] === 'Y') {
        items['Bolus Enabled'] = 'Yes';
        const categories = ['Bolus Dose High', 'Bolus Dose Low', 'Bolus Rate High',
          'Bolus Rate Low', 'Main Rate High', 'Main Rate Maximum', 'Main Rate Low'];
        categories.forEach((category) => {
          let value = this.drug[category];
          if (value === '') {
            value = 'None';
          }
          items[category] = value;
        });
      } else if (this.drug['Bolus Enabled'] === 'N') {
        items['Bolus Enabled'] = 'No';
      }
      this.items.Continuous['Bolus Limits'] = items;
    },
    getConcentrationItems() {
      const items = {};
      ['Administration Concentration', 'Concentration Dispensed'].forEach((category) => {
        let value = this.drug[category];
        if (value === '') {
          value = 'None';
        }
        items[category] = value;
      });
      this.items.Intermittent.concentration = items;
    },
    getDosingItems() {
      const items = {};
      ['EHR Orderable Units', 'Max Dose per KG', 'EHR Max Dose Flat'].forEach((category) => {
        let value = this.drug[category];
        if (value === '') {
          value = 'None';
        }
        items[category] = value;
      });
      this.items.Intermittent.dosing = items;
    },
    getRateItems() {
      const items = {};
      ['Default Rate', 'Hard Maximum', 'Soft Maximum', 'Soft Minimum'].forEach((category) => {
        let value = this.drug[category];
        if (value === '') {
          value = 'None';
        }
        items[category] = value;
      });
      this.items.Intermittent.rate = items;
    },
    getCommentItems() {
      const items = {};
      if (this.type === 'Intermittent') {
        ['Concentration Comments', 'Dose Comments', 'Rate Comments', 'Drug Alerting'].forEach((category) => {
          let value = this.drug[category];
          if (value === '') {
            value = 'None';
          }
          items[category] = value;
        });
      } else if (this.type === 'Continuous') {
        let value = this.drug['Clinical Advisory'];
        if (value === '') {
          value = 'None';
        }
        items['Clinical Advisory'] = value;
      }
      this.comments = items;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../sass/colors.scss";

.drug-container {
  min-width: 722px;
}

.outer-row {
  justify-content: space-between;
  min-width: 690px;
  margin: 0;
}

.value-header {
  text-transform: capitalize;
  color: $bainbridge-black;
}

.items {
  flex-direction: column;
}

.item-card {
  width: 32%;
  min-width: 227px;
}

.card__title {
  flex-direction: column;
  align-items: left;
}

.sub-header {
  font-size: 15px;
  text-transform: uppercase;
}

.drug-name {
  text-transform: capitalize;
}

.card-header {
  background: white !important;
  border-bottom: 3px solid;
}

input[type="text"] {
    font-size: 14px !important;
}

.type {
  font-size: 15px;
}

.input-group__details {
    display: none !important;
}

.card.drug-info {
  min-width: 708px;
}
</style>
