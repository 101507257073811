<template>
  <div
    class="file-card"
    :class="size === 'iconsSmall' ? 'file-card-small' : 'file-card-large'"
    @click="fileClicked">
    <div>
      <StatusIndicator
        :state="state"
        parent-type="tile"
        style="float:right"/>
    </div>
    <v-icon
      class="file-icon"
      :class="isSelected ? 'file-icon-selected' : ''"
      :size="size === 'iconsSmall' ? 50 : 75">
      insert_drive_file
    </v-icon>
    <div id="file-card-title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import StatusIndicator from '@/components/manual-upload/FileView/StatusIndicator/StatusIndicator.vue';
import Vuetify from 'vuetify'

export default {
  name: 'FileTile',
  components: {
    StatusIndicator,
  },
  props: {
    state: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fileClicked() {
      this.$emit('file-clicked');
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";

.file-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin: .1rem;
  &:hover {
    cursor: pointer;
  }
}

.file-card-small {
  width: 100px;
}

.file-card-large {
  width: 125px;
}

#file-card-title {
  word-break: break-all;
  text-align: center;
  font-size: 14px;
}

.file-icon-selected {
  color: $bainbridge-blue !important;
}

.file-card:hover .file-icon {
  color: $bainbridge-blue !important;
}
</style>
