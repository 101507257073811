<template>
  <v-container class="pt-12">
    <v-layout justify-center>
      <v-flex
        lg8
        md10
        xs12>
        <v-card class="text-left">
          <v-card-title class="primary">
            <span class="white--text">
              User Configuration
            </span>
          </v-card-title>
          <v-card-text
            v-if="loading"
            class="mt-2 text-center">
            <v-progress-circular
              indeterminate
              class="primary--text"/>
          </v-card-text>
          <v-card-text v-else>
            <div
              class="mt-4 pb-2 d-flex align-center justify-space-between">
              <span v-if="!usersExist">
                There are no users for your organization. Click the new user button to create a user.
              </span>
              <span v-else>
                Manage your organization's users.
              </span>
              <div>
                <span v-if="usersExist && !editingUser">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        id="edit-button"
                        :disabled="updateLoading || addLoading"
                        class="mx-2 elevation-1"
                        color="primary"
                        fab
                        small
                        v-on="on"
                        @click="editingAll = true">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit all users</span>
                  </v-tooltip>
                </span>
                <span v-if="usersExist && editingUser">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        id="cancel-button"
                        :disabled="updateLoading || addLoading"
                        class="mx-2 elevation-1"
                        color="primary"
                        fab
                        small
                        v-on="on"
                        @click="cancelEdits">
                        <v-icon>mdi-pencil-off</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancel edits for all users</span>
                  </v-tooltip>
                </span>
                <span v-if="usersExist && editingUser">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        id="save-button"
                        :disabled="updateLoading || addLoading || !changesMade"
                        class="mx-2 elevation-1"
                        color="primary"
                        fab
                        small
                        v-on="on"
                        @click="saveAll = true">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                    <span>Save updates for all users</span>
                  </v-tooltip>
                </span>
                <span>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        id="add-button"
                        :loading="addLoading"
                        :disabled="updateLoading || addLoading"
                        class="mx-2 elevation-1"
                        color="secondary"
                        fab
                        small
                        v-on="on"
                        @click="createUserDialog = true">
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Create a new user</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
            <v-list
              v-if="usersExist"
              class="pt-0">
              <div
                v-for="(user) in sortedUsers"
                :key="user.id">
                <UserRow
                  v-if="user.userType === 'domain'"
                  :key="user.id"
                  :user="user"
                  :editing="userEditing[user.id]"
                  :save="saveAll"
                  :save-complete="saveComplete[user.id]"
                  :provider-options="providerOptions"
                  :user-emails="userEmails"
                  :reset-complete="!Object.keys(resetLoading).includes(user.email)"
                  @editingUser="trackEditing"
                  @userInfoChanged="trackChanges"
                  @updateUser="updateUser"
                  @resetPassword="resetPassword"/>
              </div>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="createUserDialog"
      max-width="400">
      <v-card>
        <v-card-title
          class="title dialog-title">
          Create User
        </v-card-title>
        <v-card-text>
          <v-form
            ref="createUserForm"
            v-model="valid"
            lazy-validation>
            <v-text-field
              v-model="newUser.email"
              :rules="[
                v => !!v || 'Required',
                v => v && validateEmail(v) || 'Invalid email address',
                v => v && tenant.domain.includes(v.split('@')[1]) || 'Email domain does not match tenant domain',
                v => v && !userEmails.includes(v.toLowerCase()) || 'Email already exists',
              ]"
              :validate-on-blur="true"
              :disabled="addLoading"
              class="w-100 mt-2"
              placeholder="Email"/>
            <v-text-field
              v-model="newUser.given_name"
              :validate-on-blur="true"
              :disabled="addLoading"
              class="w-100 mt-2"
              placeholder="First Name"/>
            <v-text-field
              v-model="newUser.family_name"
              :validate-on-blur="true"
              :disabled="addLoading"
              class="w-100 mt-2"
              placeholder="Last Name"/>
            <v-combobox
              v-model="newUser.identity_provider"
              :items="sortedProviders"
              :search-input.sync="providerQuery"
              :rules="[
                v => !!v || 'Required',
                v => sortedProviders.includes(v) || 'Invalid selection',
              ]"
              :validate-on-blur="true"
              :disabled="addLoading"
              class="w-100 mt-2"
              placeholder="Login Method"
              @input="providerQuery = ''"/>
            <v-combobox
              v-if="sortedRoles.length > 0"
              v-model="newUserRoleNames"
              :items="sortedRoles"
              :search-input.sync="roleQuery"
              :rules="[
                v => v.length > 0 || 'Required',
                v => v.every(r => sortedRoles.includes(r)) || 'One or more values are invalid',
              ]"
              :validate-on-blur="true"
              :disabled="addLoading"
              class="w-100 mt-2"
              placeholder="Roles"
              multiple
              chips
              small-chips
              @input="roleQuery = ''"/>
            <v-combobox
              v-if="tenant.allow_multiple && sortedTenants.length > 0"
              v-model="tenantNames"
              :items="sortedTenants"
              :search-input.sync="tenantQuery"
              :rules="[
                v => v.every(t => sortedTenants.includes(t)) || 'One or more values are invalid',
              ]"
              :validate-on-blur="true"
              :disabled="addLoading"
              class="w-100 mt-2"
              placeholder="Tenants"
              multiple
              chips
              small-chips
              @input="tenantQuery = ''"/>
          </v-form>
        </v-card-text>
        <v-card-actions
          class="justify-end">
          <v-btn
            text
            @click="createUserDialog = false">
            Cancel
          </v-btn>
          <v-btn
            :loading="addLoading"
            :disabled="addDisabled"
            color="secondary"
            depressed
            @click="addUser">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

import { EventLog } from '@/lib/event-log';
import {
  EMAIL_REGEX,
  EMPLOYEE_IDENTITY_PROVIDERS,
  EMPLOYEE_TENANT_NAME,
  IDENTITY_PROVIDERS,
  INTERNAL_IDENTITY_PROVIDER,
} from '@/lib/identity-methods';

import UserRow from './UserRow.vue';

export default {
  name: 'UserConfig',
  components: {
    UserRow,
  },
  data() {
    return {
      valid: true,
      providerQuery: '',
      roleQuery: '',
      tenantQuery: '',
      loginMethod: '',
      role: '',
      editingAll: false,
      saveAll: false,
      addLoading: false,
      newUser: {
        email: '',
        given_name: '',
        family_name: '',
        identity_provider: '',
        roles: [],
        tenants: [],
      },
      userEditing: {},
      userUpdates: {},
      saveComplete: {},
      newUserRoleNames: [],
      tenantNames: [],
      createUserDialog: false,
      resetLoading: {},
    };
  },
  computed: {
    addDisabled() {
      return (
        this.loading ||
        this.addLoading ||
        !this.newUser.email ||
        !this.newUser.identity_provider ||
        this.newUserRoleNames.length === 0
      );
    },
    loading() {
      return !this.allowedRolesLoaded || !this.identityConfigLoaded || !this.tenantUsersLoaded ||
        !this.allowedTenantsLoaded;
    },
    usersExist() {
      return this.tenantUsers.length > 0;
    },
    editingUser() {
      return Object.keys(this.userEditing).some((k) => this.userEditing[k] === true);
    },
    changesMade() {
      return Object.keys(this.userUpdates).some((k) => this.userUpdates[k] === true);
    },
    updateLoading() {
      return Object.keys(this.saveComplete).some((k) => this.saveComplete[k] === false);
    },
    userEmails() {
      if (this.tenantUsers.length > 0) {
        return this.tenantUsers.map((user) => user.email.toLowerCase());
      } else {
        return [];
      }
    },
    providerOptions() {
      if (this.tenant.name === EMPLOYEE_TENANT_NAME) {
        return EMPLOYEE_IDENTITY_PROVIDERS;
      } else if (!this.identityConfig) {
        const providers = {};
        Object.keys(IDENTITY_PROVIDERS).forEach((key) => {
          if (key === INTERNAL_IDENTITY_PROVIDER) {
            providers[key] = IDENTITY_PROVIDERS[key];
          }
        });
        return providers;
      } else {
        return IDENTITY_PROVIDERS;
      }
    },
    sortedUsers() {
      const sortedUsers = cloneDeep(this.tenantUsers);
      sortedUsers.sort((a, b) => new Date(b.created) - new Date(a.created));
      return sortedUsers;
    },
    sortedProviders() {
      if (this.providerOptions) {
        const results = Object.values(this.providerOptions);
        results.sort();
        if (this.providerQuery) {
          results.sort((x, y) => {
            if (x.toLowerCase().startsWith(this.providerQuery.toLowerCase())) {
              return -1;
            } else if (y.toLowerCase().startsWith(this.providerQuery.toLowerCase())) {
              return 1;
            } else {
              return 0;
            }
          });
        }
        return results;
      } else {
        return [];
      }
    },
    sortedRoles() {
      let results = this.allowedRoles.map((r) => r.display_name);
      results.forEach((result) => {
        if (this.newUserRoleNames.includes(result)) {
          const selectedRole = this.allowedRoles.find((r) => r.display_name === result);
          const parentRole = this.allowedRoles.find((r) => r.id === selectedRole.parent);
          const childRoles = this.allowedRoles.filter((r) => r.parent === selectedRole.id);
          const siblingRoles = this.allowedRoles
            .filter((r) => r.parent === selectedRole.parent && r.id !== selectedRole.id);
          if (childRoles.length > 0) {
            results = results.filter((r) => childRoles.every((role) => r !== role.display_name));
          }
          if (siblingRoles.length > 0) {
            results = results.filter((r) => siblingRoles.every((role) => r !== role.display_name));
          }
          if (parentRole) {
            results = results.filter((r) => r !== parentRole.display_name);
          }
        }
      });
      results.sort();
      if (this.roleQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.roleQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.roleQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    sortedTenants() {
      const results = this.allowedTenants.map((t) => t.name);
      results.sort();
      if (this.tenantQuery) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.tenantQuery.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.tenantQuery.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    ...mapGetters([
      'allowedRoles',
      'allowedRolesLoaded',
      'allowedTenants',
      'allowedTenantsLoaded',
      'identityConfig',
      'identityConfigLoaded',
      'menuNavigationStart',
      'tenant',
      'tenantUsers',
      'tenantUsersLoaded',
    ]),
  },
  watch: {
    loading: {
      handler() {
        if (!this.loading && this.menuNavigationStart && 'timestamp' in this.menuNavigationStart) {
          const now = moment();
          const menuData = {
            timestamp: now,
            menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
          };
          this.setMenuNavigationEnd(menuData);
        }
      },
    },
    editingAll: {
      handler() {
        if (this.editingAll) {
          Object.keys(this.userEditing).forEach((userId) => {
            this.$set(this.userEditing, userId, true);
          });
        }
      },
      deep: true,
    },
    updateLoading: {
      handler() {
        if (!this.updateLoading && this.saveAll) {
          this.$notify('Successfully updated users.');
          this.saveAll = false;
        }
      },
      deep: true,
    },
    editingUser: {
      handler() {
        if (!this.editingUser) {
          this.editingAll = false;
        }
      },
      deep: true,
    },
    createUserDialog: {
      handler() {
        if (!this.createUserDialog) {
          this.resetCreateUserForm();
        }
      },
    },
  },
  mounted() {
    if (!this.loading) {
      const now = moment();
      const menuData = {
        timestamp: now,
        menu: `${this.$route.meta.displayName.toLowerCase().replace(/ |-/g, '_')}`,
      };
      this.setMenuNavigationEnd(menuData);
    }
  },
  methods: {
    validateEmail(email) {
      return EMAIL_REGEX.test(String(email).toLowerCase());
    },
    trackEditing(editing) {
      this.$set(this.userEditing, editing.id, editing.editing);
    },
    trackChanges(changes) {
      this.$set(this.userUpdates, changes.id, changes.changes);
    },
    addUser() {
      this.$refs.createUserForm.validate();
      if (this.valid) {
        const userInfo = cloneDeep(this.newUser);
        userInfo.identity_provider = Object.keys(this.providerOptions)
          .find((key) => this.providerOptions[key] === userInfo.identity_provider);
        userInfo.roles = this.newUserRoleNames.map((r) => this.allowedRoles.find((role) => role.display_name === r).id);
        if (this.tenant.allow_multiple) {
          userInfo.tenants = this.tenantNames.map((t) => this.allowedTenants.find((tenant) => tenant.name === t).id);
        }
        this.addLoading = true;
        this.setNewUser(userInfo).then(() => {
          if (userInfo.identity_provider === INTERNAL_IDENTITY_PROVIDER) {
            this.$notify(`Successfully created ${this.newUser.email}. Please reset their password below ` +
              'or instruct them to go to the sign in page and click "Forgot Password?" to set their password.');
          } else {
            this.$notify(`Successfully created ${this.newUser.email}. They may now log in with your Single Sign-On provider.`);
          }
          const loggingData = new EventLog({
            event: 'user_admin.add_user',
            newUser: userInfo.email,
          });
          this.$services.users.postTrackingLog(loggingData);
          this.createUserDialog = false;
        }).catch((error) => {
          const failLog = new EventLog({
            event: 'user_admin.fail_add_user',
            newUser: userInfo.email,
            error: error.message,
          });
          this.$services.users.postTrackingLog(failLog);
          this.$notify(error);
          Sentry.withScope((scope) => {
            Sentry.setContext('action_attributes', {
              module: 'user_admin',
              action: 'add_user',
              new_user: userInfo.email,
            });
            scope.setLevel('warning');
            Sentry.captureException(new Error(error));
          });
        }).finally(() => {
          this.addLoading = false;
          this.createUserDialog = false;
        });
      }
    },
    updateUser(userInfo) {
      this.$set(this.saveComplete, userInfo.id, false);
      let message;
      if (!this.saveAll) {
        if ('is_active' in userInfo && !userInfo.is_active) {
          message = `Successfully deactivated ${userInfo.email}. This user will no longer be able to log in.`;
        } else {
          message = `Successfully updated ${userInfo.email}.`;
        }
      }
      userInfo.tenant = this.tenant.id;
      this.setUpdatedUser(userInfo).then(() => {
        const userId = userInfo.id;
        if ('is_active' in userInfo && !userInfo.is_active) {
          if (Object.keys(this.userEditing).includes(userId)) {
            this.$delete(this.userEditing, userId);
          }
          if (Object.keys(this.userUpdates).includes(userId)) {
            this.$delete(this.userUpdates, userId);
          }
          if (Object.keys(this.saveComplete).includes(userId)) {
            this.$delete(this.saveComplete, userId);
          }
        } else {
          this.$set(this.saveComplete, userId, true);
        }
        const loggingData = new EventLog({
          event: 'user_admin.update_user',
          updateUser: userInfo.email,
        });
        this.$services.users.postTrackingLog(loggingData);
        if (!this.saveAll) {
          this.$notify(message);
        }
      }).catch((error) => {
        const failLog = new EventLog({
          event: 'user_admin.fail_update_user',
          updateUser: userInfo.email,
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        this.$notify(error);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'user_admin',
            action: 'udpate_user',
            new_user: userInfo.email,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      });
    },
    cancelEdits() {
      Object.keys(this.userEditing).forEach((userId) => {
        this.$set(this.userEditing, userId, false);
      });
      this.editingAll = false;
    },
    resetCreateUserForm() {
      Object.keys(this.newUser).forEach((key) => {
        if (key === 'roles') {
          this.$set(this.newUser, key, []);
        } else {
          this.$set(this.newUser, key, '');
        }
      });
      this.newUserRoleNames = [];
      this.$refs.createUserForm.reset();
    },
    resetPassword(email) {
      this.$set(this.resetLoading, email.email, true);
      this.$services.users.postForgotPassword(email).then((json) => {
        if (json.success) {
          this.$notify(`Successfully reset password for ${email.email}. An email has been sent to the user with instructions.`);
          const loggingData = new EventLog({
            event: 'user_admin.reset_user_password',
            updateUser: email.email,
          });
          this.$services.users.postTrackingLog(loggingData);
        } else {
          this.$notify(`Password reset failed for ${email.email}.`);
          const loggingData = new EventLog({
            event: 'user_admin.fail_reset_user_password',
            updateUser: email.email,
            error: json,
          });
          this.$services.users.postTrackingLog(loggingData);
        }
      }).catch((error) => {
        const failLog = new EventLog({
          event: 'user_admin.fail_reset_user_password',
          updateUser: email.email,
          error: error.message,
        });
        this.$services.users.postTrackingLog(failLog);
        this.$notify(error);
        Sentry.withScope((scope) => {
          Sentry.setContext('action_attributes', {
            module: 'user_admin',
            action: 'reset_user_password',
            new_user: email.email,
          });
          scope.setLevel('warning');
          Sentry.captureException(new Error(error));
        });
      }).finally(() => {
        this.$delete(this.resetLoading, email.email);
      });
    },
    ...mapActions([
      'setMenuNavigationEnd',
      'setNewUser',
      'setUpdatedUser',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.headline, .title {
  word-break: break-word;
}
</style>
