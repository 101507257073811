<template>
  <div
    :class="`text-filter ${formattedLabel}-row`">
    <v-layout
      class="align-center"
      wrap>
      <v-flex
        xs2
        class="mr-2">
        <FilterLabel
          :label="label"
          :is-active="isActive"/>
      </v-flex>
      <v-flex
        xs3
        class="mr-2">
        <v-select
          v-model="localValue.option"
          :items="possibleModifiers"
          :menu-props="{ 'contentClass': `${formattedLabel}-option-select` }"
          :disabled="!isActive"/>
      </v-flex>
      <v-flex
        v-if="showValueField"
        xs3>
        <v-form
          ref="textFilter">
          <v-combobox
            v-if="multiple"
            v-model="localValue.value"
            :rules="valueRequired ? [v => v.length > 0 || 'Required'] : []"
            :items="loading ? [] : sortedPossibleValues"
            :menu-props="{ 'contentClass': `${formattedLabel}-possible-values` }"
            :filter="filter"
            :search-input.sync="query"
            :disabled="!isActive"
            :loading="loading"
            multiple
            chips
            small-chips
            @input="query = ''"/>
          <v-combobox
            v-else
            v-model="singleValue"
            :rules="valueRequired ? [v => !!v || 'Required'] : []"
            :items="loading ? [] : sortedPossibleValues"
            :menu-props="{ 'contentClass': `${formattedLabel}-possible-values` }"
            :filter="filter"
            :search-input.sync="query"
            :disabled="!isActive"
            :loading="loading"
            chips
            small-chips
            @input="query = ''"/>
        </v-form>
      </v-flex>
      <FilterActions
        :is-active="isActive"
        @removeFilter="removeFilter"/>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { arraysEqual } from '@/lib/compare';

import {
  IS_EQUAL_TO,
  CONTAINS,
  STARTS_WITH,
  ENDS_WITH,
  IS_BLANK,
  IS_NULL,
  IS_NOT_EQUAL_TO,
  DOESNT_CONTAIN,
  DOESNT_START_WITH,
  DOESNT_END_WITH,
  IS_NOT_BLANK,
  IS_NOT_NULL,
} from '@/lib/constants';

import FilterLabel from './FilterLabel.vue';
import FilterActions from './FilterActions.vue';

const DEFAULT_OPTION = IS_EQUAL_TO;

export default {
  name: 'TextFilter',
  components: {
    FilterLabel,
    FilterActions,
  },
  props: {
    filterId: {
      type: String,
      required: true,
    },
    valueRequired: {
      type: Boolean,
      default: () => false,
    },
    passedValue: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    possibleValues: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      localValue: { value: [], option: DEFAULT_OPTION },
      singleValue: null,
      possibleModifiers: [
        { text: IS_EQUAL_TO, value: IS_EQUAL_TO },
        { text: CONTAINS, value: CONTAINS },
        { text: STARTS_WITH, value: STARTS_WITH },
        { text: ENDS_WITH, value: ENDS_WITH },
        { text: IS_BLANK, value: IS_BLANK },
        { text: IS_NULL, value: IS_NULL },
        { text: IS_NOT_EQUAL_TO, value: IS_NOT_EQUAL_TO },
        { text: DOESNT_CONTAIN, value: DOESNT_CONTAIN },
        { text: DOESNT_START_WITH, value: DOESNT_START_WITH },
        { text: DOESNT_END_WITH, value: DOESNT_END_WITH },
        { text: IS_NOT_BLANK, value: IS_NOT_BLANK },
        { text: IS_NOT_NULL, value: IS_NOT_NULL },
      ],
      query: '',
    };
  },
  computed: {
    formattedLabel() {
      return this.label.toLowerCase().replace(/ /g, '-');
    },
    sortedPossibleValues() {
      const results = this.possibleValues ? this.possibleValues : [];
      // Sort once to get the array into order, sort again, but only to move items that start with the query
      // ahead.

      results.sort();
      if (this.query) {
        results.sort((x, y) => {
          if (x.toLowerCase().startsWith(this.query.toLowerCase())) {
            return -1;
          } else if (y.toLowerCase().startsWith(this.query.toLowerCase())) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return results;
    },
    // Tracks if filter's values have been changed from passed values
    valuesChanged() {
      return (Object.keys(this.passedValue).length > 0 && this.localValue.option !== this.passedValue.option &&
        ((!Array.isArray(this.passedValue.value) && !arraysEqual(this.passedValue.value.split(/,/g), this.localValue.value)) ||
        (Array.isArray(this.passedValue.value) && !arraysEqual(this.localValue.value, this.passedValue.value))));
    },
    // Tracks if filter is in its default state
    defaultValues() {
      return (Object.keys(this.passedValue).length === 0 &&
        this.localValue.option === DEFAULT_OPTION &&
        this.localValue.value.length === 0);
    },
    showValueField() {
      return ![IS_BLANK, IS_NULL, IS_NOT_BLANK, IS_NOT_NULL].includes(this.localValue.option);
    },
    ...mapGetters([
      'tenant',
    ]),
  },
  watch: {
    singleValue: {
      handler(value) {
        if (this.$refs.textFilter) {
          this.$refs.textFilter.validate();
        }
        if (value) {
          this.$set(this.localValue, 'value', [this.singleValue]);
        } else {
          this.$set(this.localValue, 'value', []);
        }
      },
      deep: true,
      immediate: true,
    },
    passedValue: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          // Protection against infinite loops
          if (this.passedValue.option && this.passedValue.option !== this.localValue.option) {
            this.localValue.option = this.passedValue.option;
          }
          if (this.passedValue.value) {
            if (!Array.isArray(this.passedValue.value)) {
              this.localValue.value = this.passedValue.value.split(/,/g);
              [this.singleValue] = this.localValue.value;
            } else if (!arraysEqual(this.passedValue.value, this.localValue.value)) {
              this.localValue.value = this.passedValue.value;
              [this.singleValue] = this.localValue.value;
            }
          }
        } else {
          this.localValue.option = DEFAULT_OPTION;
          this.localValue.value = [];
          this.singleValue = null;
        }
      },
      deep: true,
      immediate: true,
    },
    localValue: {
      handler() {
        if (this.showValueField && this.$refs.textFilter) {
          this.$refs.textFilter.validate();
        }
        // Protection against infinite loops
        if (!this.defaultValues || this.valuesChanged) {
          const emittedValue = {};

          if (this.localValue.option) {
            emittedValue.option = this.localValue.option;
          }
          if (
            this.localValue.value &&
            this.localValue.value.length > 0 &&
            this.showValueField
          ) {
            emittedValue.value = this.localValue.value;
          } else {
            emittedValue.value = null;
          }
          this.$emit('valueChanged', {
            key: this.filterId,
            value: emittedValue,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$refs.textFilter) {
      this.$refs.textFilter.validate();
    }
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter', this.filterId);
    },
    filter(item, queryText, itemText) {
      const hasValue = val => val != null ? val : ''; //eslint-disable-line
      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1;
    },
  },
};
</script>
